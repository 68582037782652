import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PreLoginNavBar from "../NavBar/PreLoginNavBar";
import crypto from "crypto-js";
import baseurl from "../../baseurl";

const Login = () => {
  const cookies = new Cookies();
  const tokenC = cookies.get("token");
  const userInfoC = cookies.get("userInfo");

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userexist, setUserexist] = useState(true);
  const [credenerror, setCredenerror] = useState(false);
  const [passtext, setPasstext] = useState(true);
  const handleLogin = (e) => {
    e.preventDefault();
    fetch(`${baseurl}/login`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: crypto.SHA256(password.trim()).toString(),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.message === "User logged in successfully") {
          cookies.set("token", data.data.token, {
            path: "/",
            maxAge: 21600,
          });
          cookies.set(
            "userInfo",
            {
              name: data.data.name,
              collegeId: data.data.collegeId,
              role: data.data.role,
              email: data.data.email,
              studentId: data.data.studentId,
            },
            {
              path: "/",
              maxAge: 21600,
            }
          );
          navigate("/dashboard");
        } else {
          data.message === "Incorrect email/password"
            ? setCredenerror(true)
            : setCredenerror(false);
          data.message === "User not found"
            ? setUserexist(false)
            : setUserexist(true);
        }
      });
  };
  const showPassword = () => {
    setPasstext(!passtext);
  };
  if (tokenC) {
    if (userInfoC) {
      return <div>{window.location.replace("/dashboard")}</div>;
    } else {
      cookies.remove("token");
      return <div>{window.location.replace("/login")}</div>;
    }
  } else {
    return (
      <>
        <PreLoginNavBar />
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 mt-5">
              <h1 className="mt-3">Login</h1>
              <form
                className="row g-3 align-items-center justify-content-center text-start"
                autoComplete="off"
                onSubmit={handleLogin}
              >
                <div className="col-8">
                  {userexist ? null : (
                    <div className="alert alert-danger" role="alert">
                      User doesn't exists!
                    </div>
                  )}
                  {credenerror ? (
                    <div className="alert alert-danger" role="alert">
                      The entered credentials doesn't match the record!
                    </div>
                  ) : null}
                </div>
                <div className="col-8">
                  <label forHtml="email" className="form-label">
                    Email ID
                  </label>
                  <input
                    type={"email"}
                    name="email"
                    placeholder="example@domain.com"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-8">
                  <label forhtml="inputPassword4" className="form-label">
                    Password
                  </label>
                  <input
                    type={passtext ? "password" : "text"}
                    name="password"
                    placeholder="*********"
                    className="form-control"
                    value={password}
                    id="password"
                    data-toggle="password"
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                    required
                  />
                  <input type="checkbox" onClick={(e) => showPassword()} />
                  &nbsp;&nbsp;Show Password
                </div>
                <div className="col-8">
                  <button type="submit" className="btn btn-primary">
                    Sign In
                  </button>
                </div>
                <div className="col-8">
                  <a className="text-decoration-none" href="/reset-password">
                    Forgot Password?
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Login;
