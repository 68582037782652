import { useEffect, useState } from "react";
import AdminNavBar from "../NavBar/AdminNavBar";
import Cookies from "universal-cookie";

import PendingInvites from "./PendingInvites";
import RegisteredStudents from "./RegisteredStudents";
import RegisteredNonStudents from "./RegisteredNonStudent";
import baseurl from "../../baseurl";

const Attendees = () => {
  const cookies = new Cookies();
  const [InviteName, setInviteName] = useState("");
  const [InviteEmail, setInviteEmail] = useState("");
  const [InviteRole, setInviteRole] = useState("");
  const [messageInviteName, setMessageInviteName] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const token = cookies.get("token");
  const userInfo = cookies.get("userInfo");
  const [pendingInvites, setpendingInvites] = useState([]);
  const [registeredStudents, setregisteredStudents] = useState([]);
  const [registeredNonStudents, setregisteredNonStudents] = useState([]);
  useEffect(() => {
    fetch(`${baseurl}/invites`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setpendingInvites(data.data)
          : setpendingInvites([]);
      });
    fetch(`${baseurl}/student/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setregisteredStudents(data.data)
          : setregisteredStudents([]);
      });
    fetch(`${baseurl}/non-student/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setregisteredNonStudents(data.data)
          : setregisteredNonStudents([]);
      });
  }, [token]);
  const handleInvite = (e) => {
    e.preventDefault();
    fetch(`${baseurl}/invite`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        name: InviteName,
        email: InviteEmail,
        role: InviteRole,
        collegeId: userInfo.collegeId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setMessage(data.message);
          setStatus(data.status);
          handlePendingInvites();
          handleRegisteredStudents();
          handleRegisteredNonStudents();
          setMessageInviteName(InviteName);
          setInviteEmail("");
          setInviteName("");
          setInviteRole("");
        } else {
          setMessage(data.message);
          setStatus(data.status);
        }
        setTimeout(() => {
          setMessage("");
          setMessageInviteName("");
          setStatus(0);
        }, 3000);
      });
  };
  const handlePendingInvites = (e) => {
    fetch(`${baseurl}/invites`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setpendingInvites(data.data)
          : setpendingInvites([]);
      });
  };
  const handleRegisteredNonStudents = (e) => {
    fetch(`${baseurl}/non-student/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setregisteredNonStudents(data.data)
          : setregisteredNonStudents([]);
      });
  };
  const handleRegisteredStudents = (e) => {
    fetch(`${baseurl}/student/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setregisteredStudents(data.data)
          : setregisteredStudents([]);
      });
  };
  if (token) {
    if (userInfo.role === "admin") {
      return (
        <>
          <AdminNavBar />
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-md-10">
                <h2>Invite a Contingent Member.</h2>
                <h4>
                  <strong>
                    The registrations have ended and all details are frozen.
                  </strong>
                </h4>
                <form className="form-group" onSubmit={handleInvite}>
                  <div className="row justify-content-center">
                    <div className="col-md-4 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={InviteName}
                        onChange={(e) => setInviteName(e.target.value)}
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="name@example.com"
                        value={InviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-2 mt-2">
                      <select
                        className="form-select"
                        value={InviteRole}
                        onChange={(e) => setInviteRole(e.target.value)}
                        required
                        disabled
                      >
                        <option selected disabled value={""}>
                          Choose one...
                        </option>
                        <option value={"student"}>Student</option>
                        <option value={"non-student"}>Non-Student</option>
                        <option value={"faculty"}>Faculty</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4 justify-content-center">
                    <div className="col-12 ">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                  <div className="row mt-3 justify-content-center">
                    <div className="col-md-6 col-12">
                      {status === 200 ? (
                        <div className="alert alert-success" role="alert">
                          {messageInviteName} has been invited successfully.
                        </div>
                      ) : status === 400 ? (
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <PendingInvites pendingInvites={pendingInvites} token={token} />
            <RegisteredStudents
              registeredStudents={registeredStudents}
              token={token}
            />
            <RegisteredNonStudents
              registeredNonStudents={registeredNonStudents}
              token={token}
            />
          </div>
        </>
      );
    } else {
      cookies.remove("token");
      return <div>{window.location.replace("/login")}</div>;
    }
  } else {
    return <div>{window.location.replace("/login")}</div>;
  }
};
export default Attendees;
