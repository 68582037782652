import React from "react";

function ErrorBox(props) {
  return (
    <div className="login-bg-color rounded container h-50 registration-error-box ">
      <h3 className="text">{props.message ? props.message : "Loading..."}</h3>
    </div>
  );
}

export default ErrorBox;
