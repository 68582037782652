import { useState } from "react";
import PreLoginNavBar from "../NavBar/PreLoginNavBar";
import baseurl from "../../baseurl";
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const resetPassword = (e) => {
    e.preventDefault();
    fetch(`${baseurl}/request-update-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        data.status === 200
          ? setMessage("Please check your email inbox to reset your password.")
          : setMessage(data.message);
      });
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <>
      <PreLoginNavBar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card mt-5">
              <div className="card-body">
                <h2>Reset Password</h2>
                <form onSubmit={resetPassword}>
                  <div className="form-group text-start">
                    <div className="col-12 mt-3">
                      <label htmlFor="email">Email ID</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email ID"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <button type="submit" className="btn btn-primary">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 mt-5">
            {message === "" ? null : message ===
              "Please check your email for furthur procedure" ? (
              <div className="alert alert-success">{message}</div>
            ) : (
              <div className="alert alert-danger">{message}</div>
            )}{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
