import Cookies from "universal-cookie";
import AdminDashboard from "./AdminDashboard";
import StudentDashboard from "./StudentDashboard";
import NonStudentDashboard from "./NonStudentDashboard";
const Dashboard = () => {
  const cookies = new Cookies();
  const tokenC = cookies.get("token");
  const userInfoC = cookies.get("userInfo");
  if (tokenC) {
    if (userInfoC.role === "admin") {
      return <AdminDashboard token={tokenC} userInfo={userInfoC} />;
    } else if (userInfoC.role === "student") {
      return <StudentDashboard token={tokenC} userInfo={userInfoC} />;
    } else if (userInfoC.role === "non-student") {
      return <NonStudentDashboard token={tokenC} userInfo={userInfoC} />;
    } else if (userInfoC.role === "faculty") {
      return <NonStudentDashboard token={tokenC} userInfo={userInfoC} />;
    } else {
      window.location.replace("/login");
    }
  } else {
    window.location.replace("/login");
  }
};
export default Dashboard;
