import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const LogOut = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dologOut = () => {
    cookies.remove("token");
    cookies.remove("userInfo");
    navigate("/");
  };
  return (
    <div className="row">
      <div className="col-12 text-start">
        <button className="btn btn-dark" onClick={dologOut}>
          Logout
        </button>
      </div>
    </div>
  );
};
export default LogOut;
