import { useEffect, useState } from "react";
import StudentNavBar from "../NavBar/StudentNavBar";
import baseurl from "../../baseurl";

const StudentDashboard = (props) => {
  const [details, setDetails] = useState(false);
  const [editable, setEditable] = useState(true);
  const [saved, setSaved] = useState(false);
  const [StudentUSN, setStudentUSN] = useState("");
  const [StudentPhone, setStudentPhone] = useState("");
  const [StudentFatherName, setStudentFatherName] = useState("");
  const [StudentMotherName, setStudentMotherName] = useState("");
  const [StudentGuardianName, setStudentGuardianName] = useState("");
  const [StudentDOB, setStudentDOB] = useState("");
  const [StudentAge, setStudentAge] = useState("");
  const [StudentHSCPassing, setStudentHSCPassing] = useState("");
  const [StudentCourse, setStudentCourse] = useState("");
  const [StudentBranch, setStudentBranch] = useState("");
  const [StudentSemester, setStudentSemester] = useState("");
  const [StudentAdmissionYear, setStudentAdmissionYear] = useState("");
  const [NoOfTimesParticipated, setNoOfTimesParticipated] = useState("");
  const [EarnedDistinction, setEarnedDistinction] = useState("");
  const [OtherDistinctions, setOtherDistinctions] = useState("");
  const [StudentGender, setStudentGender] = useState("");
  const AgeRange = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];

  useEffect(() => {
    fetch(`${baseurl}/student`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setDetails(data.data);
          setStudentUSN(data.data.usn);
          setStudentPhone(data.data.phoneNo);
          setStudentFatherName(data.data.personalDetails.fatherName);
          setStudentMotherName(data.data.personalDetails.MotherName);
          setStudentGuardianName(data.data.personalDetails.GuardianName);
          setStudentDOB(data.data.personalDetails.dob);
          setStudentAge(data.data.personalDetails.Age);
          setStudentHSCPassing(data.data.collegeDetails.yearOfTwelfthPassing);
          setStudentCourse(data.data.collegeDetails.course);
          setStudentBranch(data.data.collegeDetails.branch);
          setStudentSemester(data.data.collegeDetails.semester);
          setStudentAdmissionYear(data.data.collegeDetails.yearOfAdmission);
          setNoOfTimesParticipated(data.data.experience.noOfTimesParticipated);
          setEarnedDistinction(data.data.experience.earnedDisctinction);
          setOtherDistinctions(data.data.experience.distinctionInOtherField);
          setEditable(!data.data.isRegistered);
          setStudentGender(data.data.gender);
        } else {
        }
      });
  }, [props]);
  const saveData = (e) => {
    e.preventDefault();

    var data = {
      studentId: details.studentId,
      name: details.name,
      email: details.email,
      usn: StudentUSN,
      gender: StudentGender,
      phoneNo: StudentPhone,
      collegeId: details.collegeId,
      personalDetails: {
        fatherName: StudentFatherName,
        GuardianName: StudentGuardianName,
        MotherName: StudentMotherName,
        Age: StudentAge,
        dob: StudentDOB,
      },
      collegeDetails: {
        yearOfTwelfthPassing: StudentHSCPassing,
        course: StudentCourse,
        branch: StudentBranch,
        semester: StudentSemester,
        yearOfAdmission: StudentAdmissionYear,
      },
      experience: {
        noOfTimesParticipated: NoOfTimesParticipated,
        earnedDisctinction: EarnedDistinction,
        distinctionInOtherField: OtherDistinctions,
      },
      isRegistered: false,
    };
    fetch(`${baseurl}/student`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + props.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          savedData();
        } else {
          setSaved(false);
        }
      });
  };
  const savedData = () => {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 5000);
  };
  const sendData = (e) => {
    e.preventDefault();
    var data = {
      studentId: details.studentId,
      name: details.name,
      email: details.email,
      usn: StudentUSN,
      phoneNo: StudentPhone,
      gender: StudentGender,
      collegeId: details.collegeId,
      isRegistered: true,
      personalDetails: {
        fatherName: StudentFatherName,
        GuardianName: StudentGuardianName,
        MotherName: StudentMotherName,
        Age: StudentAge,
        dob: StudentDOB,
      },
      collegeDetails: {
        yearOfTwelfthPassing: StudentHSCPassing,
        course: StudentCourse,
        branch: StudentBranch,
        semester: StudentSemester,
        yearOfAdmission: StudentAdmissionYear,
      },
      experience: {
        noOfTimesParticipated: NoOfTimesParticipated,
        earnedDisctinction: EarnedDistinction,
        distinctionInOtherField: OtherDistinctions,
      },
    };
    fetch(`${baseurl}/student`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + props.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setEditable(false);
        } else {
          setSaved(false);
        }
      });
  };
  return (
    <>
      <StudentNavBar />
      <div className="container">
        <div className="row">
          <form onSubmit={sendData}>
            <div className="col-12">
              <div className="row justify-content-center mt-3">
                <div className="col-md-6 col-12 text-center">
                  {saved ? (
                    <div className="alert alert-success" role="alert">
                      The provided details have been saved.
                    </div>
                  ) : null}
                  {editable ? null : (
                    <div className="alert alert-success" role="alert">
                      Congratulations! Your details have been successfully
                      submitted. You can proceed to the next step, which will be
                      uploading your documents. Please click on the 'Upload
                      Documents' tab for the same.
                    </div>
                  )}
                </div>
                <h4>
                  <strong>
                    The registrations have ended and all details are frozen.
                  </strong>
                </h4>
              </div>
              <div className="row justify-content-center">
                {/*General Details*/}
                <div className="col-md-5 col-12 mt-5">
                  <div className="row text-start">
                    <h3>General Details</h3>
                    <div className="col-12">
                      <label forhtml="StudentName" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="StudentName"
                        readOnly={true}
                        value={details.name}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentEmail" className="form-label">
                        Email ID
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="StudentEmail"
                        readOnly={true}
                        value={details.email}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentUSN" className="form-label">
                        USN
                      </label>
                      <input
                        type={"text"}
                        name="StudentUSN"
                        placeholder="1BM00CS000"
                        className="form-control"
                        value={StudentUSN}
                        onChange={(e) => setStudentUSN(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentPhone" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type={"tel"}
                        name="StudentPhone"
                        placeholder="+91 98765 43210"
                        className="form-control"
                        value={StudentPhone}
                        onChange={(e) => setStudentPhone(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-12"></div>
                {/*Personal Details*/}
                <div className="col-md-5 col-12 mt-5">
                  <div className="row text-start">
                    <h3>Personal Details</h3>
                    <div className="col-12">
                      <label forhtml="StudentFatherName" className="form-label">
                        Father's Name
                      </label>
                      <input
                        type={"text"}
                        name="StudentFatherName"
                        placeholder="Father's Full Name"
                        className="form-control"
                        value={StudentFatherName}
                        onChange={(e) => setStudentFatherName(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentMotherName" className="form-label">
                        Mother's Name
                      </label>
                      <input
                        type={"text"}
                        name="StudentMotherName"
                        placeholder="Mother's Full Name"
                        className="form-control"
                        value={StudentMotherName}
                        onChange={(e) => setStudentMotherName(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label
                        forhtml="StudentGuardianName"
                        className="form-label"
                      >
                        Guardian's Name
                      </label>
                      <input
                        type={"text"}
                        name="StudentGuardianName"
                        placeholder="Guardian's Full Name"
                        className="form-control"
                        value={StudentGuardianName}
                        onChange={(e) => setStudentGuardianName(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentDOB" className="form-label">
                        Date of Birth
                      </label>
                      <input
                        type={"date"}
                        name="StudentDOB"
                        placeholder="Date of Birth"
                        className="form-control"
                        value={StudentDOB}
                        onChange={(e) => setStudentDOB(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentAge" className="form-label">
                        Age
                      </label>
                      <select
                        name="StudentAge"
                        placeholder="Age"
                        className="form-select"
                        value={StudentAge}
                        onChange={(e) => setStudentAge(e.target.value)}
                        autoComplete="off"
                        disabled={!editable}
                        required
                      >
                        <option value={""} selected disabled>
                          Choose One...
                        </option>
                        {AgeRange.map((index) => {
                          return (
                            <option value={index.toString()}>{index}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentGender" className="form-label">
                        Gender
                      </label>
                      <select
                        name="StudentGender"
                        placeholder="Gender"
                        className="form-select"
                        value={StudentGender}
                        onChange={(e) => setStudentGender(e.target.value)}
                        autoComplete="off"
                        disabled={!editable}
                        required
                      >
                        <option value={""} selected disabled>
                          Choose One...
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-5 mb-5 text-start">
                {/*Academic Details*/}
                <div className="col-md-5 col-12 mt-5">
                  <h3>Academic Details</h3>
                  <div className="col-12">
                    <label forhtml="StudentCourse" className="form-label">
                      Course
                    </label>
                    <select
                      name="StudentCourse"
                      className="form-select"
                      value={StudentCourse}
                      onChange={(e) => setStudentCourse(e.target.value)}
                      disabled={!editable}
                      required
                    >
                      <option value="" selected disabled>
                        Choose One
                      </option>
                      <option value="B.E.">
                        B.E. (Bachelor's of Engineering)
                      </option>
                      <option value="M.Tech.">
                        M.Tech. (Master's of Technology)
                      </option>
                      <option value="B.Arch.">
                        B.Arch. (Bachelor's of Architecture)
                      </option>
                      <option value="M.Arch.">
                        M.Arch. (Master's of Architecture)
                      </option>
                      <option value="MCA">
                        MCA (Master's of Computer Application)
                      </option>
                      <option value="MBA">
                        MBA (Master's of Business Administration)
                      </option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label forhtml="StudentBranch" className="form-label">
                      Branch
                    </label>
                    <input
                      type={"text"}
                      name="StudentBranch"
                      placeholder="Branch Name"
                      className="form-control"
                      value={StudentBranch}
                      onChange={(e) => setStudentBranch(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label
                      forhtml="StudentAdmissionYear"
                      className="form-label"
                    >
                      Year of Admission
                    </label>
                    <select
                      name="StudentAdmissionYear"
                      className="form-select"
                      value={StudentAdmissionYear}
                      onChange={(e) => setStudentAdmissionYear(e.target.value)}
                      disabled={!editable}
                      required
                    >
                      <option value="" selected disabled>
                        Choose One
                      </option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label forhtml="StudentSemester" className="form-label">
                      Semester
                    </label>
                    <input
                      type={"text"}
                      name="StudentSemester"
                      placeholder="Semester"
                      className="form-control"
                      value={StudentSemester}
                      onChange={(e) => setStudentSemester(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="StudentHSCPassing" className="form-label">
                      Year of Passing 12th
                    </label>
                    <select
                      name="StudentHSCPassing"
                      className="form-select"
                      value={StudentHSCPassing}
                      onChange={(e) => setStudentHSCPassing(e.target.value)}
                      disabled={!editable}
                      required
                    >
                      <option value="" selected disabled>
                        Choose One
                      </option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-12"> </div>
                {/*Experience in the VTU Youth Festival */}
                <div className="col-md-5 col-12 mt-5">
                  <h3>Experience in the VTU Youth Festival </h3>
                  <div className="col-12">
                    <label
                      forhtml="NoOfTimesParticipated"
                      className="form-label"
                    >
                      Number of times you have taken part in the VTU Youth
                      Festival
                    </label>
                    <select
                      name="NoOfTimesParticipated"
                      className="form-select"
                      value={NoOfTimesParticipated}
                      onChange={(e) => setNoOfTimesParticipated(e.target.value)}
                      disabled={!editable}
                      required
                    >
                      <option value="" selected disabled>
                        Choose One
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label forhtml="EarnedDistinction" className="form-label">
                      Earned Distinction
                    </label>
                    <textarea
                      name="EarnedDistinction"
                      placeholder="Mention about earned Distinction. If not write 
                      'Na'"
                      className="form-control"
                      value={EarnedDistinction}
                      onChange={(e) => setEarnedDistinction(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="OtherDistinctions" className="form-label">
                      Distinction in other fields
                    </label>
                    <textarea
                      name="OtherDistinctions"
                      placeholder="Any other Distinctions. If not write 
                      'Na'"
                      className="form-control"
                      value={OtherDistinctions}
                      onChange={(e) => setOtherDistinctions(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center">
                    {saved ? (
                      <div className="alert alert-success" role="alert">
                        The provided details have been saved.
                      </div>
                    ) : null}
                    {editable ? null : (
                      <div className="alert alert-success" role="alert">
                        Congratulations! Your details have been successfully
                        submitted. You can proceed to the next step, which will
                        be uploading your documents. Please click on the 'Upload
                        Documents' tab for the same.
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center mt-4">
                    <span>
                      Note: Data once submitted cannot be changed. Please ensure
                      all details filled in are correct before clicking on the
                      Submit button. To save the details in the database and
                      continue editing, click on the save button.
                    </span>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-lg-1 col-4 text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={saveData}
                      disabled={!editable}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-lg-1 col-4 text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      disabled={!editable}
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Confirm Student Details
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <p>
                            No changes can be made in the above details once
                            submitted.{" "}
                          </p>
                          <h5 className="modal-title" id="exampleModalLabel">
                            General Details
                          </h5>
                          <br />
                          <span>
                            Name: <strong>{details.name}</strong>
                          </span>
                          <br />
                          <span>
                            Email: <strong>{details.email}</strong>
                          </span>
                          <br />
                          <span>
                            USN: <strong>{StudentUSN}</strong>
                          </span>
                          <br />
                          <span>
                            Phone Number: <strong>{StudentPhone}</strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Personal Details
                          </h5>
                          <span>
                            Father's Name: <strong>{StudentFatherName}</strong>
                          </span>
                          <br />
                          <span>
                            Mother's Name: <strong>{StudentMotherName}</strong>
                          </span>
                          <br />
                          <span>
                            Guardian's Name:{" "}
                            <strong>{StudentGuardianName}</strong>
                          </span>
                          <br />
                          <span>
                            Age: <strong>{StudentAge}</strong>
                          </span>
                          <br />
                          <span>
                            DOB: <strong>{StudentDOB}</strong>
                          </span>
                          <br />
                          <span>
                            Gender:{" "}
                            <strong>{StudentGender.toUpperCase()}</strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            College Details
                          </h5>
                          <span>
                            Year of Passing 12th:{" "}
                            <strong>{StudentHSCPassing}</strong>
                          </span>
                          <br />
                          <span>
                            Course: <strong>{StudentCourse}</strong>
                          </span>
                          <br />
                          <span>
                            Branch: <strong>{StudentBranch}</strong>
                          </span>
                          <br />
                          <span>
                            Semester: <strong>{StudentSemester}</strong>
                          </span>
                          <br />
                          <span>
                            Year of Admission:{" "}
                            <strong>{StudentAdmissionYear}</strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Experience
                          </h5>
                          <span>
                            No. of time participated:{" "}
                            <strong>{NoOfTimesParticipated}</strong>
                          </span>
                          <br />
                          <span>
                            Earned Distinction:{" "}
                            <strong>{EarnedDistinction}</strong>
                          </span>
                          <br />
                          <span>
                            Other Distinctions:{" "}
                            <strong>{OtherDistinctions}</strong>
                          </span>
                          <hr />
                          <p>
                            No changes can be made in the above details once
                            submitted.{" "}
                          </p>

                          {editable ? null : (
                            <>
                              <hr />
                              <div className="alert alert-success" role="alert">
                                Congratulations! Your details have been
                                successfully submitted. You can proceed to the
                                next step, which will be uploading your
                                documents. Please click on the 'Upload
                                Documents' tab for the same.
                              </div>
                            </>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            disabled={!editable}
                          >
                            Edit
                          </button>
                          <button
                            type="submit"
                            data-bs-dismiss="modal"
                            className="btn btn-danger"
                            disabled={!editable}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default StudentDashboard;
