import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CollegeRegistration from "./Components/Registration/CollegeRegistration";
import AttendeeRegistration from "./Components/Registration/AttendeeRegistration";
import Dashboard from "./Components/Dash/Dashboard";
import Attendees from "./Components/Attendees/Attendees";
import UploadDocuments from "./Components/Students/UploadDocuments";
import NotFound from "./Components/NotFound/NotFound";
import PaymentPortal from "./Components/PaymentPortal/PaymentPortal";
import ResetPassword from "./Components/ForgotPass/ResetPassword";
import UpdatePassword from "./Components/ForgotPass/UpdatePassword";
import AssignEvents from "./Components/AssignEvents/AssignEvents";
import Login from "./Components/LogIn/Login";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    fetch("https://api.countapi.xyz/hit/registration.prathibhotsava.in/visits")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
      });
  }, []);
  return (
    <div className="App mb-5">
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate to={"/login"} />} />
          <Route
            path="/college-registration"
            element={<CollegeRegistration />}
          />
          <Route
            path="/attendee-registration"
            element={<AttendeeRegistration />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invitecontingentmembers" element={<Attendees />} />
          <Route path="/assign-events" element={<AssignEvents />} />
          <Route path="/upload-documents" element={<UploadDocuments />} />
          <Route path="/payment" element={<PaymentPortal />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-password" element={<ResetPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
