import { useEffect, useState } from "react";
import baseurl from "../../baseurl";
const PendingInvites = (props) => {
  const [pendingInvites, setpendingInvites] = useState([]);
  useEffect(() => {
    setpendingInvites(props.pendingInvites);
  }, [props.pendingInvites]);
  const deleteInvite = (invitee) => {
    fetch(`${baseurl}/invite`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
      body: JSON.stringify({ email: invitee.email }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        handlePendingInvites();
      });
  };
  const handlePendingInvites = (e) => {
    fetch(`${baseurl}/invites`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setpendingInvites(data.data)
          : setpendingInvites([]);
      });
  };

  return (
    <div className="row">
      <div className="col-12 mt-3">
        <h2>Pending Invites - {pendingInvites.length}</h2>
        <div className="table-responsive">
          {pendingInvites.length > 0 ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-start" scope="col">
                    Sl. No.
                  </th>
                  <th className="text-start" scope="col">
                    Name
                  </th>
                  <th className="text-start" scope="col">
                    Email ID
                  </th>
                  <th className="text-start" scope="col">
                    Role
                  </th>
                  <th className="text-start" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {pendingInvites.map((invite, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td className="text-start">{invite.name}</td>
                      <td className="text-start">{invite.email}</td>
                      <td className="text-start">
                        {invite.role === "student"
                          ? "Student"
                          : invite.role === "non-student"
                            ? "Non Student"
                            : "Faculty"}
                      </td>
                      <td className="text-start">
                        <button
                          className="btn btn-danger"
                          onClick={(e) => {
                            deleteInvite(invite);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h5>
              No contingent member has been invited yet.
              <br /> Fill in the appropriate details in the fields above to send
              invites to contingent members.{" "}
            </h5>
          )}
        </div>
      </div>
    </div>
  );
};
export default PendingInvites;
