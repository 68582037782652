import { useState, useEffect } from "react";
import baseurl from "../../baseurl";
const RegisteredStudents = (props) => {
  const [registeredStudents, setregisteredStudents] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [docs, setdocs] = useState({});
  useEffect(() => {
    setregisteredStudents(props.registeredStudents);
  }, [props]);

  const deleteStudent = (attendee) => {
    fetch(`${baseurl}/student`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + props.token,
      },
      body: JSON.stringify({
        email: attendee.email,
        studentId: attendee.studentId,
        collegeId: attendee.collegeId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          refreshStudentlist();
        }
      });
  };
  const refreshStudentlist = () => {
    fetch(`${baseurl}/student/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200
          ? setregisteredStudents(data.data)
          : setregisteredStudents([]);
      });
  };
  const getDocument = (attendee) => {
    setfetching(true);
    fetch(`${baseurl}/student/docs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
        studentId: attendee.studentId,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setdocs(data.data);
          setfetching(false);
        }
      });
  };
  const reInstateEdit = (attendee) => {
    fetch(`${baseurl}/student/editaccess`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + props.token,
      },
      body: JSON.stringify({
        studentId: attendee.studentId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          refreshStudentlist();
        }
      });
  };
  const clearDocument = () => {
    setdocs({});
    setfetching(false);
  };

  return (
    <div className="row">
      <div className="col-12 mt-5 mb-5">
        <h2>Registered Students - {registeredStudents.length}</h2>
        <h4>
          <strong>
            The registrations have ended and all details are frozen.
          </strong>
        </h4>

        {registeredStudents.length > 0 ? (
          <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email ID</th>
                  <th scope="col">USN</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Show Details</th>
                  <th scope="col">Show Documents</th>
                  <th scope="col">Edit access</th>

                  <th scope="col">Delete Student</th>
                </tr>
              </thead>
              {registeredStudents.map((attendee, index) => {
                return (
                  <>
                    <tbody>
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td className="text-start">
                          <strong>{attendee.name}</strong>
                        </td>
                        <td className="text-start">
                          <strong>{attendee.email}</strong>
                        </td>
                        <td className="text-start">
                          {attendee.usn ? attendee.usn.toUpperCase() : "-"}
                        </td>
                        <td className="text-start">
                          {attendee.phoneNo ? attendee.phoneNo : "-"}
                        </td>
                        <td className="text-start">
                          {attendee.gender
                            ? attendee.gender.toUpperCase()
                            : "-"}
                        </td>
                        <td className="text-start">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            data-bs-toggle="modal"
                            data-bs-target={"#" + attendee.studentId}
                          >
                            Show Details
                          </button>
                        </td>
                        <td className="text-start">
                          <button
                            type="button"
                            className="btn btn-outline-success "
                            data-bs-toggle="modal"
                            data-bs-target={"#doc" + attendee.studentId}
                            onClick={() => {
                              getDocument(attendee);
                            }}
                          >
                            Show Documents
                          </button>
                        </td>
                        <td className="text-start">
                          <button
                            type="button"
                            className="btn btn-outline-warning "
                            onClick={(e) => {
                              reInstateEdit(attendee);
                            }}
                            disabled
                          >
                            Reinstate edit access
                          </button>
                        </td>
                        <td className="text-start">
                          <button
                            type="button"
                            className="btn btn-outline-danger "
                            data-bs-toggle="modal"
                            data-bs-target={"#delete" + attendee.studentId}
                            disabled
                          >
                            Delete {attendee.name}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    {/* Delete Student */}
                    <div
                      className="modal fade"
                      id={"delete" + attendee.studentId}
                      tabIndex="-1"
                      aria-labelledby={"delete" + attendee.studentId}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content bg-white text-start">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Delete {attendee.name}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <span>
                              <strong>Name : </strong>
                              {attendee.name}
                            </span>
                            <br />
                            <span>
                              <strong>Email : </strong>
                              {attendee.email}
                            </span>
                            <br />
                            <span>
                              <strong>USN : </strong>
                              {attendee.usn}
                            </span>
                            <br />
                            <span>
                              <strong>Phone No. : </strong>
                              {attendee.phoneNo}
                            </span>
                            <br />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              data-bs-dismiss="modal"
                              onClick={(e) => deleteStudent(attendee)}
                            >
                              Delete {attendee.name}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Show Student Docs */}
                    <div
                      className="modal fade"
                      id={"doc" + attendee.studentId}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content bg-white text-start">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Documents for {attendee.name}
                            </h5>

                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => {
                                clearDocument();
                              }}
                            ></button>
                          </div>
                          {fetching ? (
                            <div className="modal-body">
                              <small>
                                Note:- It may take upto 4 seconds for the image
                                to load.
                              </small>
                              <h3>Loading...</h3>
                            </div>
                          ) : (
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <span>Photo</span>
                                  <br />
                                  {docs.photo ? (
                                    <img
                                      src={docs.photo}
                                      alt="photo"
                                      className="doc-img mt-2"
                                    />
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                  <br />
                                </div>
                                <div className="col-md-6 col-12">
                                  <span>Aadhar</span>
                                  <br />
                                  {docs.aadhar ? (
                                    <img
                                      src={docs.aadhar}
                                      alt="aadhar"
                                      className="doc-img mt-2"
                                    />
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                  <br />
                                </div>
                                <div className="col-md-6 col-12">
                                  <span>ID Card</span>
                                  <br />
                                  {docs.idCard ? (
                                    <img
                                      src={docs.idCard}
                                      alt="idCard"
                                      className="doc-img mt-2"
                                    />
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                  <br />
                                </div>
                                <div className="col-md-6 col-12">
                                  <span>Signature</span>
                                  <br />
                                  {docs.signature ? (
                                    <img
                                      src={docs.signature}
                                      alt="signature"
                                      className="doc-img mt-2"
                                    />
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                  <br />
                                </div>
                                <div className="col-md-6 col-12">
                                  <span>10th Marksheet</span>
                                  <br />
                                  {docs.tenth ? (
                                    <img
                                      src={docs.tenth}
                                      alt="tenth"
                                      className="doc-img mt-2"
                                    />
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                  <br />
                                </div>
                                <div className="col-md-6 col-12">
                                  <span>12th Certificate</span>
                                  <br />
                                  {docs.twelfth ? (
                                    <img
                                      src={docs.twelfth}
                                      alt="twelfth"
                                      className="doc-img mt-2"
                                    />
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                  <br />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => {
                                clearDocument();
                              }}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => {
                                getDocument(attendee);
                              }}
                            >
                              Refresh Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Show Student Details */}
                    <div
                      className="modal fade"
                      id={attendee.studentId}
                      tabindex="-1"
                      aria-labelledby={attendee.studentId}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content bg-white text-start">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              <strong>{attendee.name}</strong> |{" "}
                              <strong>{attendee.email}</strong>
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <span>
                              <strong>Name : </strong>
                              {attendee.name}
                            </span>
                            <br />
                            <span>
                              <strong>Email : </strong>
                              {attendee.email}
                            </span>
                            <br />
                            <span>
                              <strong>USN : </strong>
                              {attendee.usn}
                            </span>
                            <br />
                            <span>
                              <strong>Phone No. : </strong>
                              {attendee.phoneNo}
                            </span>
                            <br />
                            <hr />
                            {attendee.personalDetails ? (
                              <>
                                <span>
                                  <strong>Personal Details</strong>
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Father's Name : </strong>
                                  {attendee.personalDetails.fatherName
                                    ? attendee.personalDetails.fatherName
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Guardian's Name : </strong>
                                  {attendee.personalDetails.GuardianName
                                    ? attendee.personalDetails.GuardianName
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Mother's Name : </strong>
                                  {attendee.personalDetails.MotherName
                                    ? attendee.personalDetails.MotherName
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Age : </strong>
                                  {attendee.personalDetails.Age
                                    ? attendee.personalDetails.Age
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Date of Birth : </strong>
                                  {attendee.personalDetails.dob
                                    ? attendee.personalDetails.dob
                                    : "Not Provided"}
                                </span>
                              </>
                            ) : (
                              <span>No Personal Details</span>
                            )}
                            <br />
                            <hr />
                            {attendee.collegeDetails ? (
                              <>
                                <span>
                                  <strong>College Details</strong>
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>12th Passing Year : </strong>
                                  {attendee.collegeDetails.yearOfTwelfthPassing
                                    ? attendee.collegeDetails
                                        .yearOfTwelfthPassing
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Course : </strong>
                                  {attendee.collegeDetails.course
                                    ? attendee.collegeDetails.course
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Branch : </strong>
                                  {attendee.collegeDetails.branch
                                    ? attendee.collegeDetails.branch
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Semester : </strong>
                                  {attendee.collegeDetails.semester
                                    ? attendee.collegeDetails.semester
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>Year of Admission : </strong>
                                  {attendee.collegeDetails.yearOfAdmission
                                    ? attendee.collegeDetails.yearOfAdmission
                                    : "Not Provided"}
                                </span>
                              </>
                            ) : (
                              <span>No College Details</span>
                            )}
                            <br />
                            <hr />
                            {attendee.experience ? (
                              <>
                                <span>
                                  <strong>Experience</strong>
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>No. of Times Participated : </strong>
                                  {attendee.experience.noOfTimesParticipated
                                    ? attendee.experience.noOfTimesParticipated
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>
                                    Distinction in other Fields :{" "}
                                  </strong>
                                  {attendee.experience.distinctionInOtherField
                                    ? attendee.experience
                                        .distinctionInOtherField
                                    : "Not Provided"}
                                </span>
                                <br />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <strong>
                                    Distinction in other Fields :{" "}
                                  </strong>
                                  {attendee.experience.earnedDisctinction
                                    ? attendee.experience.earnedDisctinction
                                    : "Not Provided"}
                                </span>
                              </>
                            ) : (
                              <span>No Personal Details</span>
                            )}
                            <br />
                            <hr />
                            <span>
                              <strong>Profile Registered on : </strong>
                              {attendee.createdAt}
                            </span>
                            <br />
                            <span>
                              <strong>Last Updated on : </strong>
                              {attendee.updatedAt}
                            </span>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </table>
          </>
        ) : (
          <h5>None of the attendees have accepted the invitation yet.</h5>
        )}
      </div>
    </div>
  );
};
export default RegisteredStudents;
