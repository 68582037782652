import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PreLoginNavBar from "../NavBar/PreLoginNavBar";
import crypto from "crypto-js";
import Cookies from "universal-cookie";
import ErrorBox from "./ErrorBox";
import baseurl from "../../baseurl";

const AttendeeRegistration = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [collegeDetails, setcollegeData] = useState({});
  const [tokenData, settokenData] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setconfPassword] = useState("");
  const [message, setMessage] = useState("");
  const [userexist, setUserexist] = useState(false);
  const [signuperror, setSignuperror] = useState(false);
  const [passtext, setPasstext] = useState(true);
  const cookies = new Cookies();
  cookies.remove("token");
  cookies.remove("userInfo");
  const showPassword = () => {
    setPasstext(!passtext);
  };
  const [passwordError, setPasswordError] = useState("");
  useEffect(() => {
    if (token) {
      fetch(`${baseurl}/check-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: null,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            setMessage(data.message);
            setcollegeData(data.data.college);
            settokenData(data.tokenData);
            setEmail(data.tokenData.email);
            setName(data.data.invite.name);
          } else {
            setMessage(data.message);
          }
        });
    }
  }, [token]);

  if (!token) {
    return (
      <ErrorBox message={"The link is broken, please contact the admin."} />
    );
  }
  const setRemovePassword = () => {
    setPasswordError("The passwords do not match");
    setTimeout(() => {
      setPasswordError("");
    }, 30000);
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    if (password !== confPassword) {
      setRemovePassword();
      return;
    } else {
      fetch(`${baseurl}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          email: email,
          name: name,
          password: crypto.SHA256(password.trim()).toString(),
          role: tokenData.role,
          collegeId: collegeDetails.collegeId,
          timeRegistered: tokenData.timeRegistered,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.message === "User sign up successful") {
            navigate("/login");
          } else {
            data.message === "User already exists"
              ? setUserexist(true)
              : setUserexist(false);
            data.message === "Couldn't signup user"
              ? setSignuperror(true)
              : setSignuperror(false);
          }
        });
    }
  };

  return (
    <>
      <PreLoginNavBar />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 mt-5">
            <h1 className="mt-3">Attendee Registration</h1>
            {message === "Token verified successfully" ? (
              <form
                className="row g-3 text-start"
                autoComplete="off"
                onSubmit={handleSignUp}
              >
                <div className="col-12">
                  {userexist ? (
                    <div className="alert alert-danger" role="alert">
                      User already exists!
                    </div>
                  ) : null}
                  {signuperror ? (
                    <div className="alert alert-danger" role="alert">
                      There was an unknown error, please contact the admin!
                    </div>
                  ) : null}
                </div>
                <div className="col-12">
                  <label forHtml="name" className="form-label">
                    Name
                  </label>
                  <input
                    type={"text"}
                    name="name"
                    placeholder="Full Name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-12">
                  <label forHtml="email" className="form-label">
                    Email ID
                  </label>
                  <input
                    type={"email"}
                    name="email"
                    placeholder="example@domain.com"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                    readOnly={true}
                    required
                  />
                </div>
                <div className="col-12">
                  <label forHtml="college" className="form-label">
                    College
                  </label>
                  <input
                    type={"text"}
                    name="college"
                    defaultValue={collegeDetails.collegeName}
                    readOnly={true}
                    placeholder="XYZ College of Engineering (field may be removed later)"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
                <div className="col-12">
                  <label forhtml="inputPassword4" className="form-label">
                    Cultural Coordinator
                  </label>
                  <input
                    type={"text"}
                    name="cultural-coordinator-name"
                    defaultValue={collegeDetails.culturalHead.name}
                    readOnly={true}
                    placeholder="Jane Doe"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
                <div className="col-12">
                  <label forhtml="inputPassword4" className="form-label">
                    Physical Education Director
                  </label>
                  <input
                    type={"text"}
                    name="sports-director-name"
                    defaultValue={collegeDetails.sportsDirector.name}
                    readOnly={true}
                    placeholder="Jane Doe"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-6">
                  <label forhtml="inputPassword4" className="form-label">
                    Password
                  </label>
                  <input
                    type={passtext ? "password" : "text"}
                    name="password"
                    placeholder="*********"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label forhtml="inputPassword4" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={passtext ? "password" : "text"}
                    name="confirm-password"
                    placeholder="*********"
                    className="form-control mb-4"
                    value={confPassword}
                    onChange={(e) => setconfPassword(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-12">
                  <input type="checkbox" onClick={(e) => showPassword()} />
                  &nbsp;&nbsp;Show Password
                </div>
                <div className="col-12">
                  {passwordError !== "" && (
                    <span className="text-danger">{passwordError}</span>
                  )}
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary">
                    Sign Up
                  </button>
                </div>
              </form>
            ) : (
              <ErrorBox message={message} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AttendeeRegistration;
