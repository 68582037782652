import styled from "styled-components";
import NavButton from "./Button";
import LogOut from "../Logout/Logout";

const AdminMobileNavigation = (prop) => {
  const props = prop.props;
  return (
    <MobileNav>
      <br />
      <NavButton href="/dashboard" name="Dashboard" curPage={props.curPage} />
      <NavButton
        href="/invitecontingentmembers"
        name="Invite Contingent Member"
        curPage={props.curPage}
      />
      <NavButton
        href="/assign-events"
        name="Assign Events"
        curPage={props.curPage}
      />
      <NavButton href="/payment" name="Payment" curPage={props.curPage} />

      <LogOut />
    </MobileNav>
  );
};

const MobileNav = styled.nav`
  @media (min-width: 1281px) {
    display: none;
  }
  @media (max-width: 1281px) {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 135px;
    padding-top: 5vh;
    height: 100vh;
    width: 100vw;
    button {
      display: absolute;
      margin-bottom: 2%;
      width: 250px;
    }
  }
`;

export default AdminMobileNavigation;
