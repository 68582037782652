import { useEffect, useState } from "react";
import NonStudentNavBar from "../NavBar/NonStudentNavBar";
import baseurl from "../../baseurl";

const NonStudentDashboard = (props) => {
  const [details, setDetails] = useState(false);
  const [editable, setEditable] = useState(true);
  const [saved, setSaved] = useState(false);
  const [NonStudentPhone, setNonStudentPhone] = useState("");
  const [StudentDOB, setStudentDOB] = useState("");
  const [StudentAge, setStudentAge] = useState("");
  const [NonStudentGender, setNonStudentGender] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [signature, setSignature] = useState("");
  const [photo, setPhoto] = useState("");
  const [NonStudentAadhar, setNonStudentAadhar] = useState();
  const [NonStudentPhoto, setNonStudentPhoto] = useState();
  const [NonStudentSignature, setNonStudentSignature] = useState();
  const [PAlert, setPAlert] = useState("");
  const [AAlert, setAAlert] = useState("");
  const [SAlert, setSAlert] = useState("");
  const AgeRange = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
  ];

  useEffect(() => {
    fetch(`${baseurl}/non-student`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setDetails(data.data);
          setNonStudentPhone(data.data.phoneNo);
          setStudentDOB(data.data.dob);
          setStudentAge(data.data.age);
          setEditable(!data.data.isRegistered);
          setNonStudentGender(data.data.gender);
        } else {
        }
      });
    fetch(`${baseurl}/non-student/docs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setAadhar(data.data.aadhar);
          setSignature(data.data.signature);
          setPhoto(data.data.photo);
        }
      });
  }, [props.token]);
  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "bmp":
      case "png":
      case "jpeg":
      case "webp":
        //etc
        return true;
    }
    return false;
  };
  const uploadAadhar = (e) => {
    e.preventDefault();
    setAAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setNonStudentAadhar(file)
        : setAAlert("File size should be less than 2MB")
      : setAAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const uploadPhoto = (e) => {
    e.preventDefault();
    setPAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setNonStudentPhoto(file)
        : setPAlert("File size should be less than 2MB")
      : setPAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const uploadSignature = (e) => {
    e.preventDefault();
    setSAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setNonStudentSignature(file)
        : setSAlert("File size should be less than 2MB")
      : setSAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const submitPhotos = (e) => {
    e.preventDefault();
    if (isImage(NonStudentPhoto.name)) {
      if (NonStudentPhoto.size < 2000000) {
        const photoForm = new FormData();
        photoForm.append("photo", NonStudentPhoto);
        fetch(`${baseurl}/non-student/photo`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + props.token,
          },
          body: photoForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setPhoto(data.data.url);
          });
      } else {
        setPAlert("File size should be less than 2MB");
      }
    } else {
      setPAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }

    setTimeout(() => {
      setPAlert("");
    }, 4000);
  };
  const submitSignature = (e) => {
    e.preventDefault();
    if (isImage(NonStudentSignature.name)) {
      if (NonStudentSignature.size < 2000000) {
        const signatureForm = new FormData();
        signatureForm.append("signature", NonStudentSignature);
        fetch(`${baseurl}/non-student/signature`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + props.token,
          },
          body: signatureForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setSignature(data.data.url);
          });
      } else {
        setSAlert("File size should be less than 2MB");
      }
    } else {
      setSAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setSAlert("");
    }, 4000);
  };
  const submitAadhar = (e) => {
    e.preventDefault();
    if (isImage(NonStudentAadhar.name)) {
      if (NonStudentAadhar.size < 2000000) {
        const aadharForm = new FormData();
        aadharForm.append("aadhar", NonStudentAadhar);
        fetch(`${baseurl}/non-student/aadhar`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + props.token,
          },
          body: aadharForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setAadhar(data.data.url);
          });
      } else {
        setAAlert("File size should be less than 2MB");
      }
    } else {
      setAAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setAAlert("");
    }, 4000);
  };
  const updateAge = (e) => {
    e.preventDefault();
    var dob = new Date(StudentDOB);
    let secDiff = Date.now() - dob.getTime();
    const finalAge = Math.floor(secDiff / (60 * 60 * 24 * 365.25 * 1000));

    setStudentAge(finalAge.toString());
  };
  const saveData = (e) => {
    e.preventDefault();

    var data = {
      nonStudentId: details.nonStudentId,
      name: details.name,
      email: details.email,
      phoneNo: NonStudentPhone,
      collegeId: details.collegeId,
      gender: NonStudentGender,
      age: StudentAge,
      dob: StudentDOB,

      isRegistered: false,
    };
    console.log(data);
    fetch(`${baseurl}/non-student`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + props.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          savedData();
        } else {
          setSaved(false);
        }
      });
  };
  const savedData = () => {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 4000);
  };
  const sendData = (e) => {
    e.preventDefault();
    var data = {
      studentId: details.studentId,
      name: details.name,
      email: details.email,
      phoneNo: NonStudentPhone,
      collegeId: details.collegeId,
      gender: NonStudentGender,
      Age: StudentAge,
      dob: StudentDOB,

      isRegistered: true,
    };
    fetch(`${baseurl}/non-student`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + props.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setEditable(false);
        } else {
          setSaved(false);
        }
      });
  };
  return (
    <>
      <NonStudentNavBar />
      <div className="container">
        <div className="row">
          <form onSubmit={sendData}>
            <div className="col-12">
              <div className="row justify-content-center mt-5 mb-5 text-start">
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center">
                    {saved ? (
                      <div className="alert alert-success" role="alert">
                        The provided details have been saved.
                      </div>
                    ) : null}
                    {editable ? null : (
                      <div className="alert alert-success" role="alert">
                        Congratulations! Your details have been successfully
                        submitted. You can proceed to the next step, which will
                        be uploading your documents. Please click on the 'Upload
                        Documents' section for the same.
                      </div>
                    )}
                  </div>
                  <h4>
                    <strong>
                      The registrations have ended and all details are frozen.
                    </strong>
                  </h4>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center">
                    <span>
                      Note: Data once submitted cannot be changed. Please ensure
                      all details filled in are correct before clicking on the
                      Submit button. To save the details in the database and
                      continue editing, click on the save button.
                    </span>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                {/*General Details*/}
                <div className="col-md-5 col-12 mt-2">
                  <div className="row text-start">
                    <h3>General Details</h3>
                    <div className="col-12">
                      <label forhtml="StudentName" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="StudentName"
                        readOnly={true}
                        value={details.name}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentEmail" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="StudentEmail"
                        readOnly={true}
                        value={details.email}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentEmail" className="form-label">
                        Gender
                      </label>
                      <select
                        className="form-select"
                        id="StudentEmail"
                        disabled={!editable}
                        value={NonStudentGender}
                        onChange={(e) => setNonStudentGender(e.target.value)}
                      >
                        <option value={""} selected disabled>
                          Choose one...
                        </option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentPhone" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type={"tel"}
                        name="StudentPhone"
                        placeholder="Enter your valid Phone Number"
                        className="form-control"
                        value={NonStudentPhone}
                        onChange={(e) => setNonStudentPhone(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-12"></div>
                {/*Personal Details*/}
                <div className="col-md-5 col-12 mt-5">
                  <div className="row text-start">
                    <h3>Personal Details</h3>
                    <div className="col-12">
                      <label forhtml="StudentDOB" className="form-label">
                        Date of Birth
                      </label>
                      <input
                        type={"date"}
                        name="StudentDOB"
                        placeholder="Guardian's Full Name"
                        className="form-control"
                        value={StudentDOB}
                        onChange={(e) => {
                          setStudentDOB(e.target.value);
                          updateAge(e);
                        }}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="StudentAge" className="form-label">
                        Age
                      </label>
                      <select
                        name="StudentAge"
                        placeholder="Age"
                        className="form-select"
                        value={StudentAge}
                        onChange={(e) => setStudentAge(e.target.value)}
                        autoComplete="off"
                        disabled={!editable}
                        required
                      >
                        <option value={""} selected disabled>
                          Choose One...
                        </option>
                        {AgeRange.map((index) => {
                          return (
                            <option value={index.toString()}>{index}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-5 mb-5 text-start">
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center">
                    {saved ? (
                      <div className="alert alert-success" role="alert">
                        The provided details have been saved.
                      </div>
                    ) : null}
                    {editable ? null : (
                      <div className="alert alert-success" role="alert">
                        Congratulations! Your details have been successfully
                        submitted. You can proceed to the next step, which will
                        be forming your contingent. Please check your Email
                        inbox for further details.
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-lg-1 col-4 text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={saveData}
                      disabled={!editable}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-lg-1 col-4 text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      disabled={!editable}
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Confirm Student Details
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <p>
                            No changes can be made in the above details once
                            submitted.{" "}
                          </p>
                          <br />
                          <span>
                            Name: <strong>{details.name}</strong>
                          </span>
                          <br />
                          <span>
                            Email: <strong>{details.email}</strong>
                          </span>
                          <br />
                          <span>
                            Phone Number: <strong>{NonStudentPhone}</strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Personal Details
                          </h5>
                          <span>
                            Age: <strong>{StudentAge}</strong>
                          </span>
                          <br />
                          <span>
                            DOB: <strong>{StudentDOB}</strong>
                          </span>
                          <hr />

                          {editable ? null : (
                            <>
                              <hr />
                              <div className="alert alert-success" role="alert">
                                Congratulations! Your details have been
                                successfully submitted. You can proceed to the
                                next step, which will be uploading your
                                documents. Please click on the 'Upload
                                Documents' section for the same.
                              </div>
                            </>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            disabled={!editable}
                          >
                            Edit
                          </button>
                          <button
                            type="submit"
                            data-bs-dismiss="modal"
                            className="btn btn-danger"
                            disabled={!editable}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center mt-4">
                    <span>
                      Note: Data once submitted cannot be changed. Please ensure
                      all details filled in are correct before clicking on the
                      Submit button. To save the details in the database and
                      continue editing, click on the save button.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <h1>Upload Documents</h1>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="alert alert-secondary">
                  <p>
                    All images must be of size 2 MB or less. <br />
                    All image files must be in JPG, JPEG, PNG or WEBP formats
                    only.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5 mb-5">
              <div className="col-md-6 mt-5">
                <h3>Upload Photo</h3>
                <form
                  className="row justify-content-center mt-3 "
                  onSubmit={submitPhotos}
                >
                  <div className="col-lg-8 col-12">
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        disabled
                        id="Photo"
                        name="Photo"
                        onChange={(e) => uploadPhoto(e)}
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        required
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <button className="btn btn-outline-primary" type="submit">
                        Upload File
                      </button>
                    </div>
                    {PAlert === "" ? null : (
                      <div className="alert alert-danger mt-3" role="alert">
                        {PAlert}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="card mt-3">
                      {photo === "" ? (
                        <div className="card-body">
                          <br />
                          <p>Loading...</p>
                          <br />
                        </div>
                      ) : !photo ? (
                        <div className="card-body">
                          <br />
                          <p>Please upload the file!</p>
                          <br />
                        </div>
                      ) : (
                        <img src={photo} className="card-img-top" alt="..." />
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6  mt-5">
                <h3>Upload Signature</h3>
                <form
                  className="row justify-content-center mt-3"
                  onSubmit={submitSignature}
                >
                  <div className="col-lg-8 col-12">
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        disabled
                        id="Signature"
                        name="Signature"
                        onChange={(e) => uploadSignature(e)}
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        required
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <button
                        className="btn btn-outline-primary"
                        type="submit"
                        id="inputGroupFileAddon04"
                      >
                        Upload File
                      </button>
                    </div>
                    {SAlert === "" ? null : (
                      <div className="alert alert-danger mt-3" role="alert">
                        {SAlert}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="card mt-3">
                      {signature === "" ? (
                        <div className="card-body">
                          <br />
                          <p>Loading...</p>
                          <br />
                        </div>
                      ) : !signature ? (
                        <div className="card-body">
                          <br />
                          <p>Please upload the file!</p>
                          <br />
                        </div>
                      ) : (
                        <img
                          src={signature}
                          className="card-img-top"
                          alt="..."
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 mt-5">
                <h3>Upload Aadhar</h3>
                <form
                  className="row justify-content-center mt-3"
                  onSubmit={submitAadhar}
                >
                  <div className="col-lg-8 col-12">
                    {" "}
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        disabled
                        id="Aadhar"
                        name="Aadhar"
                        onChange={(e) => uploadAadhar(e)}
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        required
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <button
                        className="btn btn-outline-primary"
                        id="Aadhar"
                        type="submit"
                      >
                        Upload File
                      </button>
                    </div>
                    {AAlert === "" ? null : (
                      <div className="alert alert-danger mt-3" role="alert">
                        {AAlert}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="card mt-3">
                      {aadhar === "" ? (
                        <div className="card-body">
                          <br />
                          <p>Loading...</p>
                          <br />
                        </div>
                      ) : !aadhar ? (
                        <div className="card-body">
                          <br />
                          <p>Please upload the file!</p>
                          <br />
                        </div>
                      ) : (
                        <img src={aadhar} className="card-img-top" alt="..." />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NonStudentDashboard;
