import { useEffect, useState } from "react";
import AdminNavBar from "../NavBar/AdminNavBar";
import Cookies from "universal-cookie";
import baseurl from "../../baseurl";
import eventinfo from "./eventinfo";
const AssignEvents = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const userInfo = cookies.get("userInfo");
  const [eventInfo, setEventInfo] = useState({});
  const [details, setDetails] = useState({});
  const [student, setStudent] = useState([]);
  const [nonStudent, setNonStudent] = useState([]);
  const [accompanists, setAccompanists] = useState([]);
  const [combined, setCombined] = useState([]);
  const [isEventsSubmited, setIsEventsSubmited] = useState(false);

  const [eve0p1, setEve0p1] = useState("");
  const [eve1p1, setEve1p1] = useState("");
  const [eve2p1, setEve2p1] = useState("");
  const [eve3p1, setEve3p1] = useState("");
  const [eve4p1, setEve4p1] = useState("");
  const [eve5p1, setEve5p1] = useState("");
  const [eve5p2, setEve5p2] = useState("");
  const [eve5p3, setEve5p3] = useState("");
  const [eve5p4, setEve5p4] = useState("");
  const [eve5p5, setEve5p5] = useState("");
  const [eve5p6, setEve5p6] = useState("");
  const [eve6p1, setEve6p1] = useState("");
  const [eve6p2, setEve6p2] = useState("");
  const [eve6p3, setEve6p3] = useState("");
  const [eve6p4, setEve6p4] = useState("");
  const [eve6p5, setEve6p5] = useState("");
  const [eve6p6, setEve6p6] = useState("");
  const [eve7p1, setEve7p1] = useState("");
  const [eve7p2, setEve7p2] = useState("");
  const [eve7p3, setEve7p3] = useState("");
  const [eve7p4, setEve7p4] = useState("");
  const [eve7p5, setEve7p5] = useState("");
  const [eve7p6, setEve7p6] = useState("");
  const [eve7p7, setEve7p7] = useState("");
  const [eve7p8, setEve7p8] = useState("");
  const [eve7p9, setEve7p9] = useState("");
  const [eve7p10, setEve7p10] = useState("");
  const [eve7p11, setEve7p11] = useState("");
  const [eve7p12, setEve7p12] = useState("");
  const [eve8p1, setEve8p1] = useState("");
  const [eve8p2, setEve8p2] = useState("");
  const [eve8p3, setEve8p3] = useState("");
  const [eve8p4, setEve8p4] = useState("");
  const [eve8p5, setEve8p5] = useState("");
  const [eve8p6, setEve8p6] = useState("");
  const [eve8p7, setEve8p7] = useState("");
  const [eve8p8, setEve8p8] = useState("");
  const [eve8p9, setEve8p9] = useState("");
  const [eve8p10, setEve8p10] = useState("");
  const [eve9p1, setEve9p1] = useState("");
  const [eve10p1, setEve10p1] = useState("");
  const [eve10p2, setEve10p2] = useState("");
  const [eve10p3, setEve10p3] = useState("");
  const [eve11p1, setEve11p1] = useState("");
  const [eve11p2, setEve11p2] = useState("");
  const [eve12p1, setEve12p1] = useState("");
  const [eve13p1, setEve13p1] = useState("");
  const [eve13p2, setEve13p2] = useState("");
  const [eve13p3, setEve13p3] = useState("");
  const [eve13p4, setEve13p4] = useState("");
  const [eve13p5, setEve13p5] = useState("");
  const [eve13p6, setEve13p6] = useState("");
  const [eve14p1, setEve14p1] = useState("");
  const [eve14p2, setEve14p2] = useState("");
  const [eve14p3, setEve14p3] = useState("");
  const [eve14p4, setEve14p4] = useState("");
  const [eve14p5, setEve14p5] = useState("");
  const [eve14p6, setEve14p6] = useState("");
  const [eve15p1, setEve15p1] = useState("");
  const [eve16p1, setEve16p1] = useState("");
  const [eve16p2, setEve16p2] = useState("");
  const [eve16p3, setEve16p3] = useState("");
  const [eve16p4, setEve16p4] = useState("");
  const [eve16p5, setEve16p5] = useState("");
  const [eve16p6, setEve16p6] = useState("");
  const [eve16p7, setEve16p7] = useState("");
  const [eve16p8, setEve16p8] = useState("");
  const [eve16p9, setEve16p9] = useState("");
  const [eve17p1, setEve17p1] = useState("");
  const [eve18p1, setEve18p1] = useState("");
  const [eve19p1, setEve19p1] = useState("");
  const [eve20p1, setEve20p1] = useState("");
  const [eve20p2, setEve20p2] = useState("");
  const [eve20p3, setEve20p3] = useState("");
  const [eve20p4, setEve20p4] = useState("");
  const [eve21p1, setEve21p1] = useState("");
  const [eve22p1, setEve22p1] = useState("");
  const [eve23p1, setEve23p1] = useState("");
  const [eve24p1, setEve24p1] = useState("");

  const [eve0a1, setEve0a1] = useState("");
  const [eve0a2, setEve0a2] = useState("");
  const [eve1a1, setEve1a1] = useState("");
  const [eve1a2, setEve1a2] = useState("");
  const [eve2a1, setEve2a1] = useState("");
  const [eve2a2, setEve2a2] = useState("");
  const [eve3a1, setEve3a1] = useState("");
  const [eve3a2, setEve3a2] = useState("");
  const [eve4a1, setEve4a1] = useState("");
  const [eve4a2, setEve4a2] = useState("");
  const [eve5a1, setEve5a1] = useState("");
  const [eve5a2, setEve5a2] = useState("");
  const [eve5a3, setEve5a3] = useState("");
  const [eve6a1, setEve6a1] = useState("");
  const [eve6a2, setEve6a2] = useState("");
  const [eve6a3, setEve6a3] = useState("");
  const [eve7a1, setEve7a1] = useState("");
  const [eve7a2, setEve7a2] = useState("");
  const [eve7a3, setEve7a3] = useState("");
  const [eve8a1, setEve8a1] = useState("");
  const [eve8a2, setEve8a2] = useState("");
  const [eve8a3, setEve8a3] = useState("");
  const [eve8a4, setEve8a4] = useState("");
  const [eve8a5, setEve8a5] = useState("");
  const [eve9a1, setEve9a1] = useState("");
  const [eve9a2, setEve9a2] = useState("");
  const [eve9a3, setEve9a3] = useState("");
  const [eve13a1, setEve13a1] = useState("");
  const [eve13a2, setEve13a2] = useState("");
  const [eve13a3, setEve13a3] = useState("");
  const [eve14a1, setEve14a1] = useState("");
  const [eve14a2, setEve14a2] = useState("");
  const [eve16a1, setEve16a1] = useState("");
  const [eve16a2, setEve16a2] = useState("");
  const [eve16a3, setEve16a3] = useState("");
  const [eve16a4, setEve16a4] = useState("");
  const [eve16a5, setEve16a5] = useState("");
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function checkIt() {
    let args = Array.from(arguments);
    var filtered = args.filter((element) => {
      if (element !== null && element !== "") return element;
    });
    var filteredSet = new Set(filtered);
    if (filteredSet.size === filtered.length) {
      return true;
    }
    console.log(filtered.length, filteredSet.size, filtered, filteredSet);
    console.log(false);
    return false;
  }

  function getCount() {
    let args = Array.from(arguments);
    var filtered = args.filter((ele) => {
      if (ele && ele !== "") return ele;
    });
    return filtered.length;
  }

  const isDataValid = (e) => {
    if (
      checkIt(eve0a1, eve0a2, eve0p1) &&
      checkIt(eve1p1, eve1a1, eve1a2) &&
      checkIt(eve2p1, eve2a1, eve2a2) &&
      checkIt(eve3p1, eve3a1, eve3a2) &&
      checkIt(eve4p1, eve4a1, eve4a2) &&
      checkIt(
        eve5p1,
        eve5p2,
        eve5p3,
        eve5p4,
        eve5p5,
        eve5p6,
        eve5a1,
        eve5a2,
        eve5a3
      ) &&
      checkIt(
        eve6p1,
        eve6p2,
        eve6p3,
        eve6p4,
        eve6p5,
        eve6p6,
        eve6a1,
        eve6a2,
        eve6a3
      ) &&
      checkIt(
        eve7p1,
        eve7p2,
        eve7p3,
        eve7p4,
        eve7p5,
        eve7p6,
        eve7p7,
        eve7p8,
        eve7p9,
        eve7p10,
        eve7p11,
        eve7p12,
        eve7a1,
        eve7a2,
        eve7a3
      ) &&
      checkIt(
        eve8p1,
        eve8p2,
        eve8p3,
        eve8p4,
        eve8p5,
        eve8p6,
        eve8p7,
        eve8p8,
        eve8p9,
        eve8p10,
        eve8a1,
        eve8a2,
        eve8a3,
        eve8a4,
        eve8a5
      ) &&
      checkIt(eve9p1, eve9a1, eve9a2, eve9a3) &&
      checkIt(eve10p1, eve10p2, eve10p3) &&
      checkIt(eve11p1, eve11p2) &&
      checkIt(eve12p1) &&
      checkIt(
        eve13p1,
        eve13p2,
        eve13p3,
        eve13p4,
        eve13p5,
        eve13p6,
        eve13a1,
        eve13a2,
        eve13a3
      ) &&
      checkIt(
        eve14p1,
        eve14p2,
        eve14p3,
        eve14p4,
        eve14p5,
        eve14p6,
        eve14a1,
        eve14a2
      ) &&
      checkIt(eve15p1) &&
      checkIt(
        eve16p1,
        eve16p2,
        eve16p3,
        eve16p4,
        eve16p5,
        eve16p6,
        eve16p7,
        eve16p8,
        eve16p9,
        eve16a1,
        eve16a2,
        eve16a3,
        eve16a4,
        eve16a5
      ) &&
      checkIt(eve17p1) &&
      checkIt(eve18p1) &&
      checkIt(eve19p1) &&
      checkIt(eve20p1, eve20p2, eve20p3, eve20p4) &&
      checkIt(eve21p1) &&
      checkIt(eve22p1) &&
      checkIt(eve23p1) &&
      checkIt(eve24p1)
    ) {
      alert("Data updated successfully!");
      return true;
    }
    alert("Invalid Data");
    resetData();
    return false;
  };

  function resetData() {
    var data = { data: details };
    setDetails(data.data);
    setEve0p1(
      data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].p[0]
        ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].p[0]
        : ""
    );
    setEve1p1(
      data.data.events["Classical Instrumental Solo (Percussion Tala Vadya)"]
        .p[0]
        ? data.data.events[
            "Classical Instrumental Solo (Percussion Tala Vadya)"
          ].p[0]
        : ""
    );
    setEve2p1(
      data.data.events[
        "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
      ].p[0]
        ? data.data.events[
            "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
          ].p[0]
        : ""
    );
    setEve3p1(
      data.data.events["Light Vocal Solo (Indian)"].p[0]
        ? data.data.events["Light Vocal Solo (Indian)"].p[0]
        : ""
    );
    setEve4p1(
      data.data.events["Western Vocal Solo"].p[0]
        ? data.data.events["Western Vocal Solo"].p[0]
        : ""
    );
    setEve5p1(
      data.data.events["Group Song (Indian)"].p[0]
        ? data.data.events["Group Song (Indian)"].p[0]
        : ""
    );
    setEve5p2(
      data.data.events["Group Song (Indian)"].p[1]
        ? data.data.events["Group Song (Indian)"].p[1]
        : ""
    );
    setEve5p3(
      data.data.events["Group Song (Indian)"].p[2]
        ? data.data.events["Group Song (Indian)"].p[2]
        : ""
    );
    setEve5p4(
      data.data.events["Group Song (Indian)"].p[3]
        ? data.data.events["Group Song (Indian)"].p[3]
        : ""
    );
    setEve5p5(
      data.data.events["Group Song (Indian)"].p[4]
        ? data.data.events["Group Song (Indian)"].p[4]
        : ""
    );
    setEve5p6(
      data.data.events["Group Song (Indian)"].p[5]
        ? data.data.events["Group Song (Indian)"].p[5]
        : ""
    );
    setEve6p1(
      data.data.events["Group Song (Western)"].p[0]
        ? data.data.events["Group Song (Western)"].p[0]
        : ""
    );
    setEve6p2(
      data.data.events["Group Song (Western)"].p[1]
        ? data.data.events["Group Song (Western)"].p[1]
        : ""
    );
    setEve6p3(
      data.data.events["Group Song (Western)"].p[2]
        ? data.data.events["Group Song (Western)"].p[2]
        : ""
    );
    setEve6p4(
      data.data.events["Group Song (Western)"].p[3]
        ? data.data.events["Group Song (Western)"].p[3]
        : ""
    );
    setEve6p5(
      data.data.events["Group Song (Western)"].p[4]
        ? data.data.events["Group Song (Western)"].p[4]
        : ""
    );
    setEve6p6(
      data.data.events["Group Song (Western)"].p[5]
        ? data.data.events["Group Song (Western)"].p[5]
        : ""
    );
    setEve7p1(
      data.data.events["Folk Orchestra"].p[0]
        ? data.data.events["Folk Orchestra"].p[0]
        : ""
    );
    setEve7p2(
      data.data.events["Folk Orchestra"].p[1]
        ? data.data.events["Folk Orchestra"].p[1]
        : ""
    );
    setEve7p3(
      data.data.events["Folk Orchestra"].p[2]
        ? data.data.events["Folk Orchestra"].p[2]
        : ""
    );
    setEve7p4(
      data.data.events["Folk Orchestra"].p[3]
        ? data.data.events["Folk Orchestra"].p[3]
        : ""
    );
    setEve7p5(
      data.data.events["Folk Orchestra"].p[4]
        ? data.data.events["Folk Orchestra"].p[4]
        : ""
    );
    setEve7p6(
      data.data.events["Folk Orchestra"].p[5]
        ? data.data.events["Folk Orchestra"].p[5]
        : ""
    );
    setEve7p7(
      data.data.events["Folk Orchestra"].p[6]
        ? data.data.events["Folk Orchestra"].p[6]
        : ""
    );
    setEve7p8(
      data.data.events["Folk Orchestra"].p[7]
        ? data.data.events["Folk Orchestra"].p[7]
        : ""
    );
    setEve7p9(
      data.data.events["Folk Orchestra"].p[8]
        ? data.data.events["Folk Orchestra"].p[8]
        : ""
    );
    setEve7p10(
      data.data.events["Folk Orchestra"].p[9]
        ? data.data.events["Folk Orchestra"].p[9]
        : ""
    );
    setEve7p11(
      data.data.events["Folk Orchestra"].p[10]
        ? data.data.events["Folk Orchestra"].p[10]
        : ""
    );
    setEve7p12(
      data.data.events["Folk Orchestra"].p[11]
        ? data.data.events["Folk Orchestra"].p[11]
        : ""
    );
    setEve8p1(
      data.data.events["Folk / Tribal Dance"].p[0]
        ? data.data.events["Folk / Tribal Dance"].p[0]
        : ""
    );
    setEve8p2(
      data.data.events["Folk / Tribal Dance"].p[1]
        ? data.data.events["Folk / Tribal Dance"].p[1]
        : ""
    );
    setEve8p3(
      data.data.events["Folk / Tribal Dance"].p[2]
        ? data.data.events["Folk / Tribal Dance"].p[2]
        : ""
    );
    setEve8p4(
      data.data.events["Folk / Tribal Dance"].p[3]
        ? data.data.events["Folk / Tribal Dance"].p[3]
        : ""
    );
    setEve8p5(
      data.data.events["Folk / Tribal Dance"].p[4]
        ? data.data.events["Folk / Tribal Dance"].p[4]
        : ""
    );
    setEve8p6(
      data.data.events["Folk / Tribal Dance"].p[5]
        ? data.data.events["Folk / Tribal Dance"].p[5]
        : ""
    );
    setEve8p7(
      data.data.events["Folk / Tribal Dance"].p[6]
        ? data.data.events["Folk / Tribal Dance"].p[6]
        : ""
    );
    setEve8p8(
      data.data.events["Folk / Tribal Dance"].p[7]
        ? data.data.events["Folk / Tribal Dance"].p[7]
        : ""
    );
    setEve8p9(
      data.data.events["Folk / Tribal Dance"].p[8]
        ? data.data.events["Folk / Tribal Dance"].p[8]
        : ""
    );
    setEve8p10(
      data.data.events["Folk / Tribal Dance"].p[9]
        ? data.data.events["Folk / Tribal Dance"].p[9]
        : ""
    );
    setEve9p1(
      data.data.events["Classical Dance Solo"].p[0]
        ? data.data.events["Classical Dance Solo"].p[0]
        : ""
    );
    setEve10p1(
      data.data.events["Quiz"].p[0] ? data.data.events["Quiz"].p[0] : ""
    );
    setEve10p2(
      data.data.events["Quiz"].p[1] ? data.data.events["Quiz"].p[1] : ""
    );
    setEve10p3(
      data.data.events["Quiz"].p[2] ? data.data.events["Quiz"].p[2] : ""
    );
    setEve11p1(
      data.data.events["Debate"].p[0] ? data.data.events["Debate"].p[0] : ""
    );
    setEve11p2(
      data.data.events["Debate"].p[1] ? data.data.events["Debate"].p[1] : ""
    );
    setEve12p1(
      data.data.events["Elocution"].p[0]
        ? data.data.events["Elocution"].p[0]
        : ""
    );
    setEve13p1(
      data.data.events["Skit"].p[0] ? data.data.events["Skit"].p[0] : ""
    );
    setEve13p2(
      data.data.events["Skit"].p[1] ? data.data.events["Skit"].p[1] : ""
    );
    setEve13p3(
      data.data.events["Skit"].p[2] ? data.data.events["Skit"].p[2] : ""
    );
    setEve13p4(
      data.data.events["Skit"].p[3] ? data.data.events["Skit"].p[3] : ""
    );
    setEve13p5(
      data.data.events["Skit"].p[4] ? data.data.events["Skit"].p[4] : ""
    );
    setEve13p6(
      data.data.events["Skit"].p[5] ? data.data.events["Skit"].p[5] : ""
    );
    setEve14p1(
      data.data.events["Mime"].p[0] ? data.data.events["Mime"].p[0] : ""
    );
    setEve14p2(
      data.data.events["Mime"].p[1] ? data.data.events["Mime"].p[1] : ""
    );
    setEve14p3(
      data.data.events["Mime"].p[2] ? data.data.events["Mime"].p[2] : ""
    );
    setEve14p4(
      data.data.events["Mime"].p[3] ? data.data.events["Mime"].p[3] : ""
    );
    setEve14p5(
      data.data.events["Mime"].p[4] ? data.data.events["Mime"].p[4] : ""
    );
    setEve14p6(
      data.data.events["Mime"].p[5] ? data.data.events["Mime"].p[5] : ""
    );
    setEve15p1(
      data.data.events["Mimicry"].p[0] ? data.data.events["Mimicry"].p[0] : ""
    );
    setEve16p1(
      data.data.events["One Act Play"].p[0]
        ? data.data.events["One Act Play"].p[0]
        : ""
    );
    setEve16p2(
      data.data.events["One Act Play"].p[1]
        ? data.data.events["One Act Play"].p[1]
        : ""
    );
    setEve16p3(
      data.data.events["One Act Play"].p[2]
        ? data.data.events["One Act Play"].p[2]
        : ""
    );
    setEve16p4(
      data.data.events["One Act Play"].p[3]
        ? data.data.events["One Act Play"].p[3]
        : ""
    );
    setEve16p5(
      data.data.events["One Act Play"].p[4]
        ? data.data.events["One Act Play"].p[4]
        : ""
    );
    setEve16p6(
      data.data.events["One Act Play"].p[5]
        ? data.data.events["One Act Play"].p[5]
        : ""
    );
    setEve16p7(
      data.data.events["One Act Play"].p[6]
        ? data.data.events["One Act Play"].p[6]
        : ""
    );
    setEve16p8(
      data.data.events["One Act Play"].p[7]
        ? data.data.events["One Act Play"].p[7]
        : ""
    );
    setEve16p9(
      data.data.events["One Act Play"].p[8]
        ? data.data.events["One Act Play"].p[8]
        : ""
    );
    setEve17p1(
      data.data.events["Collage"].p[0] ? data.data.events["Collage"].p[0] : ""
    );
    setEve18p1(
      data.data.events["Rangoli"].p[0] ? data.data.events["Rangoli"].p[0] : ""
    );
    setEve19p1(
      data.data.events["Cartooning"].p[0]
        ? data.data.events["Cartooning"].p[0]
        : ""
    );
    setEve20p1(
      data.data.events["Installation"].p[0]
        ? data.data.events["Installation"].p[0]
        : ""
    );
    setEve20p2(
      data.data.events["Installation"].p[1]
        ? data.data.events["Installation"].p[1]
        : ""
    );
    setEve20p3(
      data.data.events["Installation"].p[2]
        ? data.data.events["Installation"].p[2]
        : ""
    );
    setEve20p4(
      data.data.events["Installation"].p[3]
        ? data.data.events["Installation"].p[3]
        : ""
    );
    setEve21p1(
      data.data.events["Poster Making"].p[0]
        ? data.data.events["Poster Making"].p[0]
        : ""
    );
    setEve22p1(
      data.data.events["Clay Modelling"].p[0]
        ? data.data.events["Clay Modelling"].p[0]
        : ""
    );
    setEve23p1(
      data.data.events["On Spot Painting"].p[0]
        ? data.data.events["On Spot Painting"].p[0]
        : ""
    );
    setEve24p1(
      data.data.events["Spot Photography"].p[0]
        ? data.data.events["Spot Photography"].p[0]
        : ""
    );
    setEve0a1(
      data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[0]
        ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[0]
        : ""
    );
    setEve0a2(
      data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[1]
        ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[1]
        : ""
    );
    setEve1a1(
      data.data.events["Classical Instrumental Solo (Percussion Tala Vadya)"]
        .a[0]
        ? data.data.events[
            "Classical Instrumental Solo (Percussion Tala Vadya)"
          ].a[0]
        : ""
    );
    setEve1a2(
      data.data.events["Classical Instrumental Solo (Percussion Tala Vadya)"]
        .a[1]
        ? data.data.events[
            "Classical Instrumental Solo (Percussion Tala Vadya)"
          ].a[1]
        : ""
    );
    setEve2a1(
      data.data.events[
        "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
      ].a[0]
        ? data.data.events[
            "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
          ].a[0]
        : ""
    );
    setEve2a2(
      data.data.events[
        "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
      ].a[1]
        ? data.data.events[
            "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
          ].a[1]
        : ""
    );
    setEve3a1(
      data.data.events["Light Vocal Solo (Indian)"].a[0]
        ? data.data.events["Light Vocal Solo (Indian)"].a[0]
        : ""
    );
    setEve3a2(
      data.data.events["Light Vocal Solo (Indian)"].a[1]
        ? data.data.events["Light Vocal Solo (Indian)"].a[1]
        : ""
    );
    setEve4a1(
      data.data.events["Western Vocal Solo"].a[0]
        ? data.data.events["Western Vocal Solo"].a[0]
        : ""
    );
    setEve4a2(
      data.data.events["Western Vocal Solo"].a[1]
        ? data.data.events["Western Vocal Solo"].a[1]
        : ""
    );
    setEve5a1(
      data.data.events["Group Song (Indian)"].a[0]
        ? data.data.events["Group Song (Indian)"].a[0]
        : ""
    );
    setEve5a2(
      data.data.events["Group Song (Indian)"].a[1]
        ? data.data.events["Group Song (Indian)"].a[1]
        : ""
    );
    setEve5a3(
      data.data.events["Group Song (Indian)"].a[2]
        ? data.data.events["Group Song (Indian)"].a[2]
        : ""
    );
    setEve6a1(
      data.data.events["Group Song (Western)"].a[0]
        ? data.data.events["Group Song (Western)"].a[0]
        : ""
    );
    setEve6a2(
      data.data.events["Group Song (Western)"].a[1]
        ? data.data.events["Group Song (Western)"].a[1]
        : ""
    );
    setEve6a3(
      data.data.events["Group Song (Western)"].a[2]
        ? data.data.events["Group Song (Western)"].a[2]
        : ""
    );
    setEve7a1(
      data.data.events["Folk Orchestra"].a[0]
        ? data.data.events["Folk Orchestra"].a[0]
        : ""
    );
    setEve7a2(
      data.data.events["Folk Orchestra"].a[1]
        ? data.data.events["Folk Orchestra"].a[1]
        : ""
    );
    setEve7a3(
      data.data.events["Folk Orchestra"].a[2]
        ? data.data.events["Folk Orchestra"].a[2]
        : ""
    );
    setEve8a1(
      data.data.events["Folk / Tribal Dance"].a[0]
        ? data.data.events["Folk / Tribal Dance"].a[0]
        : ""
    );
    setEve8a2(
      data.data.events["Folk / Tribal Dance"].a[1]
        ? data.data.events["Folk / Tribal Dance"].a[1]
        : ""
    );
    setEve8a3(
      data.data.events["Folk / Tribal Dance"].a[2]
        ? data.data.events["Folk / Tribal Dance"].a[2]
        : ""
    );
    setEve8a4(
      data.data.events["Folk / Tribal Dance"].a[3]
        ? data.data.events["Folk / Tribal Dance"].a[3]
        : ""
    );
    setEve8a5(
      data.data.events["Folk / Tribal Dance"].a[4]
        ? data.data.events["Folk / Tribal Dance"].a[4]
        : ""
    );
    setEve9a1(
      data.data.events["Classical Dance Solo"].a[0]
        ? data.data.events["Classical Dance Solo"].a[0]
        : ""
    );
    setEve9a2(
      data.data.events["Classical Dance Solo"].a[1]
        ? data.data.events["Classical Dance Solo"].a[1]
        : ""
    );
    setEve9a3(
      data.data.events["Classical Dance Solo"].a[2]
        ? data.data.events["Classical Dance Solo"].a[2]
        : ""
    );
    setEve13a1(
      data.data.events["Skit"].a[0] ? data.data.events["Skit"].a[0] : ""
    );
    setEve13a2(
      data.data.events["Skit"].a[1] ? data.data.events["Skit"].a[1] : ""
    );
    setEve13a3(
      data.data.events["Skit"].a[2] ? data.data.events["Skit"].a[2] : ""
    );
    setEve14a1(
      data.data.events["Mime"].a[0] ? data.data.events["Mime"].a[0] : ""
    );
    setEve14a2(
      data.data.events["Mime"].a[1] ? data.data.events["Mime"].a[1] : ""
    );
    setEve16a1(
      data.data.events["One Act Play"].a[0]
        ? data.data.events["One Act Play"].a[0]
        : ""
    );
    setEve16a2(
      data.data.events["One Act Play"].a[1]
        ? data.data.events["One Act Play"].a[1]
        : ""
    );
    setEve16a3(
      data.data.events["One Act Play"].a[2]
        ? data.data.events["One Act Play"].a[2]
        : ""
    );
    setEve16a4(
      data.data.events["One Act Play"].a[3]
        ? data.data.events["One Act Play"].a[3]
        : ""
    );
    setEve16a5(
      data.data.events["One Act Play"].a[4]
        ? data.data.events["One Act Play"].a[4]
        : ""
    );
  }

  useEffect(() => {
    fetch(`${baseurl}/college`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setIsEventsSubmited(
            data.data.isEventsSubmited ? data.data.isEventsSubmited : false
          );
          setDetails(data.data);
          setEve0p1(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].p[0]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .p[0]
              : ""
          );
          setEve1p1(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].p[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].p[0]
              : ""
          );
          setEve2p1(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].p[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].p[0]
              : ""
          );
          setEve3p1(
            data.data.events["Light Vocal Solo (Indian)"].p[0]
              ? data.data.events["Light Vocal Solo (Indian)"].p[0]
              : ""
          );
          setEve4p1(
            data.data.events["Western Vocal Solo"].p[0]
              ? data.data.events["Western Vocal Solo"].p[0]
              : ""
          );
          setEve5p1(
            data.data.events["Group Song (Indian)"].p[0]
              ? data.data.events["Group Song (Indian)"].p[0]
              : ""
          );
          setEve5p2(
            data.data.events["Group Song (Indian)"].p[1]
              ? data.data.events["Group Song (Indian)"].p[1]
              : ""
          );
          setEve5p3(
            data.data.events["Group Song (Indian)"].p[2]
              ? data.data.events["Group Song (Indian)"].p[2]
              : ""
          );
          setEve5p4(
            data.data.events["Group Song (Indian)"].p[3]
              ? data.data.events["Group Song (Indian)"].p[3]
              : ""
          );
          setEve5p5(
            data.data.events["Group Song (Indian)"].p[4]
              ? data.data.events["Group Song (Indian)"].p[4]
              : ""
          );
          setEve5p6(
            data.data.events["Group Song (Indian)"].p[5]
              ? data.data.events["Group Song (Indian)"].p[5]
              : ""
          );
          setEve6p1(
            data.data.events["Group Song (Western)"].p[0]
              ? data.data.events["Group Song (Western)"].p[0]
              : ""
          );
          setEve6p2(
            data.data.events["Group Song (Western)"].p[1]
              ? data.data.events["Group Song (Western)"].p[1]
              : ""
          );
          setEve6p3(
            data.data.events["Group Song (Western)"].p[2]
              ? data.data.events["Group Song (Western)"].p[2]
              : ""
          );
          setEve6p4(
            data.data.events["Group Song (Western)"].p[3]
              ? data.data.events["Group Song (Western)"].p[3]
              : ""
          );
          setEve6p5(
            data.data.events["Group Song (Western)"].p[4]
              ? data.data.events["Group Song (Western)"].p[4]
              : ""
          );
          setEve6p6(
            data.data.events["Group Song (Western)"].p[5]
              ? data.data.events["Group Song (Western)"].p[5]
              : ""
          );
          setEve7p1(
            data.data.events["Folk Orchestra"].p[0]
              ? data.data.events["Folk Orchestra"].p[0]
              : ""
          );
          setEve7p2(
            data.data.events["Folk Orchestra"].p[1]
              ? data.data.events["Folk Orchestra"].p[1]
              : ""
          );
          setEve7p3(
            data.data.events["Folk Orchestra"].p[2]
              ? data.data.events["Folk Orchestra"].p[2]
              : ""
          );
          setEve7p4(
            data.data.events["Folk Orchestra"].p[3]
              ? data.data.events["Folk Orchestra"].p[3]
              : ""
          );
          setEve7p5(
            data.data.events["Folk Orchestra"].p[4]
              ? data.data.events["Folk Orchestra"].p[4]
              : ""
          );
          setEve7p6(
            data.data.events["Folk Orchestra"].p[5]
              ? data.data.events["Folk Orchestra"].p[5]
              : ""
          );
          setEve7p7(
            data.data.events["Folk Orchestra"].p[6]
              ? data.data.events["Folk Orchestra"].p[6]
              : ""
          );
          setEve7p8(
            data.data.events["Folk Orchestra"].p[7]
              ? data.data.events["Folk Orchestra"].p[7]
              : ""
          );
          setEve7p9(
            data.data.events["Folk Orchestra"].p[8]
              ? data.data.events["Folk Orchestra"].p[8]
              : ""
          );
          setEve7p10(
            data.data.events["Folk Orchestra"].p[9]
              ? data.data.events["Folk Orchestra"].p[9]
              : ""
          );
          setEve7p11(
            data.data.events["Folk Orchestra"].p[10]
              ? data.data.events["Folk Orchestra"].p[10]
              : ""
          );
          setEve7p12(
            data.data.events["Folk Orchestra"].p[11]
              ? data.data.events["Folk Orchestra"].p[11]
              : ""
          );
          setEve8p1(
            data.data.events["Folk / Tribal Dance"].p[0]
              ? data.data.events["Folk / Tribal Dance"].p[0]
              : ""
          );
          setEve8p2(
            data.data.events["Folk / Tribal Dance"].p[1]
              ? data.data.events["Folk / Tribal Dance"].p[1]
              : ""
          );
          setEve8p3(
            data.data.events["Folk / Tribal Dance"].p[2]
              ? data.data.events["Folk / Tribal Dance"].p[2]
              : ""
          );
          setEve8p4(
            data.data.events["Folk / Tribal Dance"].p[3]
              ? data.data.events["Folk / Tribal Dance"].p[3]
              : ""
          );
          setEve8p5(
            data.data.events["Folk / Tribal Dance"].p[4]
              ? data.data.events["Folk / Tribal Dance"].p[4]
              : ""
          );
          setEve8p6(
            data.data.events["Folk / Tribal Dance"].p[5]
              ? data.data.events["Folk / Tribal Dance"].p[5]
              : ""
          );
          setEve8p7(
            data.data.events["Folk / Tribal Dance"].p[6]
              ? data.data.events["Folk / Tribal Dance"].p[6]
              : ""
          );
          setEve8p8(
            data.data.events["Folk / Tribal Dance"].p[7]
              ? data.data.events["Folk / Tribal Dance"].p[7]
              : ""
          );
          setEve8p9(
            data.data.events["Folk / Tribal Dance"].p[8]
              ? data.data.events["Folk / Tribal Dance"].p[8]
              : ""
          );
          setEve8p10(
            data.data.events["Folk / Tribal Dance"].p[9]
              ? data.data.events["Folk / Tribal Dance"].p[9]
              : ""
          );
          setEve9p1(
            data.data.events["Classical Dance Solo"].p[0]
              ? data.data.events["Classical Dance Solo"].p[0]
              : ""
          );
          setEve10p1(
            data.data.events["Quiz"].p[0] ? data.data.events["Quiz"].p[0] : ""
          );
          setEve10p2(
            data.data.events["Quiz"].p[1] ? data.data.events["Quiz"].p[1] : ""
          );
          setEve10p3(
            data.data.events["Quiz"].p[2] ? data.data.events["Quiz"].p[2] : ""
          );
          setEve11p1(
            data.data.events["Debate"].p[0]
              ? data.data.events["Debate"].p[0]
              : ""
          );
          setEve11p2(
            data.data.events["Debate"].p[1]
              ? data.data.events["Debate"].p[1]
              : ""
          );
          setEve12p1(
            data.data.events["Elocution"].p[0]
              ? data.data.events["Elocution"].p[0]
              : ""
          );
          setEve13p1(
            data.data.events["Skit"].p[0] ? data.data.events["Skit"].p[0] : ""
          );
          setEve13p2(
            data.data.events["Skit"].p[1] ? data.data.events["Skit"].p[1] : ""
          );
          setEve13p3(
            data.data.events["Skit"].p[2] ? data.data.events["Skit"].p[2] : ""
          );
          setEve13p4(
            data.data.events["Skit"].p[3] ? data.data.events["Skit"].p[3] : ""
          );
          setEve13p5(
            data.data.events["Skit"].p[4] ? data.data.events["Skit"].p[4] : ""
          );
          setEve13p6(
            data.data.events["Skit"].p[5] ? data.data.events["Skit"].p[5] : ""
          );
          setEve14p1(
            data.data.events["Mime"].p[0] ? data.data.events["Mime"].p[0] : ""
          );
          setEve14p2(
            data.data.events["Mime"].p[1] ? data.data.events["Mime"].p[1] : ""
          );
          setEve14p3(
            data.data.events["Mime"].p[2] ? data.data.events["Mime"].p[2] : ""
          );
          setEve14p4(
            data.data.events["Mime"].p[3] ? data.data.events["Mime"].p[3] : ""
          );
          setEve14p5(
            data.data.events["Mime"].p[4] ? data.data.events["Mime"].p[4] : ""
          );
          setEve14p6(
            data.data.events["Mime"].p[5] ? data.data.events["Mime"].p[5] : ""
          );
          setEve15p1(
            data.data.events["Mimicry"].p[0]
              ? data.data.events["Mimicry"].p[0]
              : ""
          );
          setEve16p1(
            data.data.events["One Act Play"].p[0]
              ? data.data.events["One Act Play"].p[0]
              : ""
          );
          setEve16p2(
            data.data.events["One Act Play"].p[1]
              ? data.data.events["One Act Play"].p[1]
              : ""
          );
          setEve16p3(
            data.data.events["One Act Play"].p[2]
              ? data.data.events["One Act Play"].p[2]
              : ""
          );
          setEve16p4(
            data.data.events["One Act Play"].p[3]
              ? data.data.events["One Act Play"].p[3]
              : ""
          );
          setEve16p5(
            data.data.events["One Act Play"].p[4]
              ? data.data.events["One Act Play"].p[4]
              : ""
          );
          setEve16p6(
            data.data.events["One Act Play"].p[5]
              ? data.data.events["One Act Play"].p[5]
              : ""
          );
          setEve16p7(
            data.data.events["One Act Play"].p[6]
              ? data.data.events["One Act Play"].p[6]
              : ""
          );
          setEve16p8(
            data.data.events["One Act Play"].p[7]
              ? data.data.events["One Act Play"].p[7]
              : ""
          );
          setEve16p9(
            data.data.events["One Act Play"].p[8]
              ? data.data.events["One Act Play"].p[8]
              : ""
          );
          setEve17p1(
            data.data.events["Collage"].p[0]
              ? data.data.events["Collage"].p[0]
              : ""
          );
          setEve18p1(
            data.data.events["Rangoli"].p[0]
              ? data.data.events["Rangoli"].p[0]
              : ""
          );
          setEve19p1(
            data.data.events["Cartooning"].p[0]
              ? data.data.events["Cartooning"].p[0]
              : ""
          );
          setEve20p1(
            data.data.events["Installation"].p[0]
              ? data.data.events["Installation"].p[0]
              : ""
          );
          setEve20p2(
            data.data.events["Installation"].p[1]
              ? data.data.events["Installation"].p[1]
              : ""
          );
          setEve20p3(
            data.data.events["Installation"].p[2]
              ? data.data.events["Installation"].p[2]
              : ""
          );
          setEve20p4(
            data.data.events["Installation"].p[3]
              ? data.data.events["Installation"].p[3]
              : ""
          );
          setEve21p1(
            data.data.events["Poster Making"].p[0]
              ? data.data.events["Poster Making"].p[0]
              : ""
          );
          setEve22p1(
            data.data.events["Clay Modelling"].p[0]
              ? data.data.events["Clay Modelling"].p[0]
              : ""
          );
          setEve23p1(
            data.data.events["On Spot Painting"].p[0]
              ? data.data.events["On Spot Painting"].p[0]
              : ""
          );
          setEve24p1(
            data.data.events["Spot Photography"].p[0]
              ? data.data.events["Spot Photography"].p[0]
              : ""
          );
          setEve0a1(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[0]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .a[0]
              : ""
          );
          setEve0a2(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[1]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .a[1]
              : ""
          );
          setEve1a1(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].a[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].a[0]
              : ""
          );
          setEve1a2(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].a[1]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].a[1]
              : ""
          );
          setEve2a1(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].a[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].a[0]
              : ""
          );
          setEve2a2(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].a[1]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].a[1]
              : ""
          );
          setEve3a1(
            data.data.events["Light Vocal Solo (Indian)"].a[0]
              ? data.data.events["Light Vocal Solo (Indian)"].a[0]
              : ""
          );
          setEve3a2(
            data.data.events["Light Vocal Solo (Indian)"].a[1]
              ? data.data.events["Light Vocal Solo (Indian)"].a[1]
              : ""
          );
          setEve4a1(
            data.data.events["Western Vocal Solo"].a[0]
              ? data.data.events["Western Vocal Solo"].a[0]
              : ""
          );
          setEve4a2(
            data.data.events["Western Vocal Solo"].a[1]
              ? data.data.events["Western Vocal Solo"].a[1]
              : ""
          );
          setEve5a1(
            data.data.events["Group Song (Indian)"].a[0]
              ? data.data.events["Group Song (Indian)"].a[0]
              : ""
          );
          setEve5a2(
            data.data.events["Group Song (Indian)"].a[1]
              ? data.data.events["Group Song (Indian)"].a[1]
              : ""
          );
          setEve5a3(
            data.data.events["Group Song (Indian)"].a[2]
              ? data.data.events["Group Song (Indian)"].a[2]
              : ""
          );
          setEve6a1(
            data.data.events["Group Song (Western)"].a[0]
              ? data.data.events["Group Song (Western)"].a[0]
              : ""
          );
          setEve6a2(
            data.data.events["Group Song (Western)"].a[1]
              ? data.data.events["Group Song (Western)"].a[1]
              : ""
          );
          setEve6a3(
            data.data.events["Group Song (Western)"].a[2]
              ? data.data.events["Group Song (Western)"].a[2]
              : ""
          );
          setEve7a1(
            data.data.events["Folk Orchestra"].a[0]
              ? data.data.events["Folk Orchestra"].a[0]
              : ""
          );
          setEve7a2(
            data.data.events["Folk Orchestra"].a[1]
              ? data.data.events["Folk Orchestra"].a[1]
              : ""
          );
          setEve7a3(
            data.data.events["Folk Orchestra"].a[2]
              ? data.data.events["Folk Orchestra"].a[2]
              : ""
          );
          setEve8a1(
            data.data.events["Folk / Tribal Dance"].a[0]
              ? data.data.events["Folk / Tribal Dance"].a[0]
              : ""
          );
          setEve8a2(
            data.data.events["Folk / Tribal Dance"].a[1]
              ? data.data.events["Folk / Tribal Dance"].a[1]
              : ""
          );
          setEve8a3(
            data.data.events["Folk / Tribal Dance"].a[2]
              ? data.data.events["Folk / Tribal Dance"].a[2]
              : ""
          );
          setEve8a4(
            data.data.events["Folk / Tribal Dance"].a[3]
              ? data.data.events["Folk / Tribal Dance"].a[3]
              : ""
          );
          setEve8a5(
            data.data.events["Folk / Tribal Dance"].a[4]
              ? data.data.events["Folk / Tribal Dance"].a[4]
              : ""
          );
          setEve9a1(
            data.data.events["Classical Dance Solo"].a[0]
              ? data.data.events["Classical Dance Solo"].a[0]
              : ""
          );
          setEve9a2(
            data.data.events["Classical Dance Solo"].a[1]
              ? data.data.events["Classical Dance Solo"].a[1]
              : ""
          );
          setEve9a3(
            data.data.events["Classical Dance Solo"].a[2]
              ? data.data.events["Classical Dance Solo"].a[2]
              : ""
          );
          setEve13a1(
            data.data.events["Skit"].a[0] ? data.data.events["Skit"].a[0] : ""
          );
          setEve13a2(
            data.data.events["Skit"].a[1] ? data.data.events["Skit"].a[1] : ""
          );
          setEve13a3(
            data.data.events["Skit"].a[2] ? data.data.events["Skit"].a[2] : ""
          );
          setEve14a1(
            data.data.events["Mime"].a[0] ? data.data.events["Mime"].a[0] : ""
          );
          setEve14a2(
            data.data.events["Mime"].a[1] ? data.data.events["Mime"].a[1] : ""
          );
          setEve16a1(
            data.data.events["One Act Play"].a[0]
              ? data.data.events["One Act Play"].a[0]
              : ""
          );
          setEve16a2(
            data.data.events["One Act Play"].a[1]
              ? data.data.events["One Act Play"].a[1]
              : ""
          );
          setEve16a3(
            data.data.events["One Act Play"].a[2]
              ? data.data.events["One Act Play"].a[2]
              : ""
          );
          setEve16a4(
            data.data.events["One Act Play"].a[3]
              ? data.data.events["One Act Play"].a[3]
              : ""
          );
          setEve16a5(
            data.data.events["One Act Play"].a[4]
              ? data.data.events["One Act Play"].a[4]
              : ""
          );
        }
      });
    fetch(`${baseurl}/student/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.status === 200 ? setStudent(data.data) : setStudent([]);
        fetch(`${baseurl}/non-student/without-docs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((output) => {
            if (output.status === 200) {
              setAccompanists(output.data);
              setCombined(data.data.concat(output.data));
            }
          });
      });
    setAccompanists(student.concat(accompanists));
  }, [token]);
  const saveData = (e) => {
    e.preventDefault();
    var events = {
      Cartooning: {
        p: [eve19p1],
        a: [],
      },
      "Classical Dance Solo": { p: [eve9p1], a: [eve9a1, eve9a2, eve9a3] },
      "Classical Instrumental Solo (Non-Percussion Swara Vadya)": {
        p: [eve2p1],
        a: [eve2a1, eve2a2],
      },
      "Classical Instrumental Solo (Percussion Tala Vadya)": {
        p: [eve1p1],
        a: [eve1a1, eve1a2],
      },
      "Classical Vocal Solo (Hindustani/Carnatic)": {
        p: [eve0p1],
        a: [eve0a1, eve0a2],
      },
      "Clay Modelling": { p: [eve22p1], a: [] },
      Collage: { p: [eve17p1], a: [] },
      Debate: { p: [eve11p1, eve11p2], a: [] },
      Elocution: { p: [eve12p1], a: [] },
      "Folk / Tribal Dance": {
        p: [
          eve8p1,
          eve8p2,
          eve8p3,
          eve8p4,
          eve8p5,
          eve8p6,
          eve8p7,
          eve8p8,
          eve8p9,
          eve8p10,
        ],
        a: [eve8a1, eve8a2, eve8a3, eve8a4, eve8a5],
      },
      "Folk Orchestra": {
        p: [
          eve7p1,
          eve7p2,
          eve7p3,
          eve7p4,
          eve7p5,
          eve7p6,
          eve7p7,
          eve7p8,
          eve7p9,
          eve7p10,
          eve7p11,
          eve7p12,
        ],
        a: [eve7a1, eve7a2, eve7a3],
      },
      "Group Song (Indian)": {
        p: [eve5p1, eve5p2, eve5p3, eve5p4, eve5p5, eve5p6],
        a: [eve5a1, eve5a2, eve5a3],
      },
      "Group Song (Western)": {
        p: [eve6p1, eve6p2, eve6p3, eve6p4, eve6p5, eve6p6],
        a: [eve6a1, eve6a2, eve6a3],
      },
      Installation: { p: [eve20p1, eve20p2, eve20p3, eve20p4], a: [] },
      "Light Vocal Solo (Indian)": { p: [eve3p1], a: [eve3a1, eve3a2] },
      Mime: {
        p: [eve14p1, eve14p2, eve14p3, eve14p4, eve14p5, eve14p6],
        a: [eve14a1, eve14a2],
      },
      Mimicry: { p: [eve15p1], a: [] },
      "On Spot Painting": { p: [eve23p1], a: [] },
      "One Act Play": {
        p: [
          eve16p1,
          eve16p2,
          eve16p3,
          eve16p4,
          eve16p5,
          eve16p6,
          eve16p7,
          eve16p8,
          eve16p9,
        ],
        a: [eve16a1, eve16a2, eve16a3, eve16a4, eve16a5],
      },
      "Poster Making": { p: [eve21p1], a: [] },
      Quiz: { p: [eve10p1, eve10p2, eve10p3], a: [] },
      Rangoli: { p: [eve18p1], a: [] },
      Skit: {
        p: [eve13p1, eve13p2, eve13p3, eve13p4, eve13p5, eve13p6],
        a: [eve13a1, eve13a2, eve13a3],
      },
      "Spot Photography": { p: [eve24p1], a: [] },
      "Western Vocal Solo": { p: [eve4p1], a: [eve4a1, eve4a2] },
    };
    if (!isDataValid()) {
      throw Error("INVALID DATA");
    }
    var apidata = details;
    apidata.events = events;
    console.log(apidata);
    fetch(`${baseurl}/college`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify(apidata),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setIsEventsSubmited(
            data.data.isEventsSubmited ? data.data.isEventsSubmited : false
          );
          setDetails(data.data);
          setEve0p1(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].p[0]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .p[0]
              : ""
          );
          setEve1p1(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].p[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].p[0]
              : ""
          );
          setEve2p1(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].p[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].p[0]
              : ""
          );
          setEve3p1(
            data.data.events["Light Vocal Solo (Indian)"].p[0]
              ? data.data.events["Light Vocal Solo (Indian)"].p[0]
              : ""
          );
          setEve4p1(
            data.data.events["Western Vocal Solo"].p[0]
              ? data.data.events["Western Vocal Solo"].p[0]
              : ""
          );
          setEve5p1(
            data.data.events["Group Song (Indian)"].p[0]
              ? data.data.events["Group Song (Indian)"].p[0]
              : ""
          );
          setEve5p2(
            data.data.events["Group Song (Indian)"].p[1]
              ? data.data.events["Group Song (Indian)"].p[1]
              : ""
          );
          setEve5p3(
            data.data.events["Group Song (Indian)"].p[2]
              ? data.data.events["Group Song (Indian)"].p[2]
              : ""
          );
          setEve5p4(
            data.data.events["Group Song (Indian)"].p[3]
              ? data.data.events["Group Song (Indian)"].p[3]
              : ""
          );
          setEve5p5(
            data.data.events["Group Song (Indian)"].p[4]
              ? data.data.events["Group Song (Indian)"].p[4]
              : ""
          );
          setEve5p6(
            data.data.events["Group Song (Indian)"].p[5]
              ? data.data.events["Group Song (Indian)"].p[5]
              : ""
          );
          setEve6p1(
            data.data.events["Group Song (Western)"].p[0]
              ? data.data.events["Group Song (Western)"].p[0]
              : ""
          );
          setEve6p2(
            data.data.events["Group Song (Western)"].p[1]
              ? data.data.events["Group Song (Western)"].p[1]
              : ""
          );
          setEve6p3(
            data.data.events["Group Song (Western)"].p[2]
              ? data.data.events["Group Song (Western)"].p[2]
              : ""
          );
          setEve6p4(
            data.data.events["Group Song (Western)"].p[3]
              ? data.data.events["Group Song (Western)"].p[3]
              : ""
          );
          setEve6p5(
            data.data.events["Group Song (Western)"].p[4]
              ? data.data.events["Group Song (Western)"].p[4]
              : ""
          );
          setEve6p6(
            data.data.events["Group Song (Western)"].p[5]
              ? data.data.events["Group Song (Western)"].p[5]
              : ""
          );
          setEve7p1(
            data.data.events["Folk Orchestra"].p[0]
              ? data.data.events["Folk Orchestra"].p[0]
              : ""
          );
          setEve7p2(
            data.data.events["Folk Orchestra"].p[1]
              ? data.data.events["Folk Orchestra"].p[1]
              : ""
          );
          setEve7p3(
            data.data.events["Folk Orchestra"].p[2]
              ? data.data.events["Folk Orchestra"].p[2]
              : ""
          );
          setEve7p4(
            data.data.events["Folk Orchestra"].p[3]
              ? data.data.events["Folk Orchestra"].p[3]
              : ""
          );
          setEve7p5(
            data.data.events["Folk Orchestra"].p[4]
              ? data.data.events["Folk Orchestra"].p[4]
              : ""
          );
          setEve7p6(
            data.data.events["Folk Orchestra"].p[5]
              ? data.data.events["Folk Orchestra"].p[5]
              : ""
          );
          setEve7p7(
            data.data.events["Folk Orchestra"].p[6]
              ? data.data.events["Folk Orchestra"].p[6]
              : ""
          );
          setEve7p8(
            data.data.events["Folk Orchestra"].p[7]
              ? data.data.events["Folk Orchestra"].p[7]
              : ""
          );
          setEve7p9(
            data.data.events["Folk Orchestra"].p[8]
              ? data.data.events["Folk Orchestra"].p[8]
              : ""
          );
          setEve7p10(
            data.data.events["Folk Orchestra"].p[9]
              ? data.data.events["Folk Orchestra"].p[9]
              : ""
          );
          setEve7p11(
            data.data.events["Folk Orchestra"].p[10]
              ? data.data.events["Folk Orchestra"].p[10]
              : ""
          );
          setEve7p12(
            data.data.events["Folk Orchestra"].p[11]
              ? data.data.events["Folk Orchestra"].p[11]
              : ""
          );
          setEve8p1(
            data.data.events["Folk / Tribal Dance"].p[0]
              ? data.data.events["Folk / Tribal Dance"].p[0]
              : ""
          );
          setEve8p2(
            data.data.events["Folk / Tribal Dance"].p[1]
              ? data.data.events["Folk / Tribal Dance"].p[1]
              : ""
          );
          setEve8p3(
            data.data.events["Folk / Tribal Dance"].p[2]
              ? data.data.events["Folk / Tribal Dance"].p[2]
              : ""
          );
          setEve8p4(
            data.data.events["Folk / Tribal Dance"].p[3]
              ? data.data.events["Folk / Tribal Dance"].p[3]
              : ""
          );
          setEve8p5(
            data.data.events["Folk / Tribal Dance"].p[4]
              ? data.data.events["Folk / Tribal Dance"].p[4]
              : ""
          );
          setEve8p6(
            data.data.events["Folk / Tribal Dance"].p[5]
              ? data.data.events["Folk / Tribal Dance"].p[5]
              : ""
          );
          setEve8p7(
            data.data.events["Folk / Tribal Dance"].p[6]
              ? data.data.events["Folk / Tribal Dance"].p[6]
              : ""
          );
          setEve8p8(
            data.data.events["Folk / Tribal Dance"].p[7]
              ? data.data.events["Folk / Tribal Dance"].p[7]
              : ""
          );
          setEve8p9(
            data.data.events["Folk / Tribal Dance"].p[8]
              ? data.data.events["Folk / Tribal Dance"].p[8]
              : ""
          );
          setEve8p10(
            data.data.events["Folk / Tribal Dance"].p[9]
              ? data.data.events["Folk / Tribal Dance"].p[9]
              : ""
          );
          setEve9p1(
            data.data.events["Classical Dance Solo"].p[0]
              ? data.data.events["Classical Dance Solo"].p[0]
              : ""
          );
          setEve10p1(
            data.data.events["Quiz"].p[0] ? data.data.events["Quiz"].p[0] : ""
          );
          setEve10p2(
            data.data.events["Quiz"].p[1] ? data.data.events["Quiz"].p[1] : ""
          );
          setEve10p3(
            data.data.events["Quiz"].p[2] ? data.data.events["Quiz"].p[2] : ""
          );
          setEve11p1(
            data.data.events["Debate"].p[0]
              ? data.data.events["Debate"].p[0]
              : ""
          );
          setEve11p2(
            data.data.events["Debate"].p[1]
              ? data.data.events["Debate"].p[1]
              : ""
          );
          setEve12p1(
            data.data.events["Elocution"].p[0]
              ? data.data.events["Elocution"].p[0]
              : ""
          );
          setEve13p1(
            data.data.events["Skit"].p[0] ? data.data.events["Skit"].p[0] : ""
          );
          setEve13p2(
            data.data.events["Skit"].p[1] ? data.data.events["Skit"].p[1] : ""
          );
          setEve13p3(
            data.data.events["Skit"].p[2] ? data.data.events["Skit"].p[2] : ""
          );
          setEve13p4(
            data.data.events["Skit"].p[3] ? data.data.events["Skit"].p[3] : ""
          );
          setEve13p5(
            data.data.events["Skit"].p[4] ? data.data.events["Skit"].p[4] : ""
          );
          setEve13p6(
            data.data.events["Skit"].p[5] ? data.data.events["Skit"].p[5] : ""
          );
          setEve14p1(
            data.data.events["Mime"].p[0] ? data.data.events["Mime"].p[0] : ""
          );
          setEve14p2(
            data.data.events["Mime"].p[1] ? data.data.events["Mime"].p[1] : ""
          );
          setEve14p3(
            data.data.events["Mime"].p[2] ? data.data.events["Mime"].p[2] : ""
          );
          setEve14p4(
            data.data.events["Mime"].p[3] ? data.data.events["Mime"].p[3] : ""
          );
          setEve14p5(
            data.data.events["Mime"].p[4] ? data.data.events["Mime"].p[4] : ""
          );
          setEve14p6(
            data.data.events["Mime"].p[5] ? data.data.events["Mime"].p[5] : ""
          );
          setEve15p1(
            data.data.events["Mimicry"].p[0]
              ? data.data.events["Mimicry"].p[0]
              : ""
          );
          setEve16p1(
            data.data.events["One Act Play"].p[0]
              ? data.data.events["One Act Play"].p[0]
              : ""
          );
          setEve16p2(
            data.data.events["One Act Play"].p[1]
              ? data.data.events["One Act Play"].p[1]
              : ""
          );
          setEve16p3(
            data.data.events["Mimicry"].p[2]
              ? data.data.events["Mimicry"].p[2]
              : ""
          );
          setEve16p4(
            data.data.events["One Act Play"].p[3]
              ? data.data.events["One Act Play"].p[3]
              : ""
          );
          setEve16p5(
            data.data.events["One Act Play"].p[4]
              ? data.data.events["One Act Play"].p[4]
              : ""
          );
          setEve16p6(
            data.data.events["One Act Play"].p[5]
              ? data.data.events["One Act Play"].p[5]
              : ""
          );
          setEve16p7(
            data.data.events["One Act Play"].p[6]
              ? data.data.events["One Act Play"].p[6]
              : ""
          );
          setEve16p8(
            data.data.events["One Act Play"].p[7]
              ? data.data.events["One Act Play"].p[7]
              : ""
          );
          setEve16p9(
            data.data.events["One Act Play"].p[8]
              ? data.data.events["One Act Play"].p[8]
              : ""
          );
          setEve17p1(
            data.data.events["Collage"].p[0]
              ? data.data.events["Collage"].p[0]
              : ""
          );
          setEve18p1(
            data.data.events["Rangoli"].p[0]
              ? data.data.events["Rangoli"].p[0]
              : ""
          );
          setEve19p1(
            data.data.events["Cartooning"].p[0]
              ? data.data.events["Cartooning"].p[0]
              : ""
          );
          setEve20p1(
            data.data.events["Installation"].p[0]
              ? data.data.events["Installation"].p[0]
              : ""
          );
          setEve20p2(
            data.data.events["Installation"].p[1]
              ? data.data.events["Installation"].p[1]
              : ""
          );
          setEve20p3(
            data.data.events["Installation"].p[2]
              ? data.data.events["Installation"].p[2]
              : ""
          );
          setEve20p4(
            data.data.events["Installation"].p[3]
              ? data.data.events["Installation"].p[3]
              : ""
          );
          setEve21p1(
            data.data.events["Poster Making"].p[0]
              ? data.data.events["Poster Making"].p[0]
              : ""
          );
          setEve22p1(
            data.data.events["Clay Modelling"].p[0]
              ? data.data.events["Clay Modelling"].p[0]
              : ""
          );
          setEve23p1(
            data.data.events["On Spot Painting"].p[0]
              ? data.data.events["On Spot Painting"].p[0]
              : ""
          );
          setEve24p1(
            data.data.events["Spot Photography"].p[0]
              ? data.data.events["Spot Photography"].p[0]
              : ""
          );
          setEve0a1(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[0]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .a[0]
              : ""
          );
          setEve0a2(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[1]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .a[1]
              : ""
          );
          setEve1a1(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].a[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].a[0]
              : ""
          );
          setEve1a2(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].a[1]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].a[1]
              : ""
          );
          setEve2a1(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].a[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].a[0]
              : ""
          );
          setEve2a2(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].a[1]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].a[1]
              : ""
          );
          setEve3a1(
            data.data.events["Light Vocal Solo (Indian)"].a[0]
              ? data.data.events["Light Vocal Solo (Indian)"].a[0]
              : ""
          );
          setEve3a2(
            data.data.events["Light Vocal Solo (Indian)"].a[1]
              ? data.data.events["Light Vocal Solo (Indian)"].a[1]
              : ""
          );
          setEve4a1(
            data.data.events["Western Vocal Solo"].a[0]
              ? data.data.events["Western Vocal Solo"].a[0]
              : ""
          );
          setEve4a2(
            data.data.events["Western Vocal Solo"].a[1]
              ? data.data.events["Western Vocal Solo"].a[1]
              : ""
          );
          setEve5a1(
            data.data.events["Group Song (Indian)"].a[0]
              ? data.data.events["Group Song (Indian)"].a[0]
              : ""
          );
          setEve5a2(
            data.data.events["Group Song (Indian)"].a[1]
              ? data.data.events["Group Song (Indian)"].a[1]
              : ""
          );
          setEve5a3(
            data.data.events["Group Song (Indian)"].a[2]
              ? data.data.events["Group Song (Indian)"].a[2]
              : ""
          );
          setEve6a1(
            data.data.events["Group Song (Western)"].a[0]
              ? data.data.events["Group Song (Western)"].a[0]
              : ""
          );
          setEve6a2(
            data.data.events["Group Song (Western)"].a[1]
              ? data.data.events["Group Song (Western)"].a[1]
              : ""
          );
          setEve6a3(
            data.data.events["Group Song (Western)"].a[2]
              ? data.data.events["Group Song (Western)"].a[2]
              : ""
          );
          setEve7a1(
            data.data.events["Folk Orchestra"].a[0]
              ? data.data.events["Folk Orchestra"].a[0]
              : ""
          );
          setEve7a2(
            data.data.events["Folk Orchestra"].a[1]
              ? data.data.events["Folk Orchestra"].a[1]
              : ""
          );
          setEve7a3(
            data.data.events["Folk Orchestra"].a[2]
              ? data.data.events["Folk Orchestra"].a[2]
              : ""
          );
          setEve8a1(
            data.data.events["Folk / Tribal Dance"].a[0]
              ? data.data.events["Folk / Tribal Dance"].a[0]
              : ""
          );
          setEve8a2(
            data.data.events["Folk / Tribal Dance"].a[1]
              ? data.data.events["Folk / Tribal Dance"].a[1]
              : ""
          );
          setEve8a3(
            data.data.events["Folk / Tribal Dance"].a[2]
              ? data.data.events["Folk / Tribal Dance"].a[2]
              : ""
          );
          setEve8a4(
            data.data.events["Folk / Tribal Dance"].a[3]
              ? data.data.events["Folk / Tribal Dance"].a[3]
              : ""
          );
          setEve8a5(
            data.data.events["Folk / Tribal Dance"].a[4]
              ? data.data.events["Folk / Tribal Dance"].a[4]
              : ""
          );
          setEve9a1(
            data.data.events["Classical Dance Solo"].a[0]
              ? data.data.events["Classical Dance Solo"].a[0]
              : ""
          );
          setEve9a2(
            data.data.events["Classical Dance Solo"].a[1]
              ? data.data.events["Classical Dance Solo"].a[1]
              : ""
          );
          setEve9a3(
            data.data.events["Classical Dance Solo"].a[2]
              ? data.data.events["Classical Dance Solo"].a[2]
              : ""
          );
          setEve13a1(
            data.data.events["Skit"].a[0] ? data.data.events["Skit"].a[0] : ""
          );
          setEve13a2(
            data.data.events["Skit"].a[1] ? data.data.events["Skit"].a[1] : ""
          );
          setEve13a3(
            data.data.events["Skit"].a[2] ? data.data.events["Skit"].a[2] : ""
          );
          setEve14a1(
            data.data.events["Mime"].a[0] ? data.data.events["Mime"].a[0] : ""
          );
          setEve14a2(
            data.data.events["Mime"].a[1] ? data.data.events["Mime"].a[1] : ""
          );
          setEve16a1(
            data.data.events["One Act Play"].a[0]
              ? data.data.events["One Act Play"].a[0]
              : ""
          );
          setEve16a2(
            data.data.events["One Act Play"].a[1]
              ? data.data.events["One Act Play"].a[1]
              : ""
          );
          setEve16a3(
            data.data.events["One Act Play"].a[2]
              ? data.data.events["One Act Play"].a[2]
              : ""
          );
          setEve16a4(
            data.data.events["One Act Play"].a[3]
              ? data.data.events["One Act Play"].a[3]
              : ""
          );
          setEve16a5(
            data.data.events["One Act Play"].a[4]
              ? data.data.events["One Act Play"].a[4]
              : ""
          );
        }
      });
  };
  const submitData = (e) => {
    e.preventDefault();
    var events = {
      Cartooning: {
        p: [eve19p1],
        a: [],
      },
      "Classical Dance Solo": { p: [eve9p1], a: [eve9a1, eve9a2, eve9a3] },
      "Classical Instrumental Solo (Non-Percussion Swara Vadya)": {
        p: [eve2p1],
        a: [eve2a1, eve2a2],
      },
      "Classical Instrumental Solo (Percussion Tala Vadya)": {
        p: [eve1p1],
        a: [eve1a1, eve1a2],
      },
      "Classical Vocal Solo (Hindustani/Carnatic)": {
        p: [eve0p1],
        a: [eve0a1, eve0a2],
      },
      "Clay Modelling": { p: [eve22p1], a: [] },
      Collage: { p: [eve17p1], a: [] },
      Debate: { p: [eve11p1, eve11p2], a: [] },
      Elocution: { p: [eve12p1], a: [] },
      "Folk / Tribal Dance": {
        p: [
          eve8p1,
          eve8p2,
          eve8p3,
          eve8p4,
          eve8p5,
          eve8p6,
          eve8p7,
          eve8p8,
          eve8p9,
          eve8p10,
        ],
        a: [eve8a1, eve8a2, eve8a3, eve8a4, eve8a5],
      },
      "Folk Orchestra": {
        p: [
          eve7p1,
          eve7p2,
          eve7p3,
          eve7p4,
          eve7p5,
          eve7p6,
          eve7p7,
          eve7p8,
          eve7p9,
          eve7p10,
          eve7p11,
          eve7p12,
        ],
        a: [eve7a1, eve7a2, eve7a3],
      },
      "Group Song (Indian)": {
        p: [eve5p1, eve5p2, eve5p3, eve5p4, eve5p5, eve5p6],
        a: [eve5a1, eve5a2, eve5a3],
      },
      "Group Song (Western)": {
        p: [eve6p1, eve6p2, eve6p3, eve6p4, eve6p6, eve6p5],
        a: [eve6a1, eve6a2, eve6a3],
      },
      Installation: { p: [eve20p1, eve20p2, eve20p3, eve20p4], a: [] },
      "Light Vocal Solo (Indian)": { p: [eve3p1], a: [eve3a1, eve3a2] },
      Mime: {
        p: [eve14p1, eve14p2, eve14p3, eve14p4, eve14p5, eve14p6],
        a: [eve14a1, eve14a2],
      },
      Mimicry: { p: [eve15p1], a: [] },
      "On Spot Painting": { p: [eve23p1], a: [] },
      "One Act Play": {
        p: [
          eve16p1,
          eve16p2,
          eve16p3,
          eve16p4,
          eve16p5,
          eve16p6,
          eve16p7,
          eve16p8,
          eve16p9,
        ],
        a: [eve16a1, eve16a2, eve16a3, eve16a4, eve16a5],
      },
      "Poster Making": { p: [eve21p1], a: [] },
      Quiz: { p: [eve10p1, eve10p2, eve10p3], a: [] },
      Rangoli: { p: [eve18p1], a: [] },
      Skit: {
        p: [eve13p1, eve13p2, eve13p3, eve13p4, eve13p5, eve13p6],
        a: [eve13a1, eve13a2, eve13a3],
      },
      "Spot Photography": { p: [eve24p1], a: [] },
      "Western Vocal Solo": { p: [eve4p1], a: [eve4a1, eve4a2] },
    };
    if (!isDataValid()) {
      throw Error("INVALID DATA");
    }
    var apidata = details;
    apidata.events = events;
    apidata.isEventsSubmited = true;
    console.log(apidata);
    fetch(`${baseurl}/college`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify(apidata),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setIsEventsSubmited(
            data.data.isEventsSubmited ? data.data.isEventsSubmited : false
          );
          setDetails(data.data);
          setEve0p1(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].p[0]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .p[0]
              : ""
          );
          setEve1p1(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].p[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].p[0]
              : ""
          );
          setEve2p1(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].p[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].p[0]
              : ""
          );
          setEve3p1(
            data.data.events["Light Vocal Solo (Indian)"].p[0]
              ? data.data.events["Light Vocal Solo (Indian)"].p[0]
              : ""
          );
          setEve4p1(
            data.data.events["Western Vocal Solo"].p[0]
              ? data.data.events["Western Vocal Solo"].p[0]
              : ""
          );
          setEve5p1(
            data.data.events["Group Song (Indian)"].p[0]
              ? data.data.events["Group Song (Indian)"].p[0]
              : ""
          );
          setEve5p2(
            data.data.events["Group Song (Indian)"].p[1]
              ? data.data.events["Group Song (Indian)"].p[1]
              : ""
          );
          setEve5p3(
            data.data.events["Group Song (Indian)"].p[2]
              ? data.data.events["Group Song (Indian)"].p[2]
              : ""
          );
          setEve5p4(
            data.data.events["Group Song (Indian)"].p[3]
              ? data.data.events["Group Song (Indian)"].p[3]
              : ""
          );
          setEve5p5(
            data.data.events["Group Song (Indian)"].p[4]
              ? data.data.events["Group Song (Indian)"].p[4]
              : ""
          );
          setEve5p6(
            data.data.events["Group Song (Indian)"].p[5]
              ? data.data.events["Group Song (Indian)"].p[5]
              : ""
          );
          setEve6p1(
            data.data.events["Group Song (Western)"].p[0]
              ? data.data.events["Group Song (Western)"].p[0]
              : ""
          );
          setEve6p2(
            data.data.events["Group Song (Western)"].p[1]
              ? data.data.events["Group Song (Western)"].p[1]
              : ""
          );
          setEve6p3(
            data.data.events["Group Song (Western)"].p[2]
              ? data.data.events["Group Song (Western)"].p[2]
              : ""
          );
          setEve6p4(
            data.data.events["Group Song (Western)"].p[3]
              ? data.data.events["Group Song (Western)"].p[3]
              : ""
          );
          setEve6p5(
            data.data.events["Group Song (Western)"].p[4]
              ? data.data.events["Group Song (Western)"].p[4]
              : ""
          );
          setEve6p6(
            data.data.events["Group Song (Western)"].p[5]
              ? data.data.events["Group Song (Western)"].p[5]
              : ""
          );
          setEve7p1(
            data.data.events["Folk Orchestra"].p[0]
              ? data.data.events["Folk Orchestra"].p[0]
              : ""
          );
          setEve7p2(
            data.data.events["Folk Orchestra"].p[1]
              ? data.data.events["Folk Orchestra"].p[1]
              : ""
          );
          setEve7p3(
            data.data.events["Folk Orchestra"].p[2]
              ? data.data.events["Folk Orchestra"].p[2]
              : ""
          );
          setEve7p4(
            data.data.events["Folk Orchestra"].p[3]
              ? data.data.events["Folk Orchestra"].p[3]
              : ""
          );
          setEve7p5(
            data.data.events["Folk Orchestra"].p[4]
              ? data.data.events["Folk Orchestra"].p[4]
              : ""
          );
          setEve7p6(
            data.data.events["Folk Orchestra"].p[5]
              ? data.data.events["Folk Orchestra"].p[5]
              : ""
          );
          setEve7p7(
            data.data.events["Folk Orchestra"].p[6]
              ? data.data.events["Folk Orchestra"].p[6]
              : ""
          );
          setEve7p8(
            data.data.events["Folk Orchestra"].p[7]
              ? data.data.events["Folk Orchestra"].p[7]
              : ""
          );
          setEve7p9(
            data.data.events["Folk Orchestra"].p[8]
              ? data.data.events["Folk Orchestra"].p[8]
              : ""
          );
          setEve7p10(
            data.data.events["Folk Orchestra"].p[9]
              ? data.data.events["Folk Orchestra"].p[9]
              : ""
          );
          setEve7p11(
            data.data.events["Folk Orchestra"].p[10]
              ? data.data.events["Folk Orchestra"].p[10]
              : ""
          );
          setEve7p12(
            data.data.events["Folk Orchestra"].p[11]
              ? data.data.events["Folk Orchestra"].p[11]
              : ""
          );
          setEve8p1(
            data.data.events["Folk / Tribal Dance"].p[0]
              ? data.data.events["Folk / Tribal Dance"].p[0]
              : ""
          );
          setEve8p2(
            data.data.events["Folk / Tribal Dance"].p[1]
              ? data.data.events["Folk / Tribal Dance"].p[1]
              : ""
          );
          setEve8p3(
            data.data.events["Folk / Tribal Dance"].p[2]
              ? data.data.events["Folk / Tribal Dance"].p[2]
              : ""
          );
          setEve8p4(
            data.data.events["Folk / Tribal Dance"].p[3]
              ? data.data.events["Folk / Tribal Dance"].p[3]
              : ""
          );
          setEve8p5(
            data.data.events["Folk / Tribal Dance"].p[4]
              ? data.data.events["Folk / Tribal Dance"].p[4]
              : ""
          );
          setEve8p6(
            data.data.events["Folk / Tribal Dance"].p[5]
              ? data.data.events["Folk / Tribal Dance"].p[5]
              : ""
          );
          setEve8p7(
            data.data.events["Folk / Tribal Dance"].p[6]
              ? data.data.events["Folk / Tribal Dance"].p[6]
              : ""
          );
          setEve8p8(
            data.data.events["Folk / Tribal Dance"].p[7]
              ? data.data.events["Folk / Tribal Dance"].p[7]
              : ""
          );
          setEve8p9(
            data.data.events["Folk / Tribal Dance"].p[8]
              ? data.data.events["Folk / Tribal Dance"].p[8]
              : ""
          );
          setEve8p10(
            data.data.events["Folk / Tribal Dance"].p[9]
              ? data.data.events["Folk / Tribal Dance"].p[9]
              : ""
          );
          setEve9p1(
            data.data.events["Classical Dance Solo"].p[0]
              ? data.data.events["Classical Dance Solo"].p[0]
              : ""
          );
          setEve10p1(
            data.data.events["Quiz"].p[0] ? data.data.events["Quiz"].p[0] : ""
          );
          setEve10p2(
            data.data.events["Quiz"].p[1] ? data.data.events["Quiz"].p[1] : ""
          );
          setEve10p3(
            data.data.events["Quiz"].p[2] ? data.data.events["Quiz"].p[2] : ""
          );
          setEve11p1(
            data.data.events["Debate"].p[0]
              ? data.data.events["Debate"].p[0]
              : ""
          );
          setEve11p2(
            data.data.events["Debate"].p[1]
              ? data.data.events["Debate"].p[1]
              : ""
          );
          setEve12p1(
            data.data.events["Elocution"].p[0]
              ? data.data.events["Elocution"].p[0]
              : ""
          );
          setEve13p1(
            data.data.events["Skit"].p[0] ? data.data.events["Skit"].p[0] : ""
          );
          setEve13p2(
            data.data.events["Skit"].p[1] ? data.data.events["Skit"].p[1] : ""
          );
          setEve13p3(
            data.data.events["Skit"].p[2] ? data.data.events["Skit"].p[2] : ""
          );
          setEve13p4(
            data.data.events["Skit"].p[3] ? data.data.events["Skit"].p[3] : ""
          );
          setEve13p5(
            data.data.events["Skit"].p[4] ? data.data.events["Skit"].p[4] : ""
          );
          setEve13p6(
            data.data.events["Skit"].p[5] ? data.data.events["Skit"].p[5] : ""
          );
          setEve14p1(
            data.data.events["Mime"].p[0] ? data.data.events["Mime"].p[0] : ""
          );
          setEve14p2(
            data.data.events["Mime"].p[1] ? data.data.events["Mime"].p[1] : ""
          );
          setEve14p3(
            data.data.events["Mime"].p[2] ? data.data.events["Mime"].p[2] : ""
          );
          setEve14p4(
            data.data.events["Mime"].p[3] ? data.data.events["Mime"].p[3] : ""
          );
          setEve14p5(
            data.data.events["Mime"].p[4] ? data.data.events["Mime"].p[4] : ""
          );
          setEve14p6(
            data.data.events["Mime"].p[5] ? data.data.events["Mime"].p[5] : ""
          );
          setEve15p1(
            data.data.events["Mimicry"].p[0]
              ? data.data.events["Mimicry"].p[0]
              : ""
          );
          setEve16p1(
            data.data.events["One Act Play"].p[0]
              ? data.data.events["One Act Play"].p[0]
              : ""
          );
          setEve16p2(
            data.data.events["One Act Play"].p[1]
              ? data.data.events["One Act Play"].p[1]
              : ""
          );
          setEve16p3(
            data.data.events["Mimicry"].p[2]
              ? data.data.events["Mimicry"].p[2]
              : ""
          );
          setEve16p4(
            data.data.events["One Act Play"].p[3]
              ? data.data.events["One Act Play"].p[3]
              : ""
          );
          setEve16p5(
            data.data.events["One Act Play"].p[4]
              ? data.data.events["One Act Play"].p[4]
              : ""
          );
          setEve16p6(
            data.data.events["One Act Play"].p[5]
              ? data.data.events["One Act Play"].p[5]
              : ""
          );
          setEve16p7(
            data.data.events["One Act Play"].p[6]
              ? data.data.events["One Act Play"].p[6]
              : ""
          );
          setEve16p8(
            data.data.events["One Act Play"].p[7]
              ? data.data.events["One Act Play"].p[7]
              : ""
          );
          setEve16p9(
            data.data.events["One Act Play"].p[8]
              ? data.data.events["One Act Play"].p[8]
              : ""
          );
          setEve17p1(
            data.data.events["Collage"].p[0]
              ? data.data.events["Collage"].p[0]
              : ""
          );
          setEve18p1(
            data.data.events["Rangoli"].p[0]
              ? data.data.events["Rangoli"].p[0]
              : ""
          );
          setEve19p1(
            data.data.events["Cartooning"].p[0]
              ? data.data.events["Cartooning"].p[0]
              : ""
          );
          setEve20p1(
            data.data.events["Installation"].p[0]
              ? data.data.events["Installation"].p[0]
              : ""
          );
          setEve20p2(
            data.data.events["Installation"].p[1]
              ? data.data.events["Installation"].p[1]
              : ""
          );
          setEve20p3(
            data.data.events["Installation"].p[2]
              ? data.data.events["Installation"].p[2]
              : ""
          );
          setEve20p4(
            data.data.events["Installation"].p[3]
              ? data.data.events["Installation"].p[3]
              : ""
          );
          setEve21p1(
            data.data.events["Poster Making"].p[0]
              ? data.data.events["Poster Making"].p[0]
              : ""
          );
          setEve22p1(
            data.data.events["Clay Modelling"].p[0]
              ? data.data.events["Clay Modelling"].p[0]
              : ""
          );
          setEve23p1(
            data.data.events["On Spot Painting"].p[0]
              ? data.data.events["On Spot Painting"].p[0]
              : ""
          );
          setEve24p1(
            data.data.events["Spot Photography"].p[0]
              ? data.data.events["Spot Photography"].p[0]
              : ""
          );
          setEve0a1(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[0]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .a[0]
              : ""
          );
          setEve0a2(
            data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"].a[1]
              ? data.data.events["Classical Vocal Solo (Hindustani/Carnatic)"]
                  .a[1]
              : ""
          );
          setEve1a1(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].a[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].a[0]
              : ""
          );
          setEve1a2(
            data.data.events[
              "Classical Instrumental Solo (Percussion Tala Vadya)"
            ].a[1]
              ? data.data.events[
                  "Classical Instrumental Solo (Percussion Tala Vadya)"
                ].a[1]
              : ""
          );
          setEve2a1(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].a[0]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].a[0]
              : ""
          );
          setEve2a2(
            data.data.events[
              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
            ].a[1]
              ? data.data.events[
                  "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                ].a[1]
              : ""
          );
          setEve3a1(
            data.data.events["Light Vocal Solo (Indian)"].a[0]
              ? data.data.events["Light Vocal Solo (Indian)"].a[0]
              : ""
          );
          setEve3a2(
            data.data.events["Light Vocal Solo (Indian)"].a[1]
              ? data.data.events["Light Vocal Solo (Indian)"].a[1]
              : ""
          );
          setEve4a1(
            data.data.events["Western Vocal Solo"].a[0]
              ? data.data.events["Western Vocal Solo"].a[0]
              : ""
          );
          setEve4a2(
            data.data.events["Western Vocal Solo"].a[1]
              ? data.data.events["Western Vocal Solo"].a[1]
              : ""
          );
          setEve5a1(
            data.data.events["Group Song (Indian)"].a[0]
              ? data.data.events["Group Song (Indian)"].a[0]
              : ""
          );
          setEve5a2(
            data.data.events["Group Song (Indian)"].a[1]
              ? data.data.events["Group Song (Indian)"].a[1]
              : ""
          );
          setEve5a3(
            data.data.events["Group Song (Indian)"].a[2]
              ? data.data.events["Group Song (Indian)"].a[2]
              : ""
          );
          setEve6a1(
            data.data.events["Group Song (Western)"].a[0]
              ? data.data.events["Group Song (Western)"].a[0]
              : ""
          );
          setEve6a2(
            data.data.events["Group Song (Western)"].a[1]
              ? data.data.events["Group Song (Western)"].a[1]
              : ""
          );
          setEve6a3(
            data.data.events["Group Song (Western)"].a[2]
              ? data.data.events["Group Song (Western)"].a[2]
              : ""
          );
          setEve7a1(
            data.data.events["Folk Orchestra"].a[0]
              ? data.data.events["Folk Orchestra"].a[0]
              : ""
          );
          setEve7a2(
            data.data.events["Folk Orchestra"].a[1]
              ? data.data.events["Folk Orchestra"].a[1]
              : ""
          );
          setEve7a3(
            data.data.events["Folk Orchestra"].a[2]
              ? data.data.events["Folk Orchestra"].a[2]
              : ""
          );
          setEve8a1(
            data.data.events["Folk / Tribal Dance"].a[0]
              ? data.data.events["Folk / Tribal Dance"].a[0]
              : ""
          );
          setEve8a2(
            data.data.events["Folk / Tribal Dance"].a[1]
              ? data.data.events["Folk / Tribal Dance"].a[1]
              : ""
          );
          setEve8a3(
            data.data.events["Folk / Tribal Dance"].a[2]
              ? data.data.events["Folk / Tribal Dance"].a[2]
              : ""
          );
          setEve8a4(
            data.data.events["Folk / Tribal Dance"].a[3]
              ? data.data.events["Folk / Tribal Dance"].a[3]
              : ""
          );
          setEve8a5(
            data.data.events["Folk / Tribal Dance"].a[4]
              ? data.data.events["Folk / Tribal Dance"].a[4]
              : ""
          );
          setEve9a1(
            data.data.events["Classical Dance Solo"].a[0]
              ? data.data.events["Classical Dance Solo"].a[0]
              : ""
          );
          setEve9a2(
            data.data.events["Classical Dance Solo"].a[1]
              ? data.data.events["Classical Dance Solo"].a[1]
              : ""
          );
          setEve9a3(
            data.data.events["Classical Dance Solo"].a[2]
              ? data.data.events["Classical Dance Solo"].a[2]
              : ""
          );
          setEve13a1(
            data.data.events["Skit"].a[0] ? data.data.events["Skit"].a[0] : ""
          );
          setEve13a2(
            data.data.events["Skit"].a[1] ? data.data.events["Skit"].a[1] : ""
          );
          setEve13a3(
            data.data.events["Skit"].a[2] ? data.data.events["Skit"].a[2] : ""
          );
          setEve14a1(
            data.data.events["Mime"].a[0] ? data.data.events["Mime"].a[0] : ""
          );
          setEve14a2(
            data.data.events["Mime"].a[1] ? data.data.events["Mime"].a[1] : ""
          );
          setEve16a1(
            data.data.events["One Act Play"].a[0]
              ? data.data.events["One Act Play"].a[0]
              : ""
          );
          setEve16a2(
            data.data.events["One Act Play"].a[1]
              ? data.data.events["One Act Play"].a[1]
              : ""
          );
          setEve16a3(
            data.data.events["One Act Play"].a[2]
              ? data.data.events["One Act Play"].a[2]
              : ""
          );
          setEve16a4(
            data.data.events["One Act Play"].a[3]
              ? data.data.events["One Act Play"].a[3]
              : ""
          );
          setEve16a5(
            data.data.events["One Act Play"].a[4]
              ? data.data.events["One Act Play"].a[4]
              : ""
          );
        }
      });
  };
  if (token) {
    if (userInfo.role === "admin") {
      return (
        <>
          <AdminNavBar />
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-md-10">
                <h2>Assign Events</h2>
                <h4>
                  <strong>
                    The registrations have ended and all details are frozen.
                  </strong>
                </h4>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Sl. No.</th>
                      <th scope="col">Event Name</th>
                      <th scope="col">Participants</th>
                      <th scope="col">Accompanists</th>
                      <th scope="col">Show Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td className="text-start">
                        {"Classical Vocal Solo (Hindustani/Carnatic)"}
                      </td>
                      <td>
                        {getCount(eve0p1)}/
                        {
                          eventinfo[
                            "Classical Vocal Solo (Hindustani/Carnatic)"
                          ].p
                        }
                      </td>
                      <td>
                        {getCount(eve0a1, eve0a2)}/
                        {
                          eventinfo[
                            "Classical Vocal Solo (Hindustani/Carnatic)"
                          ].a
                        }
                      </td>

                      {/* <td>
                        {eventInfo["Classical Vocal Solo (Hindustani/Carnatic)"]
                          .status
                          ? eventInfo[
                              "Classical Vocal Solo (Hindustani/Carnatic)"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Classical Vocal Solo (Hindustani/Carnatic)"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Classical Vocal Solo (Hindustani/Carnatic)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event0"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td className="text-start">
                        {"Classical Instrumental Solo (Percussion Tala Vadya)"}
                      </td>
                      <td>
                        {getCount(eve1p1)}/
                        {
                          eventinfo[
                            "Classical Instrumental Solo (Percussion Tala Vadya)"
                          ].p
                        }
                      </td>
                      <td>
                        {getCount(eve1a1, eve1a2)}/
                        {
                          eventinfo[
                            "Classical Instrumental Solo (Percussion Tala Vadya)"
                          ].a
                        }
                      </td>

                      {/* <td>
                        {eventInfo["Classical Instrumental Solo (Percussion Tala Vadya)"]
                          .status
                          ? eventInfo[
                              "Classical Instrumental Solo (Percussion Tala Vadya)"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Classical Instrumental Solo (Percussion Tala Vadya)"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Classical Instrumental Solo (Percussion Tala Vadya)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event1"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td className="text-start">
                        {
                          "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                        }
                      </td>
                      <td>
                        {getCount(eve2p1)}/
                        {
                          eventinfo[
                            "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                          ].p
                        }
                      </td>
                      <td>
                        {getCount(eve2a1, eve2a2)}/
                        {
                          eventinfo[
                            "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                          ].a
                        }
                      </td>

                      {/* <td>
                        {eventInfo["Classical Instrumental Solo (Non-Percussion Swara Vadya)"]
                          .status
                          ? eventInfo[
                              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Classical Instrumental Solo (Non-Percussion Swara Vadya)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event2"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td className="text-start">
                        {"Light Vocal Solo (Indian)"}
                      </td>
                      <td>
                        {getCount(eve3p1)}/
                        {eventinfo["Light Vocal Solo (Indian)"].p}
                      </td>
                      <td>
                        {getCount(eve3a1, eve3a2)}/
                        {eventinfo["Light Vocal Solo (Indian)"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Light Vocal Solo (Indian)"]
                          .status
                          ? eventInfo[
                              "Light Vocal Solo (Indian)"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Light Vocal Solo (Indian)"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Light Vocal Solo (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event3"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td className="text-start">{"Western Vocal Solo"}</td>
                      <td>
                        {getCount(eve4p1)}/{eventinfo["Western Vocal Solo"].p}
                      </td>
                      <td>
                        {getCount(eve4a1, eve4a2)}/
                        {eventinfo["Western Vocal Solo"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Western Vocal Solo"]
                          .status
                          ? eventInfo[
                              "Western Vocal Solo"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Western Vocal Solo"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Western Vocal Solo"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event4"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td className="text-start">{"Group Song (Indian)"}</td>
                      <td>
                        {getCount(
                          eve5p1,
                          eve5p2,
                          eve5p3,
                          eve5p4,
                          eve5p5,
                          eve5p6
                        )}
                        /{eventinfo["Group Song (Indian)"].p}
                      </td>
                      <td>
                        {getCount(eve5a1, eve5a2, eve5a3)}/
                        {eventinfo["Group Song (Indian)"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Group Song (Indian)"]
                          .status
                          ? eventInfo[
                              "Group Song (Indian)"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event5"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td className="text-start">{"Group Song (Western)"}</td>
                      <td>
                        {getCount(
                          eve6p1,
                          eve6p2,
                          eve6p3,
                          eve6p4,
                          eve6p5,
                          eve6p6
                        )}
                        /{eventinfo["Group Song (Western)"].p}
                      </td>
                      <td>
                        {getCount(eve6a1, eve6a2, eve6a3)}/
                        {eventinfo["Group Song (Western)"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Group Song (Western)"]
                          .status
                          ? eventInfo[
                              "Group Song (Western)"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Group Song (Western)"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Western)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event6"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">8</th>
                      <td className="text-start">{"Folk Orchestra"}</td>
                      <td>
                        {getCount(
                          eve7p1,
                          eve7p2,
                          eve7p3,
                          eve7p4,
                          eve7p5,
                          eve7p6,
                          eve7p7,
                          eve7p8,
                          eve7p9,
                          eve7p10,
                          eve7p11,
                          eve7p12
                        )}
                        /{eventinfo["Folk Orchestra"].p}
                      </td>
                      <td>
                        {getCount(eve7a1, eve7a2, eve7a3)}/
                        {eventinfo["Folk Orchestra"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Folk Orchestra"]
                          .status
                          ? eventInfo[
                              "Folk Orchestra"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Folk Orchestra"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Folk Orchestra"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event7"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">9</th>
                      <td className="text-start">{"Folk / Tribal Dance"}</td>
                      <td>
                        {getCount(
                          eve8p1,
                          eve8p2,
                          eve8p3,
                          eve8p4,
                          eve8p5,
                          eve8p6,
                          eve8p7,
                          eve8p8,
                          eve8p9,
                          eve8p10
                        )}
                        /{eventinfo["Folk / Tribal Dance"].p}
                      </td>
                      <td>
                        {getCount(eve8a1, eve8a2, eve8a3)}/
                        {eventinfo["Folk / Tribal Dance"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Folk / Tribal Dance"]
                          .status
                          ? eventInfo[
                              "Folk / Tribal Dance"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Folk / Tribal Dance"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event8"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">10</th>
                      <td className="text-start">{"Classical Dance Solo"}</td>
                      <td>
                        {getCount(eve9p1)}/{eventinfo["Classical Dance Solo"].p}
                      </td>
                      <td>
                        {getCount(eve9a1)}/{eventinfo["Classical Dance Solo"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Classical Dance Solo"]
                          .status
                          ? eventInfo[
                              "Classical Dance Solo"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Classical Dance Solo"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event9"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">11</th>
                      <td className="text-start">{"Quiz"}</td>
                      <td>
                        {getCount(eve10p1, eve10p2, eve10p3)}/
                        {eventinfo["Quiz"].p}
                      </td>
                      <td>{eventinfo["Quiz"].a}</td>

                      {/* <td>
                        {eventInfo["Quiz"]
                          .status
                          ? eventInfo[
                              "Quiz"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Quiz"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event10"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">12</th>
                      <td className="text-start">{"Debate"}</td>
                      <td>
                        {getCount(eve11p1, eve11p2)}/{eventinfo["Debate"].p}
                      </td>
                      <td>{eventinfo["Debate"].a}</td>

                      {/* <td>
                        {eventInfo["Debate"]
                          .status
                          ? eventInfo[
                              "Debate"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Debate"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event11"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">13</th>
                      <td className="text-start">{"Elocution"}</td>
                      <td>
                        {getCount(eve12p1)}/{eventinfo["Elocution"].p}
                      </td>
                      <td>{eventinfo["Elocution"].a}</td>

                      {/* <td>
                        {eventInfo["Elocution"]
                          .status
                          ? eventInfo[
                              "Elocution"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Elocution"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event12"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">14</th>
                      <td className="text-start">{"Skit"}</td>
                      <td>
                        {getCount(
                          eve13p1,
                          eve13p2,
                          eve13p3,
                          eve13p4,
                          eve13p5,
                          eve13p6
                        )}
                        /{eventinfo["Skit"].p}
                      </td>
                      <td>
                        {getCount(eve13a1, eve13a2, eve13a3)}/
                        {eventinfo["Skit"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Skit"]
                          .status
                          ? eventInfo[
                              "Skit"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Skit"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event13"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">15</th>
                      <td className="text-start">{"Mime"}</td>
                      <td>
                        {getCount(
                          eve14p1,
                          eve14p2,
                          eve14p3,
                          eve14p4,
                          eve14p5,
                          eve14p6
                        )}
                        /{eventinfo["Mime"].p}
                      </td>
                      <td>
                        {getCount(eve14a1, eve14a2)}/{eventinfo["Mime"].a}
                      </td>

                      {/* <td>
                        {eventInfo["Mime"]
                          .status
                          ? eventInfo[
                              "Mime"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Mime"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event14"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">16</th>
                      <td className="text-start">{"Mimicry"}</td>
                      <td>
                        {getCount(eve15p1)}/{eventinfo["Mimicry"].p}
                      </td>
                      <td>{eventinfo["Mimicry"].a}</td>

                      {/* <td>
                        {eventInfo["Mimicry"]
                          .status
                          ? eventInfo[
                              "Mimicry"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Mimicry"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event15"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">17</th>
                      <td className="text-start">{"One Act Play"}</td>
                      <td>
                        {getCount(
                          eve16p1,
                          eve16p2,
                          eve16p3,
                          eve16p4,
                          eve16p5,
                          eve16p6,
                          eve16p7,
                          eve16p8,
                          eve16p9
                        )}
                        /{eventinfo["One Act Play"].p}
                      </td>
                      <td>
                        {getCount(eve16a1, eve16a2, eve16a3, eve16a4, eve16a5)}/
                        {eventinfo["One Act Play"].a}
                      </td>

                      {/* <td>
                        {eventInfo["One Act Play"]
                          .status
                          ? eventInfo[
                              "One Act Play"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "One Act Play"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event16"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">18</th>
                      <td className="text-start">{"Collage"}</td>
                      <td>
                        {getCount(eve17p1)}/{eventinfo["Collage"].p}
                      </td>
                      <td>{eventinfo["Collage"].a}</td>

                      {/* <td>
                        {eventInfo["Collage"]
                          .status
                          ? eventInfo[
                              "Collage"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Collage"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event17"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">19</th>
                      <td className="text-start">{"Rangoli"}</td>
                      <td>
                        {getCount(eve18p1)}/{eventinfo["Rangoli"].p}
                      </td>
                      <td>{eventinfo["Rangoli"].a}</td>

                      {/* <td>
                        {eventInfo["Rangoli"]
                          .status
                          ? eventInfo[
                              "Rangoli"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Rangoli"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event18"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">20</th>
                      <td className="text-start">{"Cartooning"}</td>
                      <td>
                        {getCount(eve19p1)}/{eventinfo["Cartooning"].p}
                      </td>
                      <td>{eventinfo["Cartooning"].a}</td>

                      {/* <td>
                        {eventInfo["Cartooning"]
                          .status
                          ? eventInfo[
                              "Cartooning"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Cartooning"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event19"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">21</th>
                      <td className="text-start">{"Installation"}</td>
                      <td>
                        {getCount(eve20p1, eve20p2, eve20p3, eve20p4)}/
                        {eventinfo["Installation"].p}
                      </td>
                      <td>{eventinfo["Installation"].a}</td>

                      {/* <td>
                        {eventInfo["Installation"]
                          .status
                          ? eventInfo[
                              "Installation"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Installation"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event20"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">22</th>
                      <td className="text-start">{"Poster Making"}</td>
                      <td>
                        {getCount(eve21p1)}/{eventinfo["Poster Making"].p}
                      </td>
                      <td>{eventinfo["Poster Making"].a}</td>

                      {/* <td>
                        {eventInfo["Poster Making"]
                          .status
                          ? eventInfo[
                              "Poster Making"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Poster Making"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event21"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">23</th>
                      <td className="text-start">{"Clay Modelling"}</td>
                      <td>
                        {getCount(eve22p1)}/{eventinfo["Clay Modelling"].p}
                      </td>
                      <td>{eventinfo["Clay Modelling"].a}</td>

                      {/* <td>
                        {eventInfo["Clay Modelling"]
                          .status
                          ? eventInfo[
                              "Clay Modelling"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Clay Modelling"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event22"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">24</th>
                      <td className="text-start">{"On Spot Painting"}</td>
                      <td>
                        {getCount(eve23p1)}/{eventinfo["On Spot Painting"].p}
                      </td>
                      <td>{eventinfo["On Spot Painting"].a}</td>

                      {/* <td>
                        {eventInfo["On Spot Painting"]
                          .status
                          ? eventInfo[
                              "On Spot Painting"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "On Spot Painting"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event23"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">25</th>
                      <td className="text-start">{"Spot Photography"}</td>
                      <td>
                        {getCount(eve24p1)}/{eventinfo["Spot Photography"].p}
                      </td>
                      <td>{eventinfo["Spot Photography"].a}</td>

                      {/* <td>
                        {eventInfo["Spot Photography"]
                          .status
                          ? eventInfo[
                              "Spot Photography"
                            ]
                          : "" === ""
                          ? "Yet to Submit"
                          : eventInfo[
                              "Spot Photography"
                            ].status === "saved"
                          ? "Data Saved"
                          : eventInfo[
                              "Group Song (Indian)"
                            ].status === "submitted"
                          ? "Data Submitted"
                          : "-"} */}
                      {/* </td> */}
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target={"#event24"}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                  </tbody>

                  <div
                    className="modal fade"
                    id={"event0"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Classical Vocal Solo (Hindustani/Carnatic)
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={(e) => resetData(e)}
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve0p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => {
                                    setEve0p1(e.target.value);
                                  }}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve0a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => {
                                    setEve0a1(e.target.value);
                                  }}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve0a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve0a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event1"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Classical Instrumental Solo (Percussion Tala
                              Vadya)
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve1p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve1p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve1a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve1a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve1a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve1a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event2"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Classical Instrumental Solo (Non-Percussion Swara
                              Vadya)
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve2p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve2p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve2a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve2a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve2a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve2a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event3"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Light Vocal Solo (Indian)
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve3p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve3p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve3a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve3a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve3a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve3a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event4"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Western Vocal Solo
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve4p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve4p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve4a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve4a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve4a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve4a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event5"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Group Song (Indian)
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve5p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve5p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve5p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve5p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve5p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve5p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve5a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve5a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve5a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve5a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event6"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Group Song (Western)
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve6p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve6p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve6p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve6p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve6p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve6p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve6a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve6a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve6a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve6a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event7"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Folk Orchestra
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve7p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve7p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve7p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve7p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve7p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve7p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 7</label>
                                <select
                                  value={eve7p7}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p7(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 8</label>
                                <select
                                  value={eve7p8}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p8(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 9</label>
                                <select
                                  value={eve7p9}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p9(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 10</label>
                                <select
                                  value={eve7p10}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p10(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 11</label>
                                <select
                                  value={eve7p11}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p11(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 12</label>
                                <select
                                  value={eve7p12}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7p12(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve7a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve7a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve7a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve7a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event8"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Folk / Tribal Dance
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve8p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve8p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve8p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve8p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve8p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve8p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 7</label>
                                <select
                                  value={eve8p7}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p7(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 8</label>
                                <select
                                  value={eve8p8}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p8(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 9</label>
                                <select
                                  value={eve8p9}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p9(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 10</label>
                                <select
                                  value={eve8p10}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8p10(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve8a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve8a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve8a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 4</label>
                                <select
                                  value={eve8a4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8a4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 5</label>
                                <select
                                  value={eve8a5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve8a5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event9"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Classical Dance Solo
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve9p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve9p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve9a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve9a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve9a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve9a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve9a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve9a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event10"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Quiz
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve10p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve10p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve10p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve10p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve10p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve10p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event11"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Debate
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve11p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve11p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve11p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve11p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event12"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Elocution
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve12p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve12p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event13"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Skit
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve13p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve13p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve13p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve13p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve13p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve13p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve13a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve13a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve13a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve13a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event14"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Mime
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve14p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve14p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve14p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve14p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve14p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve14p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve14a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve14a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve14a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event15"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Mimicry
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve15p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve15p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event16"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              One Act Play
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve16p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve16p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve16p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve16p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 5</label>
                                <select
                                  value={eve16p5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 6</label>
                                <select
                                  value={eve16p6}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p6(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 7</label>
                                <select
                                  value={eve16p7}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p7(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 8</label>
                                <select
                                  value={eve16p8}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p8(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 9</label>
                                <select
                                  value={eve16p9}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16p9(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <hr />
                              <div className="col-12">
                                <label>Accompanist 1</label>
                                <select
                                  value={eve16a1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16a1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 2</label>
                                <select
                                  value={eve16a2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16a2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 3</label>
                                <select
                                  value={eve16a3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16a3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 4</label>
                                <select
                                  value={eve16a4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16a4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Accompanist 5</label>
                                <select
                                  value={eve16a5}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve16a5(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {combined.map((accompanists) => {
                                    return (
                                      <option value={accompanists.email}>
                                        {capitalizeFirstLetter(
                                          accompanists.name
                                        )}{" "}
                                        {accompanists.role
                                          ? " - " + accompanists.role
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event17"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Collage
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve17p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve17p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event18"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Rangoli
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve18p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve18p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event19"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Cartooning
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve19p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve19p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event20"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Installation
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve20p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve20p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 2</label>
                                <select
                                  value={eve20p2}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve20p2(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 3</label>
                                <select
                                  value={eve20p3}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve20p3(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-12">
                                <label>Participant 4</label>
                                <select
                                  value={eve20p4}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve20p4(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event21"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Poster Making
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve21p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve21p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event22"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Clay Modelling
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve22p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve22p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event23"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              On Spot Painting
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve23p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve23p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id={"event24"}
                    tabindex="-1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg	">
                      <div className="modal-content bg-white text-start">
                        <form>
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Spot Photography
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => resetData(e)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <h4>Select Participants</h4>
                            <form>
                              <hr />
                              <div className="col-12">
                                <label>Participant 1</label>
                                <select
                                  value={eve24p1}
                                  className="form-select"
                                  disabled={isEventsSubmited}
                                  onChange={(e) => setEve24p1(e.target.value)}
                                >
                                  <option value={""} selected>
                                    None
                                  </option>
                                  {student.map((student) => {
                                    return (
                                      <option value={student.email}>
                                        {student.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={(e) => resetData(e)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => saveData(e)}
                              disabled
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </table>
                <div className="col-12">
                  <button
                    type="button"
                    onClick={submitData}
                    disabled
                    className="btn btn-danger"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      cookies.remove("token");
      return <div>{window.location.replace("/login")}</div>;
    }
  } else {
    return <div>{window.location.replace("/login")}</div>;
  }
};
export default AssignEvents;
