import { useEffect, useState } from "react";
import AdminNavBar from "../NavBar/AdminNavBar";
import baseurl from "../../baseurl";
const AdminDashboard = (props) => {
  const [details, setDetails] = useState(false);
  const [editable, setEditable] = useState(true);
  const [saved, setSaved] = useState(false);
  const [CulturalHeadName, setCulturalHeadName] = useState("");
  const [CulturalHeadEmail, setCulturalHeadEmail] = useState("");
  const [CulturalHeadPhone, setCulturalHeadPhone] = useState("");
  const [CulturalHeadAccompanying, setCulturalHeadAccompanying] = useState("");
  const [CulturalHeadExperience, setCulturalHeadExperience] = useState(0);
  const [CulturalHeadGender, setCulturalHeadGender] = useState("");
  const [SportsDirectorName, setSportsDirectorName] = useState("");
  const [SportsDirectorEmail, setSportsDirectorEmail] = useState("");
  const [SportsDirectorPhone, setSportsDirectorPhone] = useState("");
  const [SportsDirectorAccompanying, setSportsDirectorAccompanying] =
    useState("");
  const [SportsDirectorExperience, setSportsDirectorExperience] = useState(0);
  const [SportsDirectorGender, setSportsDirectorGender] = useState("");
  const [ContingentHead, setContingentHead] = useState("");
  const [ContingentArray, setContingentArray] = useState([]);
  const [FacultyInchargeName, setFacultyInchargeName] = useState("");
  const [FacultyInchargeEmail, setFacultyInchargeEmail] = useState("");
  const [FacultyInchargePhone, setFacultyInchargePhone] = useState("");
  const [FacultyInchargeExperience, setFacultyInchargeExperience] = useState(0);
  const [FacultyInchargeGender, setFacultyInchargeGender] = useState("");
  const [PrincipalName, setPrincipalName] = useState("");
  const [PrincipalPhone, setPrincipalPhone] = useState("");
  const AgeRange = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  ];
  const [wantAccomodation, setWantAccomodation] = useState(false);

  const [rerenderer, setRerender] = useState(false);
  useEffect(() => {
    fetch(`${baseurl}/college`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + props.token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDetails(data);
        setEditable(!data.data.isRegistered);
        setCulturalHeadName(data.data.culturalHead.name);
        setCulturalHeadEmail(data.data.culturalHead.email);
        setCulturalHeadAccompanying(data.data.culturalHead.accompanying);
        setCulturalHeadExperience(data.data.culturalHead.yearsOfExperience);
        setCulturalHeadPhone(data.data.culturalHead.phoneNo);
        setCulturalHeadGender(data.data.culturalHead.gender);
        setSportsDirectorName(data.data.sportsDirector.name);
        setSportsDirectorEmail(data.data.sportsDirector.email);
        setSportsDirectorAccompanying(data.data.sportsDirector.accompanying);
        setSportsDirectorExperience(data.data.sportsDirector.yearsOfExperience);
        setSportsDirectorPhone(data.data.sportsDirector.phoneNo);
        setSportsDirectorGender(data.data.sportsDirector.gender);
        setContingentHead(data.data.contingentHead);
        setContingentArray(data.data.contingents);
        setFacultyInchargeName(data.data.facultyIncharge.name);
        setFacultyInchargeEmail(data.data.facultyIncharge.email);
        setFacultyInchargePhone(data.data.facultyIncharge.phoneNo);
        setFacultyInchargeExperience(
          data.data.facultyIncharge.yearsOfExperience
        );
        setFacultyInchargeGender(data.data.facultyIncharge.gender);
        setPrincipalName(data.data.principal.name);
        setPrincipalPhone(data.data.principal.phoneNo);
        setWantAccomodation(data.data.isAccomodationRequired);
      });
  }, [props.token, rerenderer]);

  const sendData = (e) => {
    e.preventDefault();
    var newdata = {};
    if (
      CulturalHeadAccompanying === "true" ||
      SportsDirectorAccompanying === "true"
    ) {
      if (
        CulturalHeadAccompanying === "true" &&
        SportsDirectorAccompanying === "false"
      ) {
        newdata = {
          collegeId: props.userInfo.collegeId,
          principal: {
            name: PrincipalName,
            email: details.data.principal.email,
            phoneNo: PrincipalPhone,
          },
          culturalHead: {
            name: CulturalHeadName,
            email: CulturalHeadEmail,
            phoneNo: CulturalHeadPhone,
            yearsOfExperience: CulturalHeadExperience,
            accompanying: CulturalHeadAccompanying,
            gender: CulturalHeadGender,
          },
          sportsDirector: {
            name: SportsDirectorName,
            email: SportsDirectorEmail,
            phoneNo: SportsDirectorPhone,
            yearsOfExperience: SportsDirectorExperience,
            accompanying: SportsDirectorAccompanying,
            gender: SportsDirectorGender,
          },
          facultyIncharge: {
            name: "",
            email: "",
            phoneNo: "",
            yearsOfExperience: 0,
            gender: "",
          },
          isAccomodationRequired: wantAccomodation,
          contingentHead: "culturalHead",
          contingents: ContingentArray,
          isRegistered: true,
        };
      } else if (
        CulturalHeadAccompanying === "false" &&
        SportsDirectorAccompanying === "true"
      ) {
        newdata = {
          collegeId: props.userInfo.collegeId,
          principal: {
            name: PrincipalName,
            email: details.data.principal.email,
            phoneNo: PrincipalPhone,
          },
          culturalHead: {
            name: CulturalHeadName,
            email: CulturalHeadEmail,
            phoneNo: CulturalHeadPhone,
            yearsOfExperience: CulturalHeadExperience,
            accompanying: CulturalHeadAccompanying,
            gender: CulturalHeadGender,
          },
          sportsDirector: {
            name: SportsDirectorName,
            email: SportsDirectorEmail,
            phoneNo: SportsDirectorPhone,
            yearsOfExperience: SportsDirectorExperience,
            accompanying: SportsDirectorAccompanying,
            gender: SportsDirectorGender,
          },
          facultyIncharge: {
            name: "",
            email: "",
            phoneNo: "",
            yearsOfExperience: 0,
            gender: "",
          },
          isAccomodationRequired: wantAccomodation,
          contingentHead: "sportsDirector",
          contingents: ContingentArray,
          isRegistered: true,
        };
      } else {
        newdata = {
          collegeId: props.userInfo.collegeId,
          principal: {
            name: PrincipalName,
            email: details.data.principal.email,
            phoneNo: PrincipalPhone,
          },
          culturalHead: {
            name: CulturalHeadName,
            email: CulturalHeadEmail,
            phoneNo: CulturalHeadPhone,
            yearsOfExperience: CulturalHeadExperience,
            accompanying: CulturalHeadAccompanying,
            gender: CulturalHeadGender,
          },
          sportsDirector: {
            name: SportsDirectorName,
            email: SportsDirectorEmail,
            phoneNo: SportsDirectorPhone,
            yearsOfExperience: SportsDirectorExperience,
            accompanying: SportsDirectorAccompanying,
            gender: SportsDirectorGender,
          },
          facultyIncharge: {
            name: "",
            email: "",
            phoneNo: "",
            yearsOfExperience: 0,
            gender: "",
          },
          isAccomodationRequired: wantAccomodation,
          contingentHead: ContingentHead,
          contingents: ContingentArray,
          isRegistered: true,
        };
      }
    } else {
      newdata = {
        collegeId: props.userInfo.collegeId,
        principal: {
          name: PrincipalName,
          email: details.data.principal.email,
          phoneNo: PrincipalPhone,
        },
        culturalHead: {
          name: CulturalHeadName,
          email: CulturalHeadEmail,
          phoneNo: CulturalHeadPhone,
          yearsOfExperience: CulturalHeadExperience,
          accompanying: CulturalHeadAccompanying,
          gender: CulturalHeadGender,
        },
        sportsDirector: {
          name: SportsDirectorName,
          email: SportsDirectorEmail,
          phoneNo: SportsDirectorPhone,
          yearsOfExperience: SportsDirectorExperience,
          accompanying: SportsDirectorAccompanying,
          gender: SportsDirectorGender,
        },
        facultyIncharge: {
          name: FacultyInchargeName,
          email: FacultyInchargeEmail,
          phoneNo: FacultyInchargePhone,
          yearsOfExperience: FacultyInchargeExperience,
          gender: FacultyInchargeGender,
        },
        isAccomodationRequired: wantAccomodation,
        contingentHead: "facultyIncharge",
        contingents: ContingentArray,
        isRegistered: true,
      };
    }
    fetch(`${baseurl}/college`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + props.token,
      },
      body: JSON.stringify(newdata),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.message === "College updated Successfully") {
          setDetails(data);
          setEditable(!data.data.isRegistered);
          setCulturalHeadName(data.data.culturalHead.name);
          setCulturalHeadEmail(data.data.culturalHead.email);
          setCulturalHeadAccompanying(data.data.culturalHead.accompanying);
          setCulturalHeadExperience(data.data.culturalHead.yearsOfExperience);
          setCulturalHeadPhone(data.data.culturalHead.phoneNo);
          setSportsDirectorName(data.data.sportsDirector.name);
          setSportsDirectorEmail(data.data.sportsDirector.email);
          setSportsDirectorAccompanying(data.data.sportsDirector.accompanying);
          setSportsDirectorExperience(
            data.data.sportsDirector.yearsOfExperience
          );
          setSportsDirectorPhone(data.data.sportsDirector.phoneNo);
          setContingentHead(data.data.contingentHead);
          setContingentArray(data.data.contingents);
          setFacultyInchargeName(data.data.facultyIncharge.name);
          setFacultyInchargeEmail(data.data.facultyIncharge.email);
          setFacultyInchargePhone(data.data.facultyIncharge.phoneNo);
          setFacultyInchargeExperience(
            data.data.facultyIncharge.yearsOfExperience
          );
          setCulturalHeadGender(data.data.culturalHead.gender);
          setFacultyInchargeGender(data.data.facultyIncharge.gender);
          setSportsDirectorGender(data.data.sportsDirector.gender);
          setPrincipalName(data.data.principal.name);
          setPrincipalPhone(data.data.principal.phoneNo);
          setWantAccomodation(data.data.isAccomodationRequired);
          setEditable(false);
        } else {
          window.alert(data.message);
          window.location.reload();
        }
      });
  };
  const saveData = (e) => {
    e.preventDefault();
    if (editable) {
      var newdata = {};
      if (
        CulturalHeadAccompanying === "true" ||
        SportsDirectorAccompanying === "true"
      ) {
        if (
          CulturalHeadAccompanying === "true" &&
          SportsDirectorAccompanying === "false"
        ) {
          newdata = {
            collegeId: props.userInfo.collegeId,
            principal: {
              name: PrincipalName,
              email: details.data.principal.email,
              phoneNo: PrincipalPhone,
            },
            culturalHead: {
              name: CulturalHeadName,
              email: CulturalHeadEmail,
              phoneNo: CulturalHeadPhone,
              yearsOfExperience: CulturalHeadExperience,
              accompanying: CulturalHeadAccompanying,
              gender: CulturalHeadGender,
            },
            sportsDirector: {
              name: SportsDirectorName,
              email: SportsDirectorEmail,
              phoneNo: SportsDirectorPhone,
              yearsOfExperience: SportsDirectorExperience,
              accompanying: SportsDirectorAccompanying,
              gender: SportsDirectorGender,
            },
            facultyIncharge: {
              name: "",
              email: "",
              phoneNo: "",
              yearsOfExperience: 0,
              gender: "",
            },
            isAccomodationRequired: wantAccomodation,

            contingentHead: "culturalHead",
            contingents: ContingentArray,
            isRegistered: false,
          };
        } else if (
          CulturalHeadAccompanying === "false" &&
          SportsDirectorAccompanying === "true"
        ) {
          newdata = {
            collegeId: props.userInfo.collegeId,
            principal: {
              name: PrincipalName,
              email: details.data.principal.email,
              phoneNo: PrincipalPhone,
            },
            culturalHead: {
              name: CulturalHeadName,
              email: CulturalHeadEmail,
              phoneNo: CulturalHeadPhone,
              yearsOfExperience: CulturalHeadExperience,
              accompanying: CulturalHeadAccompanying,
              gender: CulturalHeadGender,
            },
            sportsDirector: {
              name: SportsDirectorName,
              email: SportsDirectorEmail,
              phoneNo: SportsDirectorPhone,
              yearsOfExperience: SportsDirectorExperience,
              accompanying: SportsDirectorAccompanying,
              gender: SportsDirectorGender,
            },
            facultyIncharge: {
              name: "",
              email: "",
              phoneNo: "",
              yearsOfExperience: 0,
              gender: "",
            },
            isAccomodationRequired: wantAccomodation,
            contingentHead: "sportsDirector",
            contingents: ContingentArray,
            isRegistered: false,
          };
        } else {
          newdata = {
            collegeId: props.userInfo.collegeId,
            principal: {
              name: PrincipalName,
              email: details.data.principal.email,
              phoneNo: PrincipalPhone,
            },
            culturalHead: {
              name: CulturalHeadName,
              email: CulturalHeadEmail,
              phoneNo: CulturalHeadPhone,
              yearsOfExperience: CulturalHeadExperience,
              accompanying: CulturalHeadAccompanying,
              gender: CulturalHeadGender,
            },
            sportsDirector: {
              name: SportsDirectorName,
              email: SportsDirectorEmail,
              phoneNo: SportsDirectorPhone,
              yearsOfExperience: SportsDirectorExperience,
              accompanying: SportsDirectorAccompanying,
              gender: SportsDirectorGender,
            },
            facultyIncharge: {
              name: "",
              email: "",
              phoneNo: "",
              yearsOfExperience: 0,
              gender: "",
            },
            isAccomodationRequired: wantAccomodation,
            contingentHead: ContingentHead,
            contingents: ContingentArray,
            isRegistered: false,
          };
        }
      } else {
        newdata = {
          collegeId: props.userInfo.collegeId,
          culturalHead: {
            name: CulturalHeadName,
            email: CulturalHeadEmail,
            phoneNo: CulturalHeadPhone,
            yearsOfExperience: CulturalHeadExperience,
            accompanying: CulturalHeadAccompanying,
            gender: CulturalHeadGender,
          },
          principal: {
            name: PrincipalName,
            email: details.data.principal.email,
            phoneNo: PrincipalPhone,
          },
          sportsDirector: {
            name: SportsDirectorName,
            email: SportsDirectorEmail,
            phoneNo: SportsDirectorPhone,
            yearsOfExperience: SportsDirectorExperience,
            accompanying: SportsDirectorAccompanying,
            gender: SportsDirectorGender,
          },
          facultyIncharge: {
            name: FacultyInchargeName,
            email: FacultyInchargeEmail,
            phoneNo: FacultyInchargePhone,
            yearsOfExperience: FacultyInchargeExperience,
            gender: FacultyInchargeGender,
          },
          isAccomodationRequired: wantAccomodation,
          contingentHead: "facultyIncharge",
          contingents: ContingentArray,
          isRegistered: false,
        };
      }
      fetch(`${baseurl}/college`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + props.token,
        },
        body: JSON.stringify(newdata),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.message === "College updated Successfully") {
            setDetails(data);
            setEditable(!data.data.isRegistered);
            setCulturalHeadName(data.data.culturalHead.name);
            setCulturalHeadEmail(data.data.culturalHead.email);
            setCulturalHeadAccompanying(data.data.culturalHead.accompanying);
            setCulturalHeadExperience(data.data.culturalHead.yearsOfExperience);
            setCulturalHeadPhone(data.data.culturalHead.phoneNo);
            setSportsDirectorName(data.data.sportsDirector.name);
            setSportsDirectorEmail(data.data.sportsDirector.email);
            setSportsDirectorAccompanying(
              data.data.sportsDirector.accompanying
            );
            setSportsDirectorExperience(
              data.data.sportsDirector.yearsOfExperience
            );
            setSportsDirectorPhone(data.data.sportsDirector.phoneNo);
            setContingentHead(data.data.contingentHead);
            setContingentArray(data.data.contingents);
            setFacultyInchargeName(data.data.facultyIncharge.name);
            setFacultyInchargeEmail(data.data.facultyIncharge.email);
            setFacultyInchargePhone(data.data.facultyIncharge.phoneNo);
            setFacultyInchargeExperience(
              data.data.facultyIncharge.yearsOfExperience
            );
            setCulturalHeadGender(data.data.culturalHead.gender);
            setFacultyInchargeGender(data.data.facultyIncharge.gender);
            setSportsDirectorGender(data.data.sportsDirector.gender);
            setPrincipalName(data.data.principal.name);
            setPrincipalPhone(data.data.principal.phoneNo);
            setWantAccomodation(data.data.isAccomodationRequired);
            setEditable(true);
            savedData();
          } else {
            window.alert(data.message);
            window.location.reload();
          }
        });
    }
  };
  const savedData = () => {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 4000);
  };
  return (
    <>
      <AdminNavBar />
      {!details ? null : (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center mt-3">
                <div className="col-md-6 col-12 text-center">
                  {saved ? (
                    <div className="alert alert-success" role="alert">
                      The provided details have been saved.
                    </div>
                  ) : null}
                  {editable ? null : (
                    <div className="alert alert-success" role="alert">
                      Congratulations! Your details have been successfully
                      submitted. You can proceed to the next step, which will be
                      forming your contingent. You can do so by clicking on the
                      "Invite Contingent Member" button on the top of the
                      screen.
                    </div>
                  )}
                </div>
                <h4>
                  <strong>
                    The registrations have ended and all details are frozen.
                  </strong>
                </h4>
              </div>
              <div className="row justify-content-center">
                {/*College Details*/}
                <div className="col-md-5 col-12 mt-5">
                  <form className="row text-start">
                    <h3>College Details</h3>
                    <div className="col-12">
                      <label forhtml="name" className="form-label">
                        College Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        id="name"
                        readOnly={true}
                        value={details.data.collegeName}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="address" className="form-label">
                        College Address
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        disabled
                        id="address"
                        readOnly={true}
                        value={details.data.collegeAddress}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-md-2 col-12"></div>
                {/*Principal Details*/}
                <div className="col-md-5 col-12 mt-5">
                  <form className="row text-start">
                    <h3>Principal Details</h3>
                    <div className="col-12">
                      <label forhtml="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        id="name"
                        value={PrincipalName}
                        onChange={(e) => setPrincipalName(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="email" className="form-label">
                        Email ID
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        disabled
                        id="email"
                        readOnly={true}
                        value={details.data.principal.email}
                      />
                    </div>
                    <div className="col-12">
                      <label forhtml="phone" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        disabled
                        id="phone"
                        value={PrincipalPhone}
                        onChange={(e) => setPrincipalPhone(e.target.value)}
                        autoComplete="off"
                        readOnly={!editable}
                        required
                      />
                    </div>
                  </form>
                </div>
              </div>
              <form
                className="row justify-content-center mt-5 mb-5 text-start"
                onSubmit={sendData}
              >
                {/*Cultural Head Form*/}
                <div className="col-md-5 col-12 mt-5">
                  <h3>Cultural Coordinator Details</h3>
                  <div className="col-12">
                    <label forhtml="name" className="form-label">
                      Name
                    </label>
                    <input
                      type={"text"}
                      name="name"
                      placeholder="Full Name"
                      className="form-control"
                      disabled
                      value={CulturalHeadName}
                      onChange={(e) => setCulturalHeadName(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="email" className="form-label">
                      Email ID
                    </label>
                    <input
                      type={"email"}
                      name="email"
                      placeholder="example@domain.com"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      className="form-control"
                      disabled
                      value={CulturalHeadEmail}
                      onChange={(e) => setCulturalHeadEmail(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      name="gender"
                      className="form-select"
                      value={CulturalHeadGender}
                      onChange={(e) => setCulturalHeadGender(e.target.value)}
                      autoComplete="off"
                      disabled={!editable}
                      required
                    >
                      <option value={""} selected disabled>
                        Choose One...
                      </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label forhtml="address" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type={"tel"}
                      name="phone"
                      placeholder="+91 98765 43210"
                      className="form-control"
                      disabled
                      value={CulturalHeadPhone}
                      onChange={(e) => setCulturalHeadPhone(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="address" className="form-label">
                      Years of Experience
                    </label>
                    <select
                      className="form-select"
                      id="experience"
                      value={CulturalHeadExperience}
                      onChange={(e) =>
                        setCulturalHeadExperience(e.target.value)
                      }
                      autoComplete="off"
                      disabled={!editable}
                      required
                    >
                      <option value={""} selected disabled>
                        Choose One...
                      </option>
                      {AgeRange.map((index) => {
                        return (
                          <option value={(index + 4).toString()}>
                            {index + 4}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      Will they accompany the contingent?
                    </label>
                    <select
                      className="form-select"
                      value={CulturalHeadAccompanying}
                      onChange={(e) =>
                        setCulturalHeadAccompanying(e.target.value)
                      }
                      disabled={!editable}
                      required
                    >
                      <option selected disabled value={""}>
                        Choose one...
                      </option>
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-12"> </div>
                {/*Sports Director Form*/}
                <div className="col-md-5 col-12 mt-5">
                  <h3>Physical Education Director Details</h3>
                  <div className="col-12">
                    <label forhtml="name" className="form-label">
                      Name
                    </label>
                    <input
                      type={"text"}
                      name="name"
                      placeholder="Full Name"
                      className="form-control"
                      disabled
                      value={SportsDirectorName}
                      onChange={(e) => setSportsDirectorName(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="email" className="form-label">
                      Email ID
                    </label>
                    <input
                      type={"email"}
                      name="email"
                      placeholder="example@domain.com"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      className="form-control"
                      disabled
                      value={SportsDirectorEmail}
                      onChange={(e) => setSportsDirectorEmail(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      name="gender"
                      className="form-select"
                      value={SportsDirectorGender}
                      onChange={(e) => setSportsDirectorGender(e.target.value)}
                      autoComplete="off"
                      disabled={!editable}
                      required
                    >
                      <option value={""} selected disabled>
                        Choose One...
                      </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label forhtml="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type={"tel"}
                      name="phone"
                      placeholder="+91 98765 43210"
                      className="form-control"
                      disabled
                      value={SportsDirectorPhone}
                      onChange={(e) => setSportsDirectorPhone(e.target.value)}
                      autoComplete="off"
                      readOnly={!editable}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label forhtml="phone" className="form-label">
                      Years of Experience
                    </label>
                    <select
                      className="form-select"
                      id="experience"
                      value={SportsDirectorExperience}
                      onChange={(e) =>
                        setSportsDirectorExperience(e.target.value)
                      }
                      autoComplete="off"
                      disabled={!editable}
                      required
                    >
                      <option value={""} selected disabled>
                        Choose One...
                      </option>
                      {AgeRange.map((index) => {
                        return (
                          <option value={(index + 4).toString()}>
                            {index + 4}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className=" col-12">
                    <label className="form-label">
                      Will they accompany the contingent?
                    </label>
                    <select
                      className="form-select"
                      value={SportsDirectorAccompanying}
                      onChange={(e) =>
                        setSportsDirectorAccompanying(e.target.value)
                      }
                      disabled={!editable}
                      required
                    >
                      <option selected disabled value={""}>
                        Choose one...
                      </option>
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </select>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="row justify-content-center mt-3">
                    <div className="col-md-5 col-12">
                      {SportsDirectorAccompanying === "false" &&
                      CulturalHeadAccompanying === "false" ? (
                        <>
                          <h3>Faculty Incharge Details</h3>
                          <div className="col-12">
                            <label forhtml="name" className="form-label">
                              Name
                            </label>
                            <input
                              type={"text"}
                              name="name"
                              placeholder="Full Name"
                              className="form-control"
                              disabled
                              value={FacultyInchargeName}
                              onChange={(e) =>
                                setFacultyInchargeName(e.target.value)
                              }
                              autoComplete="off"
                              readOnly={!editable}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label forhtml="email" className="form-label">
                              Email ID
                            </label>
                            <input
                              type={"email"}
                              name="email"
                              placeholder="example@domain.com"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                              className="form-control"
                              disabled
                              value={FacultyInchargeEmail}
                              onChange={(e) =>
                                setFacultyInchargeEmail(e.target.value)
                              }
                              autoComplete="off"
                              readOnly={!editable}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label forhtml="gender" className="form-label">
                              Gender
                            </label>
                            <select
                              name="gender"
                              className="form-select"
                              value={FacultyInchargeGender}
                              onChange={(e) =>
                                setFacultyInchargeGender(e.target.value)
                              }
                              autoComplete="off"
                              disabled={!editable}
                              required
                            >
                              <option value={""} selected disabled>
                                Choose One...
                              </option>
                              <option value={"male"}>Male</option>
                              <option value={"female"}>Female</option>
                            </select>
                          </div>
                          <div className="col-12">
                            <label forhtml="phone" className="form-label">
                              Phone Number
                            </label>
                            <input
                              type={"tel"}
                              name="phone"
                              placeholder="+91 98765 43210"
                              className="form-control"
                              disabled
                              value={FacultyInchargePhone}
                              onChange={(e) =>
                                setFacultyInchargePhone(e.target.value)
                              }
                              autoComplete="off"
                              readOnly={!editable}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label forhtml="phone" className="form-label">
                              Years of Experience
                            </label>
                            <select
                              className="form-select"
                              id="experience"
                              value={FacultyInchargeExperience}
                              onChange={(e) =>
                                setFacultyInchargeExperience(e.target.value)
                              }
                              autoComplete="off"
                              disabled={!editable}
                              required
                            >
                              <option value={""} selected disabled>
                                Choose One...
                              </option>
                              {AgeRange.map((index) => {
                                return (
                                  <option value={(index + 4).toString()}>
                                    {index + 4}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </>
                      ) : SportsDirectorAccompanying === "true" &&
                        CulturalHeadAccompanying === "true" ? (
                        <>
                          <label className="form-label">
                            Select the accompanying Team Manager
                          </label>
                          <select
                            className="form-select"
                            value={ContingentHead}
                            onChange={(e) => setContingentHead(e.target.value)}
                            disabled={!editable}
                            required
                          >
                            <option selected disabled value={""}>
                              Choose one...
                            </option>
                            <option value={"culturalHead"}>
                              Cultural Coordinator
                            </option>
                            <option value={"sportsDirector"}>
                              Physical Education Director
                            </option>
                          </select>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12">
                    <div className="col-12">
                      <label>Is accomodation required?</label>
                      {console.log(typeof wantAccomodation)}
                      <select
                        className="form-select"
                        value={wantAccomodation}
                        onChange={(e) => setWantAccomodation(e.target.value)}
                        disabled={!editable}
                        required
                      >
                        <option selected disabled value={""}>
                          Choose one...
                        </option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center">
                    {saved ? (
                      <div className="alert alert-success" role="alert">
                        The provided details have been saved.
                      </div>
                    ) : null}
                    {editable ? null : (
                      <div className="alert alert-success" role="alert">
                        Congratulations! Your details have been successfully
                        submitted. You can proceed to the next step, which will
                        be forming your contingent. You can do so by clicking on
                        the "Invite Contingent Member" button on the top of the
                        screen.
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6 col-12 text-center mt-4">
                    <span>
                      Note: Data once submitted cannot be changed. Please ensure
                      all details filled in are correct before clicking on the
                      Submit button. To save the details in the database and
                      continue editing, click on the save button.
                    </span>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-1 col-4 text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={saveData}
                      disabled={!editable}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-md-1 col-4 text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      disabled={!editable}
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <p>
                            <strong>
                              No changes can be made in the above details once
                              submitted.
                            </strong>
                          </p>
                          <h5 className="modal-title" id="exampleModalLabel">
                            College Details
                          </h5>

                          <hr />
                          <span>
                            Name: <strong>{details.data.collegeName}</strong>
                          </span>
                          <br />
                          <span>
                            Address:{" "}
                            <strong>{details.data.collegeAddress}</strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Principal Details
                          </h5>
                          <span>
                            Name: <strong>{details.data.principal.name}</strong>
                          </span>
                          <br />
                          <span>
                            Email ID:{" "}
                            <strong>{details.data.principal.email}</strong>
                          </span>
                          <br />
                          <span>
                            Phone No.:{" "}
                            <strong>{details.data.principal.phoneNo}</strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Cultural Coordinator Details
                          </h5>
                          <span>
                            Name: <strong>{CulturalHeadName}</strong>
                          </span>
                          <br />
                          <span>
                            Email ID: <strong>{CulturalHeadEmail}</strong>
                          </span>
                          <br />
                          <span>
                            Phone No.: <strong>{CulturalHeadPhone}</strong>
                          </span>
                          <br />
                          <span>
                            Years Of Experience:{" "}
                            <strong>{CulturalHeadExperience}</strong>
                          </span>
                          <br />
                          <span>
                            Will they accompany the contingent?{" "}
                            <strong>
                              {CulturalHeadAccompanying === "true"
                                ? "Yes"
                                : "No"}
                            </strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Physical Education Director Details
                          </h5>
                          <span>
                            Name: <strong>{SportsDirectorName}</strong>
                          </span>
                          <br />
                          <span>
                            Email ID: <strong>{SportsDirectorEmail}</strong>
                          </span>
                          <br />
                          <span>
                            Phone No.: <strong>{SportsDirectorPhone}</strong>
                          </span>
                          <br />
                          <span>
                            Years Of Experience:{" "}
                            <strong>{SportsDirectorExperience}</strong>
                          </span>
                          <br />
                          <span>
                            Will they accompany the contingent?{" "}
                            <strong>
                              {SportsDirectorAccompanying === "true"
                                ? "Yes"
                                : "No"}
                            </strong>
                          </span>
                          <hr />
                          <h5 className="modal-title" id="exampleModalLabel">
                            Contingent Details
                          </h5>
                          <span>
                            Accompanying Team Manager:{" "}
                            <strong>
                              {ContingentHead === "sportsDirector"
                                ? "Physical Education Director"
                                : ContingentHead === "culturalHead"
                                ? "Cultural Coordinator"
                                : "Faculty Incharge"}
                            </strong>
                          </span>

                          {editable ? null : (
                            <>
                              <hr />
                              <div className="alert alert-success" role="alert">
                                Congratulations! Your details have been
                                successfully submitted. You can proceed to the
                                next step, which will be forming your
                                contingent. Please check your Email inbox for
                                further details.
                              </div>
                            </>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            disabled={!editable}
                          >
                            Edit
                          </button>
                          <button
                            type="submit"
                            data-bs-dismiss="modal"
                            className="btn btn-danger"
                            disabled={!editable}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AdminDashboard;
