import Cookies from "universal-cookie";
import PreLoginNavBar from "../NavBar/PreLoginNavBar";
import AdminNavBar from "../NavBar/AdminNavBar";
const NotFound = () => {
  const cookies = new Cookies();
  const userInfo = cookies.get("userInfo");
  return (
    <>
      {userInfo ? (
        userInfo.role === "admin" ? (
          <AdminNavBar />
        ) : userInfo.role === "student" ? null : (
          <PreLoginNavBar />
        )
      ) : (
        <PreLoginNavBar />
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>404 Not Found</h1>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotFound;
