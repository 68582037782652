const eventinfo = {
  "Classical Vocal Solo (Hindustani/Carnatic)": {
    p: 1,
    a: 2,
  },
  "Classical Instrumental Solo (Percussion Tala Vadya)": {
    p: 1,
    a: 2,
  },
  "Classical Instrumental Solo (Non-Percussion Swara Vadya)": {
    p: 1,
    a: 2,
  },
  "Light Vocal Solo (Indian)": {
    p: 1,
    a: 2,
  },
  "Western Vocal Solo": {
    p: 1,
    a: 2,
  },
  "Group Song (Indian)": {
    p: 6,
    a: 3,
  },
  "Group Song (Western)": {
    p: 6,
    a: 3,
  },
  "Folk Orchestra": {
    p: 12,
    a: 3,
  },
  "Folk / Tribal Dance": {
    p: 10,
    a: 5,
  },
  "Classical Dance Solo": {
    p: 1,
    a: 3,
  },
  Quiz: {
    p: 3,
    a: 0,
  },
  Debate: {
    p: 2,
    a: 0,
  },
  Elocution: {
    p: 1,
    a: 0,
  },
  Skit: {
    p: 6,
    a: 3,
  },
  Mime: {
    p: 6,
    a: 2,
  },
  Mimicry: {
    p: 1,
    a: 0,
  },
  "One Act Play": {
    p: 9,
    a: 5,
  },
  Collage: {
    p: 1,
    a: 0,
  },
  Rangoli: {
    p: 1,
    a: 0,
  },
  Cartooning: {
    p: 1,
    a: 0,
  },
  Installation: {
    p: 4,
    a: 0,
  },
  "Poster Making": {
    p: 1,
    a: 0,
  },
  "Clay Modelling": {
    p: 1,
    a: 0,
  },
  "On Spot Painting": {
    p: 1,
    a: 0,
  },
  "Spot Photography": {
    p: 1,
    a: 0,
  },
};

export default eventinfo;
