import { useEffect, useState } from "react";
import AdminNavBar from "../NavBar/AdminNavBar";
import Cookies from "universal-cookie";
import baseurl from "../../baseurl";

const PaymentPortal = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const userInfo = cookies.get("userInfo");
  const [TransactionId, setTransactionId] = useState("");
  const [PaymentDate, setPaymentDate] = useState("");
  const [SenderName, setSenderName] = useState("");
  const [SenderAccNo, setSenderAccNo] = useState("");
  const [SenderISFC, setSenderISFC] = useState("");
  const [SenderBank, setSenderBank] = useState("");
  const [SenderAmount, setSenderAmount] = useState("");
  const [message, setMessage] = useState("");
  const [PPAlert, setPPAlert] = useState("");
  const [paymentImage, setPaymentImage] = useState();
  const [PaymentPhoto, setPaymentPhoto] = useState();
  useEffect(() => {
    fetch(`${baseurl}/payment`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          if (data.payment === null) {
            setMessage("No Payment Info Found");
          } else {
            setTransactionId(data.payment.transactionId);
            setPaymentDate(data.payment.paymentDate);
            setSenderName(data.payment.senderName);
            setSenderAccNo(data.payment.senderAccountNumber);
            setSenderISFC(data.payment.senderIfscCode);
            setSenderBank(data.payment.senderBankName);
            setSenderAmount(data.payment.amount);
          }
        } else {
          setMessage(data.message);
        }
      });
    fetch(`${baseurl}/payment/image`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.payment === undefined
          ? setPaymentImage()
          : setPaymentImage(data.payment.url);
      });
    fetch(`${baseurl}/college/amount`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setSenderAmount(data.data);
        console.log(data);
      });
  }, [token]);
  const sendData = (e) => {
    e.preventDefault();
    fetch(`${baseurl}/payment`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        transactionId: TransactionId,
        amount: SenderAmount,
        paymentDate: PaymentDate,
        senderAccountNumber: SenderAccNo,
        senderIfscCode: SenderISFC,
        senderBankName: SenderBank,
        senderName: SenderName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setTransactionId(data.payment.transactionId);
          setPaymentDate(data.payment.paymentDate);
          setSenderName(data.payment.senderName);
          setSenderAccNo(data.payment.senderAccountNumber);
          setSenderISFC(data.payment.senderIfscCode);
          setSenderBank(data.payment.senderBankName);
          setSenderAmount(data.payment.amount);
          setMessage("Details successfully submitted.");
        } else {
          alert("Invalid details. Please recheck all the fields entered.");
          setMessage(data.message);
        }
      });
  };
  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "bmp":
      case "png":
      case "jpeg":
      case "webp":
        //etc
        return true;
    }
    return false;
  };
  const uploadPaymentPhoto = (e) => {
    e.preventDefault();
    setPPAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setPaymentPhoto(file)
        : setPPAlert("File size should be less than 2MB")
      : setPPAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const submitPaymentPhoto = (e) => {
    e.preventDefault();
    if (isImage(PaymentPhoto.name)) {
      if (PaymentPhoto.size < 2000000) {
        const photoForm = new FormData();
        photoForm.append("payment", PaymentPhoto);
        setPaymentImage("");
        fetch(`${baseurl}/payment/image`, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: photoForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setPaymentImage(data.payment.url);
            setPPAlert(
              "Awaiting confirmation of payment. This process might take upto three business days. Please check your email inbox for further communication."
            );
          });
      } else {
        setPPAlert("File size should be less than 2MB");
      }
    } else {
      setPPAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }

    setTimeout(() => {
      setPPAlert("");
    }, 4000);
  };
  if (token) {
    if (userInfo.role === "admin") {
      return (
        <>
          <AdminNavBar />
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-12">
                <h1>Payment Info</h1>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-6 text-start">
                    <div className="card">
                      <div className="card-body">
                        <p>
                          <strong>Acc No.:</strong> 50267366389
                          <br />
                          <strong>Name:</strong> PRATHIBHOTSAVA
                          <br />
                          <strong>Type of Acc:</strong> SB
                          <br />
                          <strong>IFSC Code:</strong> IDIB000B607
                          <br />
                          <strong>Branch:</strong> Hanumanthnagar
                          <br />
                          <strong>Bank Name:</strong> Indian Bank
                          <br />
                          <strong>Amount:</strong> {SenderAmount}*
                          <br />
                          <p className="text-muted">
                            Note:- The amount may change according to number of
                            events you have participated in. Kindly check the
                            amount after assinging events.
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h1>Transaction Details</h1>
                <form onSubmit={sendData}>
                  <div className="row justify-content-center">
                    <div className="col-md-6 text-start">
                      <div className="col-12">
                        <label forhtml="TransactionID" className="form-label">
                          Transaction ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="TransactionID"
                          value={TransactionId}
                          placeholder="Enter Transaction ID"
                          onChange={(e) => setTransactionId(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label forhtml="SenderName" className="form-label">
                          Sender Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="SenderName"
                          value={SenderName}
                          placeholder="Enter Sender Full Name"
                          onChange={(e) => setSenderName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label forhtml="SenderAccNo" className="form-label">
                          Sender Account Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          disabled
                          id="SenderAccNo"
                          value={SenderAccNo}
                          placeholder="Enter Sender Account Number"
                          onChange={(e) => setSenderAccNo(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label forhtml="SenderAccNo" className="form-label">
                          Sender IFSC Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="SenderAccNo"
                          value={SenderISFC}
                          placeholder="Enter Sender IFSC Code"
                          onChange={(e) => setSenderISFC(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label forhtml="SenderAccNo" className="form-label">
                          Sender Bank Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="SenderAccNo"
                          value={SenderBank}
                          placeholder="Enter Sender Bank Name"
                          onChange={(e) => setSenderBank(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label forhtml="SenderAccNo" className="form-label">
                          Transaction Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          disabled
                          id="SenderAccNo"
                          value={PaymentDate}
                          onChange={(e) => setPaymentDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label forhtml="SenderAccNo" className="form-label">
                          Amount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="SenderAccNo"
                          disabled={true}
                          value={SenderAmount}
                          onChange={(e) => setSenderAmount(e.target.value)}
                          required
                        />
                      </div>
                      {message === "Details successfully submitted." ? (
                        <div className="col-12">
                          <div className="alert alert-danger mt-3" role="alert">
                            {message}
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          <div className="alert alert-danger mt-3" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                      <div className="col-12 mt-2">
                        <button
                          type="submit"
                          disabled
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row justify-content-center mt-5 mb-5">
                  <div className="col-md-8  mt-5">
                    <h3>Upload Payment Photo</h3>
                    <form
                      className="row justify-content-center mt-3"
                      onSubmit={submitPaymentPhoto}
                    >
                      <div className="col-lg-8 col-12">
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            disabled
                            id="Signature"
                            name="Signature"
                            onChange={(e) => uploadPaymentPhoto(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            type="submit"
                            id="inputGroupFileAddon04"
                            disabled
                          >
                            Upload File
                          </button>
                        </div>
                        {PPAlert ===
                        "Awaiting confirmation of payment. This process might take upto three business days. Please check your email inbox for further communication." ? (
                          <div className="alert alert-danger mt-3" role="alert">
                            {PPAlert}
                          </div>
                        ) : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {PPAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {paymentImage === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !paymentImage ? (
                            <div className="card-body">
                              <br />
                              <p>Please upload the file!</p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={paymentImage}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      cookies.remove("token");
      return <div>{window.location.replace("/login")}</div>;
    }
  } else {
    return <div>{window.location.replace("/login")}</div>;
  }
};
export default PaymentPortal;
