import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import crypto from "crypto-js";
import PreLoginNavBar from "../NavBar/PreLoginNavBar";
import baseurl from "../../baseurl";
const UpdatePassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passtext, setPasstext] = useState(true);
  const showPassword = () => {
    setPasstext(!passtext);
  };
  useEffect(() => {
    if (token) {
      fetch(`${baseurl}/validate-reset-password-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            setEmail(data.data.email);
            setName(data.data.name);
          } else {
            setMessage(data.message);
          }
        });
    }
  }, [token]);
  const resetPassword = (e) => {
    e.preventDefault();
    password === confirmPassword
      ? fetch(`${baseurl}/update-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email,
            password: crypto.SHA256(password).toString(),
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            window.location.replace("/login");
          })
      : setMessage("Passwords do not match");
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <>
      <PreLoginNavBar />
      <div className="container">
        <div className="row justify-content-center">
          {message === "" ? (
            <div className="col-md-6">
              <div className="card mt-5">
                <div className="card-body">
                  <h2>Reset Password</h2>
                  <form onSubmit={resetPassword}>
                    <div className="form-group text-start">
                      <div className="col-12 mt-3">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Full Name"
                          value={name}
                          onChange={(e) => setEmail(e.target.value)}
                          readOnly
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label htmlFor="email">Email ID</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email ID"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          readOnly
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label htmlFor="password">Password</label>
                        <input
                          type={passtext ? "password" : "text"}
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label htmlFor="canfirmpassword">
                          Confirm Password
                        </label>
                        <input
                          type={passtext ? "password" : "text"}
                          className="form-control"
                          id="canfirmpassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <input
                          type="checkbox"
                          onClick={(e) => showPassword()}
                        />
                        &nbsp;&nbsp;Show Password
                      </div>
                      <div className="col-12 mt-3">
                        <button type="submit" className="btn btn-primary">
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 mt-5">
            {message === "" ? null : message ===
              "Please check your email for furthur procedure" ? (
              <div className="alert alert-success">{message}</div>
            ) : (
              <div className="alert alert-danger">{message}</div>
            )}{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdatePassword;
