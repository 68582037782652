import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import baseurl from "../../baseurl";

import StudentNavBar from "../NavBar/StudentNavBar";
const UploadDocuments = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const userInfoC = cookies.get("userInfo");
  const [aadhar, setAadhar] = useState();
  const [signature, setSignature] = useState();
  const [idcard, setIdcard] = useState();
  const [photo, setPhoto] = useState();
  const [marksheet, setMarksheet] = useState();
  const [certificate, setCertificate] = useState();
  const [StudentAadhar, setStudentAadhar] = useState();
  const [StudentIDCard, setStudentIDCard] = useState();
  const [StudentPhoto, setStudentPhoto] = useState();
  const [StudentSignature, setStudentSignature] = useState();
  const [Student10thMarksheet, setStudent10thMarksheet] = useState();
  const [Student12thCertificate, setStudent12thCertificate] = useState();
  const [PAlert, setPAlert] = useState("");
  const [AAlert, setAAlert] = useState("");
  const [IAlert, setIAlert] = useState("");
  const [SAlert, setSAlert] = useState("");
  const [MAlert, setMAlert] = useState("");
  const [CAlert, setCAlert] = useState("");
  const [editable, setEditable] = useState(true);
  const [details, setDetails] = useState({});
  useEffect(() => {
    fetch(`${baseurl}/student`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setEditable(!data.data.isDocsSubmitted);
        setDetails(data.data);
      });
    fetch(`${baseurl}/student/docs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === 200) {
          data.data.aadhar ? setAadhar(data.data.aadhar) : setAadhar("");
          data.data.signature
            ? setSignature(data.data.signature)
            : setSignature("");
          data.data.idCard ? setIdcard(data.data.idCard) : setIdcard("");
          data.data.photo ? setPhoto(data.data.photo) : setPhoto("");
          data.data.tenth ? setMarksheet(data.data.tenth) : setMarksheet("");
          data.data.twelfth
            ? setCertificate(data.data.twelfth)
            : setCertificate("");
        }
      });
  }, [token]);
  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "bmp":
      case "png":
      case "jpeg":
      case "webp":
        //etc
        return true;
    }
    return false;
  };
  const uploadAadhar = (e) => {
    e.preventDefault();
    setAAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setStudentAadhar(file)
        : setAAlert("File size should be less than 2MB")
      : setAAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const uploadID = (e) => {
    e.preventDefault();
    setIAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setStudentIDCard(file)
        : setIAlert("File size should be less than 2MB")
      : setIAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const upload10thMarksheet = (e) => {
    e.preventDefault();
    setMAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setStudent10thMarksheet(file)
        : setMAlert("File size should be less than 2MB")
      : setMAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const upload12thCertificate = (e) => {
    e.preventDefault();
    setCAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setStudent12thCertificate(file)
        : setCAlert("File size should be less than 2MB")
      : setCAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const uploadPhoto = (e) => {
    e.preventDefault();
    setPAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setStudentPhoto(file)
        : setPAlert("File size should be less than 2MB")
      : setPAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const uploadSignature = (e) => {
    e.preventDefault();
    setSAlert("");
    var file = e.target.files[0];
    isImage(file.name)
      ? file.size < 2000000
        ? setStudentSignature(file)
        : setSAlert("File size should be less than 2MB")
      : setSAlert(
          "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
        );
  };
  const submitPhotos = (e) => {
    e.preventDefault();
    setEditable(false);
    if (isImage(StudentPhoto.name)) {
      if (StudentPhoto.size < 2000000) {
        const photoForm = new FormData();
        photoForm.append("photo", StudentPhoto);
        setPhoto("");
        fetch(`${baseurl}/student/photo`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
          body: photoForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            data.status === 200
              ? setPhoto(data.data.url)
              : setPhoto("Error while uploading");
          });
      } else {
        setPAlert("File size should be less than 2MB");
      }
    } else {
      setPAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }

    setTimeout(() => {
      setPAlert("");
    }, 4000);
    setEditable(true);
  };
  const submitSignature = (e) => {
    e.preventDefault();
    setEditable(false);
    if (isImage(StudentSignature.name)) {
      if (StudentSignature.size < 2000000) {
        const signatureForm = new FormData();
        signatureForm.append("signature", StudentSignature);
        setSignature("");
        fetch(`${baseurl}/student/signature`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
          body: signatureForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            data.status === 200
              ? setSignature(data.data.url)
              : setSignature("Error while uploading");
          });
      } else {
        setSAlert("File size should be less than 2MB");
      }
    } else {
      setSAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setSAlert("");
    }, 4000);
    setEditable(true);
  };
  const submitAadhar = (e) => {
    e.preventDefault();
    setEditable(false);
    if (isImage(StudentAadhar.name)) {
      if (StudentAadhar.size < 2000000) {
        const aadharForm = new FormData();
        aadharForm.append("aadhar", StudentAadhar);
        setAadhar("");
        fetch(`${baseurl}/student/aadhar`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
          body: aadharForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            data.status === 200
              ? setAadhar(data.data.url)
              : setAadhar("Error while uploading");
          });
      } else {
        setAAlert("File size should be less than 2MB");
      }
    } else {
      setAAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setAAlert("");
    }, 4000);
    setEditable(true);
  };
  const submitID = (e) => {
    e.preventDefault();
    setEditable(false);
    if (isImage(StudentIDCard.name)) {
      if (StudentIDCard.size < 2000000) {
        const idForm = new FormData();
        idForm.append("idCard", StudentIDCard);
        setIdcard("");
        fetch(`${baseurl}/student/id-card`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
          body: idForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            data.status === 200
              ? setIdcard(data.data.url)
              : setIdcard("Error while uploading");
          });
      } else {
        setIAlert("File size should be less than 2MB");
      }
    } else {
      setIAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setIAlert("");
    }, 4000);
    setEditable(true);
  };
  const submit10thMarksheet = (e) => {
    e.preventDefault();
    setEditable(false);
    if (isImage(Student10thMarksheet.name)) {
      if (Student10thMarksheet.size < 2000000) {
        const marksheetForm = new FormData();
        marksheetForm.append("tenth", Student10thMarksheet);
        setMarksheet("");
        fetch(`${baseurl}/student/tenth`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
          body: marksheetForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            data.status === 200
              ? setMarksheet(data.data.url)
              : setMarksheet("Error while uploading");
          });
      } else {
        setMAlert("File size should be less than 2MB");
      }
    } else {
      setMAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setMAlert("");
    }, 4000);
    setEditable(true);
  };
  const submit12thCertificate = (e) => {
    e.preventDefault();
    setEditable(false);
    if (isImage(Student12thCertificate.name)) {
      if (Student12thCertificate.size < 2000000) {
        const certificateForm = new FormData();
        certificateForm.append("twelfth", Student12thCertificate);
        setCertificate("");
        fetch(`${baseurl}/student/twelfth`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
          body: certificateForm,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            data.status === 200
              ? setCertificate(data.data.url)
              : setCertificate("Error while uploading");
          });
      } else {
        setCAlert("File size should be less than 2MB");
      }
    } else {
      setCAlert(
        "File type should be image only i.e. jpg,png,gif,bmp,jpeg,webp"
      );
    }
    setTimeout(() => {
      setCAlert("");
    }, 4000);
    setEditable(true);
  };
  const submitDocs = () => {
    var apidata = details;
    apidata.isDocsSubmitted = true;
    if (
      certificate === "" ||
      marksheet === "" ||
      idcard === "" ||
      photo === "" ||
      aadhar === "" ||
      signature === ""
    ) {
      alert("All photos have to be uploaded before submitting");
    } else {
      fetch(`${baseurl}/student`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(apidata),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          data.status === 200
            ? alert("Submitted Successfully")
            : alert(data.message);
          console.log(data);
          setEditable(!data.data.isDocsSubmitted);
        });
    }
  };
  if (token) {
    if (userInfoC.role === "student") {
      return (
        <>
          <StudentNavBar />
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 mt-3">
                <h1>Upload Documents</h1>
                <h4>
                  <strong>
                    The registrations have ended and all details are frozen.
                  </strong>
                </h4>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="alert alert-secondary">
                      <p>Images under 2MB can be uploaded.</p>
                      <p>
                        The file format of image shall be JPG, PNG, JPEG, WEBP.
                      </p>
                      <p>Please upload compressed image only.</p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-5 mb-5">
                  <div className="col-md-6 mt-5">
                    <h3>Upload Passport-sized Photo</h3>
                    <form
                      className="row justify-content-center mt-3 "
                      onSubmit={submitPhotos}
                    >
                      <div className="col-lg-8 col-12">
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            id="Photo"
                            name="Photo"
                            onChange={(e) => uploadPhoto(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            disabled
                            type="submit"
                            disabled={!editable}
                          >
                            Upload File
                          </button>
                        </div>
                        {PAlert === "" ? null : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {PAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {photo === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !photo ? (
                            <div className="card-body">
                              <br />
                              <p>
                                File hasn’t been uploaded. Please follow the
                                instructions carefully.
                              </p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={photo}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6  mt-5">
                    <h3>Upload Signature</h3>
                    <form
                      className="row justify-content-center mt-3"
                      onSubmit={submitSignature}
                    >
                      <div className="col-lg-8 col-12">
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            id="Signature"
                            name="Signature"
                            onChange={(e) => uploadSignature(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            disabled
                            type="submit"
                            id="inputGroupFileAddon04"
                            disabled={!editable}
                          >
                            Upload File
                          </button>
                        </div>
                        {SAlert === "" ? null : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {SAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {signature === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !signature ? (
                            <div className="card-body">
                              <br />
                              <p>
                                File hasn’t been uploaded. Please follow the
                                instructions carefully.
                              </p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={signature}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-center mt-5">
                  <div className="col-md-6 mt-5">
                    <h3>Upload Aadhar</h3>
                    <form
                      className="row justify-content-center mt-3"
                      onSubmit={submitAadhar}
                    >
                      <div className="col-lg-8 col-12">
                        {" "}
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            id="Aadhar"
                            name="Aadhar"
                            onChange={(e) => uploadAadhar(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            disabled
                            id="Aadhar"
                            type="submit"
                            disabled={!editable}
                          >
                            Upload File
                          </button>
                        </div>
                        {AAlert === "" ? null : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {AAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {aadhar === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !aadhar ? (
                            <div className="card-body">
                              <br />
                              <p>
                                File hasn’t been uploaded. Please follow the
                                instructions carefully.
                              </p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={aadhar}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6 col  mt-5">
                    <h3>Upload College ID Card</h3>
                    <form
                      className="row justify-content-center mt-3"
                      onSubmit={submitID}
                    >
                      <div className="col-lg-8 col-12">
                        {" "}
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            id="IDCard"
                            name="IDCard"
                            onChange={(e) => uploadID(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            disabled
                            type="submit"
                            id="IDCard"
                            disabled={!editable}
                          >
                            Upload File
                          </button>
                        </div>
                        {IAlert === "" ? null : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {IAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {idcard === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !idcard ? (
                            <div className="card-body">
                              <br />
                              <p>
                                File hasn’t been uploaded. Please follow the
                                instructions carefully.
                              </p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={idcard}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-center mt-5">
                  <div className="col-md-6 mt-5">
                    <h3>Upload 10th Marksheet</h3>
                    <form
                      className="row justify-content-center mt-3"
                      onSubmit={submit10thMarksheet}
                    >
                      <div className="col-lg-8 col-12">
                        {" "}
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            id="Marksheet10th"
                            name="Marksheet10th"
                            onChange={(e) => upload10thMarksheet(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            disabled
                            type="submit"
                            disabled={!editable}
                          >
                            Upload File
                          </button>
                        </div>
                        {MAlert === "" ? null : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {MAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {marksheet === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !marksheet ? (
                            <div className="card-body">
                              <br />
                              <p>
                                File hasn’t been uploaded. Please follow the
                                instructions carefully.
                              </p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={marksheet}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6  mt-5">
                    <h3>Upload 12th Marksheet </h3>
                    <form
                      className="row justify-content-center mt-3"
                      onSubmit={submit12thCertificate}
                    >
                      <div className="col-lg-8 col-12">
                        {" "}
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            id="Certificate12th"
                            name="Certificate12th"
                            onChange={(e) => upload12thCertificate(e)}
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            className="btn btn-outline-primary"
                            disabled
                            type="submit"
                            disabled={!editable}
                          >
                            Upload File
                          </button>
                        </div>
                        {CAlert === "" ? null : (
                          <div className="alert alert-danger mt-3" role="alert">
                            {CAlert}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="card mt-3">
                          {certificate === "" ? (
                            <div className="card-body">
                              <br />
                              <p>Loading...</p>
                              <br />
                            </div>
                          ) : !certificate ? (
                            <div className="card-body">
                              <br />
                              <p>
                                File hasn’t been uploaded. Please follow the
                                instructions carefully.
                              </p>
                              <br />
                            </div>
                          ) : (
                            <img
                              src={certificate}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-center mt-5">
                  {/* <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled
                      onClick={(e) => {
                        submitDocs();
                      }}
                    >
                      Submit
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      cookies.remove("token");
      return <div>{window.location.replace("/login")}</div>;
    }
  } else {
    return <div>{window.location.replace("/login")}</div>;
  }
};
export default UploadDocuments;
